import React, { useState, useEffect } from "react";
import styles from "./Grund.module.scss";
import GrundIcon from "components/common/icons/Grund";
import Label from "components/common/PriorityLabels/Label";
import ExpandablePaper from "components/common/ExpandablePaper";
import GrundInfo from "./GrundInfo";
import Bygning from "./Bygning/Bygning";
import TekniskAnlaeg from "./TekniskAnlaeg/TekniskAnlaeg";
import { usePrevious } from "hooks/usePrevious";

export default function Grund(props: IGrundProps) {
  const { grund, liste, opgave, expand, supplerendeOplysninger, title } = props;

  const [grundMarkeret, setGrundMarkeret] = useState(false);
  const [grundOpen, setGrundOpen] = useState(false);

  const prevOpgave = usePrevious(opgave);

  useEffect(() => {
    if (expand && grundMarkeret) {
      setGrundOpen(true);
    } else {
      setGrundOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand, grundMarkeret]);

  useEffect(() => {
    if (opgave && prevOpgave && opgave.id !== prevOpgave.id) {
      setGrundMarkeret(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opgave]);

  const constructGrundSubtitle = () => {
    let bygningText = "";
    let tekniskAnlaegText = "";
    switch (grund.bygninger.length) {
      case 0:
        bygningText = "Ingen bygninger";
        break;
      case 1:
        bygningText = "1 bygning";
        break;
      default:
        bygningText = grund.bygninger.length + " bygninger";
    }
    switch (grund.tekniskeAnlaeg.length) {
      case 0:
        tekniskAnlaegText = ""; //"ingen tekniske anlæg";
        break;
      case 1:
        tekniskAnlaegText = ", 1 teknisk anlæg";
        break;
      default:
        tekniskAnlaegText =
          ", " + grund.tekniskeAnlaeg.length + " tekniske anlæg";
    }
    return `
     ${bygningText} ${tekniskAnlaegText}`;
  };
  const renderBygninger = () => {
    let jsx_elements: JSX.Element[] = [];
    grund.bygninger.forEach((bygning, idx) => {
      jsx_elements.push(
        <Bygning
          key={bygning.bygning.id_lokalId}
          //key={idx}
          bygning={bygning}
          expand={expand}
          setGrundMarkeret={setGrundMarkeret}
          liste={liste}
          opgave={opgave}
          supplerendeOplysninger={supplerendeOplysninger}
        />
      );
    });
    return jsx_elements;
  };
  const renderTekniskeAnlaeg = () => {
    let jsx_elements: JSX.Element[] = [];

    grund.tekniskeAnlaeg.forEach((ta, idx) => {
      jsx_elements.push(
        <TekniskAnlaeg
          key={ta["tekniskAnlaeg"].id_lokalId}
          tekniskAnlaeg={ta}
          expand={expand}
          setGrundMarkeret={setGrundMarkeret}
          liste={liste}
          opgave={opgave}
          supplerendeOplysninger={supplerendeOplysninger}
        />
      );
    });

    return jsx_elements;
  };
  return (
    <div className={styles.container}>
      <div>
        <ExpandablePaper
          headerIcon={<GrundIcon />}
          opgaveIcon={
            grundMarkeret ? <Label type={liste?.listetype} /> : undefined
          }
          title={title}
          subtitle={constructGrundSubtitle()}
          nestingLevel={1}
          defaultMenuOpen={false}
          state={{ menuOpen: grundOpen, setMenuOpen: setGrundOpen }}
        >
          <GrundInfo
            grund={grund.grund}
            liste={liste}
            opgave={opgave}
            setGrundMarkeret={setGrundMarkeret}
            supplerendeOplysninger={supplerendeOplysninger}
          ></GrundInfo>
        </ExpandablePaper>
      </div>
      {grundOpen && (
        <div>
          {renderBygninger()}
          {renderTekniskeAnlaeg()}
        </div>
      )}
      {
        // Because we need to evaluate if bygninger or enheder has opgaver
        // We just hide them under the grund until we know.
      }
      {!grundOpen && (
        <div className={styles.hidden}>
          {renderBygninger()}
          {renderTekniskeAnlaeg()}
        </div>
      )}
    </div>
  );
}

function arePropsEqual(prev, next) {
  if (prev.opgave && next.opgave) {
    if (prev.opgave.id !== next.opgave.id) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
export const MemoizedGrund = React.memo(Grund, arePropsEqual);

interface IGrundProps {
  supplerendeOplysninger?: SupplerendeOplysninger;
  grund: Grund;
  expand: boolean;
  liste?: OpgaveListe;
  opgave?: Opgave;
  title: string;
}
