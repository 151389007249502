import Arrow from "components/common/icons/Arrow";
import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Header.module.scss";
import Button from "components/common/Buttons/Button";

export default function Header(props: HeaderProps) {
  const { bfe, ejendomState, listeId } = props;
  let history = useHistory();
  return (
    <div className={styles.container}>
      <Button
        type="small-light"
        classNames={styles.goto}
        onClick={() => {
          history.push(listeId ? `/fejllister?id=${listeId}` : "/fejllister");
        }}
      >
        <Arrow width={22} /> Tilbage
      </Button>
      <div className={styles.heading}>{renderHeader(ejendomState, bfe)}</div>
    </div>
  );
}

const renderHeader = (ejendomState, bfe) => {
  const jsx_elements: JSX.Element[] = [];
  /*BFE Nummber*/
  switch (ejendomState.state) {
    case "LOADING":
      jsx_elements.push(<h2 key={"h2_" + bfe}>BFE: {bfe}</h2>);
      jsx_elements.push(<p key={"p_" + bfe}>henter ...</p>);
      break;
    case "IDLE":
      if (ejendomState.data.moderejendomBfeNr) {
        jsx_elements.push(
          <h2 key={"h2_" + bfe}>
            BFE: {ejendomState.data.moderejendomBfeNr} - (moderejendom for {bfe}
            )
          </h2>
        );
      } else if (ejendomState.data.bfeNr) {
        jsx_elements.push(<h2 key={"h2_" + bfe}>BFE: {bfe}</h2>);
      }
      if (ejendomState.data.beliggenhedsadresse) {
        jsx_elements.push(
          <p key={"p_" + bfe}>
            {ejendomState.data["beliggenhedsadresse"]["betegnelse"]}
          </p>
        );
      } else {
        jsx_elements.push(
          <p key={"p_" + bfe}>Ingen beliggenhedsadresse oplyst.</p>
        );
      }

      break;
    case "ERROR":
      jsx_elements.push(<h2 key={"h2_" + bfe}>BFE: Ikke Oplyst</h2>);
      jsx_elements.push(<p key={"p_" + bfe}>-</p>);
      break;
  }

  return jsx_elements;
};
interface HeaderProps {
  bfe: Number;
  ejendomState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data?: Ejendom;
  };
  listeId: string | undefined;
}
