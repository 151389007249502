import React from "react";
import styles from "./NextPreviousPage.module.scss";
import Chevron from "components/common/icons/Chevron";
import { PageOptions } from "interfaces/paging";

interface INextPreviousPageProps {
  pageOptions: PageOptions;
  setPageOptions: (options: PageOptions) => void;
  style?: any;
  totalCount: number;
}
export default function NextPreviousPage(props: INextPreviousPageProps) {
  const { pageOptions, setPageOptions, totalCount, style = {} } = props;
  const pageSize = pageOptions.pageSize
    ? pageOptions.pageSize
    : process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;
  if (!pageOptions || totalCount === 0) return null;
  return (
    <div style={style} className={styles.paging}>
      <button
        aria-label="Første"
        className={`${styles.previous} ${styles.firstlast}`}
        style={{ color: pageOptions.page === 1 ? "#859c8d" : "inherit" }}
        disabled={pageOptions.page === 1}
        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: 1,
          })
        }
      >
        <Chevron />
        <Chevron />
        {/* <span>Forrige</span> */}
      </button>
      <button
        aria-label="Forrige"
        className={styles.previous}
        style={{ color: pageOptions.page === 1 ? "#859c8d" : "inherit" }}
        disabled={pageOptions.page === 1}
        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: pageOptions.page - 1,
          })
        }
      >
        <Chevron />
        {/* <span>Forrige</span> */}
      </button>
      <div className={styles.text}>
        <span className={styles.mobile}>Viser </span>
        <b>
          {pageOptions.page * pageSize - (pageSize - 1)}-
          {pageOptions.page * pageSize > totalCount
            ? totalCount
            : pageOptions.page * pageSize}{" "}
        </b>
        af {totalCount}{" "}
      </div>
      <button
        aria-label="Næste"
        className={styles.next}
        style={{
          color:
            pageOptions.page * pageSize >= totalCount ? "#859c8d" : "inherit",
        }}
        disabled={pageOptions.page * pageSize >= totalCount}
        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: pageOptions.page + 1,
          })
        }
      >
        {/* <span>Næste</span> */}
        <Chevron />
      </button>
      <button
        aria-label="Sidste"
        className={`${styles.next} ${styles.firstlast}`}
        style={{
          color:
            pageOptions.page * pageSize >= totalCount ? "#859c8d" : "inherit",
        }}
        disabled={pageOptions.page * pageSize >= totalCount}
        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: Math.ceil(totalCount / pageSize),
          })
        }
      >
        {/* <span>Næste</span> */}
        <Chevron />
        <Chevron />
      </button>
    </div>
  );
}
