import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Accordion.module.scss";
import MinusSimple from "components/common/icons/MinusSimple";
import PlusSimple from "components/common/icons/PlusSimple";
import { mergeClassNames } from "util/index";

const variants = {
  open: { opacity: 1, height: "auto" },
  closed: { opacity: 0, height: 0 },
};

export default function Accordion(props: AccordionProps) {
  const { isOpen, toggleIsOpen, headerText, children } = props;
  return (
    <div
      className={mergeClassNames(
        styles.container,
        isOpen ? styles.expanded : ""
      )}
    >
      <button
        aria-expanded={isOpen}
        aria-controls="accordion-content"
        className={styles.accordionheader}
        onClick={() => {
          toggleIsOpen();
        }}
      >
        <h3 className={styles.headertext}>{headerText}</h3>
        <AnimatePresence>
          {isOpen && (
            <motion.span
              className={styles.expand}
              key={isOpen.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <MinusSimple />
            </motion.span>
          )}
          {!isOpen && (
            <motion.span
              className={styles.expand}
              key={isOpen.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <PlusSimple />
            </motion.span>
          )}
        </AnimatePresence>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            id="accordion-content"
            className={styles.accordioncontent}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
            transition={{ damping: 300 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface AccordionProps {
  isOpen: boolean;
  toggleIsOpen: Function;
  headerText: string;
  children: React.ReactNode;
}
