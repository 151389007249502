import React from "react";

export default function Hamburger(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (33 / 21) : 33}
      height={height ? height : width ? width * (21 / 33) : 21}
      viewBox="0 0 33 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Wireframes"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Ejendomsvisning---1b-Copy-4"
          transform="translate(-1134.000000, -39.000000)"
          stroke={primaryColor ? primaryColor : "#003C16"}
          strokeWidth="2"
        >
          <g id="hamburger" transform="translate(1135.500000, 39.992992)">
            <path
              d="M-1.36779477e-13,0.974478468 L12.4522095,0.974478468 C12.4522095,0.974478468 24.9044191,0.105574289 29.6927687,0.974478468"
              id="Stroke-5"
            ></path>
            <path
              d="M-1.36779477e-13,9.45599358 L12.4522095,9.45599358 C12.4522095,9.45599358 25.3001151,10.8261054 29.6927687,9.45599358"
              id="Stroke-5-Copy"
            ></path>
            <path
              d="M-9.76996262e-14,18.1565709 L8.06447953,18.8015775 C8.06447953,18.8015775 24.5429684,18.1565709 29.6927687,18.8015775"
              id="Stroke-5-Copy-2"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
