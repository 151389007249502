import React from "react";
import styles from "./Sidebar.module.scss";
import { useHistory } from "react-router";
import { motion } from "framer-motion";
import { Spacing } from "components/common/layout/Spacing";
import Chevron from "components/common/icons/Chevron";
import { parseQueryString, mergeClassNames } from "util/index";
import Drawer from "components/common/Sidebar/Drawer/Drawer";
import SidebarItem from "components/common/Sidebar/SidebarItem/SidebarItem";

export default function Sidebar() {
  let history = useHistory();
  const { search } = history.location;
  const query = parseQueryString(search);
  const { type, handling, page } = query;

  return (
    <div className={styles.container}>
      <Drawer key={"Lister"} isOpen={true} headerText="Lister">
        <ul role="tablist">
          <li>
            <motion.button
              role="tab"
              aria-controls="opgaveliste-main-content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={mergeClassNames(
                styles.menuItem,
                type === "lister" && handling === "opret" ? styles.selected : ""
              )}
              title="Lister"
              onClick={() => {
                history.push("/admin?type=lister&handling=opret");
              }}
            >
              <Spacing right={1} />
              <SidebarItem title={"Opret ny liste"}></SidebarItem>
            </motion.button>
          </li>
          <li>
            <motion.button
              role="tab"
              aria-controls="opgaveliste-main-content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={mergeClassNames(
                styles.menuItem,
                type === "lister" && handling === "alle" ? styles.selected : ""
              )}
              title="Lister"
              onClick={() => {
                console.log({ type, page });
                if (type === "lister" && page) {
                  history.push("/admin?type=lister&handling=alle&page=" + page);
                } else {
                  history.push("/admin?type=lister&handling=alle&page=1");
                }
              }}
            >
              <Spacing right={1} />
              <SidebarItem title={"Alle lister"}></SidebarItem>
            </motion.button>
          </li>
        </ul>
      </Drawer>
      <Drawer key={"Artikler"} isOpen={true} headerText="Beskrivelser">
        <ul role="tablist">
          <li>
            <motion.button
              role="tab"
              aria-controls="opgaveliste-main-content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={mergeClassNames(
                styles.menuItem,
                type === "hjaelp-til-fejllister" && handling === "opret"
                  ? styles.selected
                  : ""
              )}
              title="Opret ny beskrivelse"
              onClick={() => {
                history.push(
                  "/admin?type=hjaelp-til-fejllister&handling=opret"
                );
              }}
            >
              <Spacing right={1} />
              <SidebarItem title={"Opret ny beskrivelse"}></SidebarItem>
            </motion.button>
          </li>
          <li>
            <motion.button
              role="tab"
              aria-controls="opgaveliste-main-content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={mergeClassNames(
                styles.menuItem,
                type === "beskrivelse" && handling === "alle"
                  ? styles.selected
                  : ""
              )}
              title="Alle beskrivelser"
              onClick={() => {
                history.push(
                  "/admin?type=hjaelp-til-fejllister&handling=alle&page=1"
                );
              }}
            >
              <Spacing right={1} />
              <SidebarItem title={"Alle beskrivelser"}></SidebarItem>
            </motion.button>
          </li>
        </ul>
      </Drawer>
      <ul role="tablist">
        <li>
          <motion.button
            role="tab"
            aria-controls="opgaveliste-main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={mergeClassNames(
              styles.menuItem,
              type === "brugere" ? styles.selected : ""
            )}
            title="Lister"
            onClick={() => {
              history.push("/admin?type=brugere");
            }}
          >
            <Spacing right={0.5} />
            <div className={styles.headeritem}>
              <h2>
                Brugere
                <span>
                  <Chevron />
                </span>
              </h2>
            </div>
          </motion.button>
        </li>
      </ul>
      <ul role="tablist">
        <li>
          <motion.button
            role="tab"
            aria-controls="opgaveliste-main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={mergeClassNames(
              styles.menuItem,
              type === "organisationer" ? styles.selected : ""
            )}
            title="Lister"
            onClick={() => {
              history.push("/admin?type=organisationer");
            }}
          >
            <Spacing right={0.5} />
            <div className={styles.headeritem}>
              <h2>
                Organisationer
                <span>
                  <Chevron />
                </span>
              </h2>
            </div>
          </motion.button>
        </li>
      </ul>
    </div>
  );
}
