import React, { useState } from "react";
import styles from "./Main.module.scss";
import ExpandablePaper from "components/common/ExpandablePaper";
import PageContainer from "components/common/layout/PageContainer";
import GrundIcon from "components/common/icons/Grund";
import Plus from "components/common/icons/Plus";
import Minus from "components/common/icons/Minus";
import InfoSmall from "components/common/icons/InfoSmall";
import Grund from "../Grund/Grund";
import MapMarker from "components/common/icons/MapMarker";
import SupplerendeOplysninger from "../SupplerendeOplysninger/SupplerendeOplysninger";
import { getRelevantSupplerendeOplysninger } from "util/index";
import { EjendomState, dataIsEjenom } from "../Ejendom";

export default function Main(props: MainProps) {
  const { ejendomState, listeState, opgaveState } = props;
  const [minimized, setMinimized] = useState(false);

  return (
    <PageContainer classNames={styles.content}>
      <div className={styles.header}>
        {!minimized && (
          <button onClick={() => setMinimized(true)}>
            <Minus secondaryColor={"transparent"} />
            <span>Minimér alle</span>
          </button>
        )}
        {minimized && (
          <button onClick={() => setMinimized(false)}>
            <Plus secondaryColor={"transparent"} />
            <span>Udfold relevante</span>{" "}
          </button>
        )}
        <p>
          <InfoSmall /> Der vises kun felter, der har indhold
        </p>
      </div>
      {ejendomState.state === "LOADING" && (
        <div className={styles.box}>
          <GrundIcon /> <span>Henter Ejendom ...</span>
        </div>
      )}
      {ejendomState.state === "IDLE" &&
        renderEjendom(ejendomState, listeState, opgaveState, minimized)}
      {ejendomState.state === "ERROR" && (
        <div className={styles.box}>
          <GrundIcon /> <span>Ingen grund fundet på opslag</span>
        </div>
      )}
    </PageContainer>
  );
}

const renderEjendom = (
  ejendomState: EjendomState,
  listeState,
  opgaveState,
  minimized
) => {
  const ejendom = dataIsEjenom(ejendomState.data)
    ? ejendomState.data
    : undefined;
  if (!ejendom) return null;

  const relevantSupplerendeOplysninger = getRelevantSupplerendeOplysninger(
    ejendom.supplerendeOplysninger,
    null
  );

  const {
    chrOplysninger,
    cvrBranchekoder,
    cvrVirksomheder,
    elOplysninger,
    energiOplysninger,
    smileyBranchekoder,
    smileyVirksomheder,
  } = relevantSupplerendeOplysninger;
  const disableExpandable =
    !chrOplysninger?.oplysninger.length &&
    !cvrBranchekoder?.oplysninger.length &&
    !cvrVirksomheder?.oplysninger.length &&
    !energiOplysninger?.oplysninger.length &&
    !elOplysninger?.oplysninger.length &&
    !smileyBranchekoder?.oplysninger.length &&
    !smileyVirksomheder?.oplysninger.length;
  return (
    <div>
      <ExpandablePaper
        headerIcon={<MapMarker />}
        title="Ejendom"
        subtitle={`BFE: ${ejendom.bfeNr}`}
        disabled={disableExpandable}
      >
        {ejendomState.data && relevantSupplerendeOplysninger && (
          <div>
            <SupplerendeOplysninger
              supplerendeOplysninger={relevantSupplerendeOplysninger}
            />
          </div>
        )}
      </ExpandablePaper>
      {renderGrunde(ejendomState, listeState, opgaveState, minimized)}
    </div>
  );
};
export const formatEjerlav = (ejerlav: string) => {
  if (ejerlav) {
    const tokens = ejerlav.split(" ");
    let formattedTokens: string[] = [];
    tokens.forEach((t) => {
      if (t !== "") {
        formattedTokens.push(
          t.charAt(0).toUpperCase() + t.toLowerCase().slice(1)
        );
      }
    });
    return formattedTokens.join(" ");
  } else {
    return "";
  }
};
const renderGrunde = (
  ejendomState: EjendomState,
  listeState,
  opgaveState,
  minimized
) => {
  const ejendom = dataIsEjenom(ejendomState.data)
    ? ejendomState.data
    : undefined;
  if (!ejendom) return null;
  let opgave: Opgave = opgaveState.data?.[opgaveState.idx];
  let liste: OpgaveListe = listeState.data;
  if ("grunde" in ejendom && ejendom.grunde.length > 0) {
    const { grunde } = ejendom;
    return grunde.map(function (grund, i) {
      let title = `Grund`;
      if (grund.jordstykker && grund.jordstykker.length > 0) {
        title = `Grund - ${grund.navnFraJordstykker}`;
      }
      return (
        <Grund
          key={grund.grund?.["id_lokalId"]}
          expand={!minimized}
          grund={grund}
          liste={liste}
          opgave={opgave}
          supplerendeOplysninger={ejendom.supplerendeOplysninger}
          title={title}
        />
      );
    });
  } else {
    return (
      <div className={styles.box}>
        <GrundIcon /> <span>Kunne ikke finde grund for BFE</span>
      </div>
    );
  }
};
interface MainProps {
  ejendomState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data?: Ejendom;
  };
  listeState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data?: OpgaveListe;
    opgaveOrder?: OpgaveOrder;
  };
  opgaveState: {
    state: "IDLE" | "LOADING" | "ERROR" | "EMPTY";
    idx: Number;
    data?: Opgave[];
  };
}
