import React, { useReducer, useState } from "react";
import styles from "./ListItem.module.scss";
import Hourglass from "components/common/icons/Hourglass";
import Pause from "components/common/icons/Pause";
import More from "components/common/icons/More";
import Checkmark from "components/common/icons/Checkmark";
import ExternalArrow from "components/common/icons/ExternalArrow";
import { AnimatePresence, motion } from "framer-motion";
import Minus from "components/common/icons/Minus";
import Plus from "components/common/icons/Plus";
import Arrow from "components/common/icons/Arrow";
import Button from "components/common/Buttons/Button";
import StatusLabel from "components/common/StatusLabels/StatusLabel";
import {
  // color, borderColorLight,
  size,
} from "styles/theme";
import {
  OpgaveListeType,
  OpgaveBrugerStatus,
} from "interfaces/Opgaveliste/enums";
import { mergeClassNames } from "util/index";
import Avatar from "components/common/Avatar";
import FoldOutMenu from "components/common/FoldOutMenu";

export default function ListItem(props: IListItemProps) {
  const {
    opgave,
    // listetype,
    goToEjendom,
    setOpgaveBrugerStatus,
    brugerStatusUI,
  } = props;
  const initialState: IListItemState = {
    headerHovered: false,
    toggled: false,
  };
  const reducer = (
    state: IListItemState,
    action: IListItemAction
  ): IListItemState => {
    switch (action.type) {
      case "SET_TOGGLED":
        return { ...state, toggled: action.toggled };
      case "SET_HOVERED":
        return { ...state, headerHovered: action.hovered };
      default:
        throw new Error("Action not defined");
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { headerHovered, toggled } = state;

  const listitemexpanded = toggled
    ? `${styles.listitemexpanded} ${styles.expanded}`
    : styles.listitemexpanded;

  const [buttonState, setButtonState] = useState<"IDLE" | "LOADING">("IDLE");
  const asyncSetOpgaveBrugerStatus = async (
    opgaveId: string,
    brugerStatus: OpgaveBrugerStatus
  ) => {
    setButtonState("LOADING");
    await setOpgaveBrugerStatus(opgaveId, brugerStatus);
    setButtonState("IDLE");
  };
  const containerclasses = toggled
    ? mergeClassNames(styles.container, styles.expanded)
    : styles.container;
  const retILinks = opgave.visningsdata.links
    ? opgave.visningsdata.links.filter((o) => !o.skjul)
    : [];
  const hasKanIkkeLoesesButton =
    brugerStatusUI &&
    brugerStatusUI.findIndex((b) => b.status === 5 && b.visesUi) > -1;
  return (
    <div className={containerclasses}>
      <div
        role="button"
        aria-expanded={toggled}
        tabIndex={0}
        className={styles.listitemheader}
        onMouseEnter={() => dispatch({ type: "SET_HOVERED", hovered: true })}
        onMouseLeave={() => dispatch({ type: "SET_HOVERED", hovered: false })}
        onClick={() => dispatch({ type: "SET_TOGGLED", toggled: !toggled })}
        onKeyDown={(e) => {
          if (e.keyCode === 32) {
            e.preventDefault();
            dispatch({ type: "SET_TOGGLED", toggled: !toggled });
          } else if (e.keyCode === 13) {
            e.preventDefault();
            dispatch({ type: "SET_TOGGLED", toggled: !toggled });
          }
        }}
      >
        <AnimatePresence>
          {toggled && (
            <motion.div
              className={styles.expand}
              key={toggled.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Minus hovered={headerHovered} />
            </motion.div>
          )}
          {!toggled && (
            <motion.div
              className={styles.expand}
              key={toggled.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Plus hovered={headerHovered} />
            </motion.div>
          )}
        </AnimatePresence>
        <div className={styles.bfe}>
          <h3>BFE: {opgave.bfe}</h3>
          {opgave.adresse && (
            <p title={opgave.adresse.betegnelse} className={styles.adresse}>
              {opgave.adresse.betegnelse}
            </p>
          )}
          {!opgave.adresse && <p className={styles.adresse}>Adresse mangler</p>}
        </div>
      </div>
      <motion.div
        aria-hidden={!toggled}
        style={{ overflow: "hidden" }}
        initial={{ height: size.base * 6 }}
        animate={{ height: toggled ? "auto" : size.base * 6 }}
        className={listitemexpanded}
      >
        <div className={styles.colLeft}>
          <div className={styles.spacer}></div>
          <div className={styles.links}>
            {retILinks.length > 0 && (
              <>
                <h5>Ret i</h5>
                <ul>
                  {retILinks.map((o, idx) => {
                    return (
                      <li key={idx}>
                        <a
                          className="external"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={o.url}
                        >
                          <span>{o.system}</span>
                          <ExternalArrow />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
        <div className={styles.colRight}>
          <div className={styles.info}>
            <ul>
              {opgave.visningsdata.elementer &&
                opgave.visningsdata.elementer.map((o, idx) => {
                  return (
                    <li className={styles.infoitem} key={idx}>
                      <h5 className={styles.itemheader}>{o.info.navn}</h5>
                      <p className={styles.itemvalue}>{o.info.vaerdi}</p>
                    </li>
                  );
                })}
            </ul>
            <div style={{ padding: "16px 10px" }}>
              {opgave.tilhoererBruger && (
                <Avatar bruger={opgave.tilhoererBruger} />
              )}
              {!opgave.tilhoererBruger && (
                <div style={{ height: 30, width: 30 }}></div>
              )}
            </div>
            <div className={styles.innerColRight}>
              <div className={styles.opgaveikons}>
                {opgave.brugerStatus === 0 && (
                  <StatusLabel type="open">Åben</StatusLabel>
                )}
                {opgave.brugerStatus === 2 && (
                  <StatusLabel type="done">Færdig</StatusLabel>
                )}
                {opgave.brugerStatus === 1 && (
                  <StatusLabel type="onit">I gang</StatusLabel>
                )}
                {opgave.brugerStatus === 5 && (
                  <StatusLabel type="pause">Fejl</StatusLabel>
                )}
              </div>
              {/* <div className={styles.oprettet}>
                <h5>Oprettet</h5>
                <p>
                  {format(new Date(opgave.oprettetDato), "PPP", {
                    locale: da,
                  })}
                </p>
              </div> */}
            </div>
          </div>
          {toggled && (
            <div className={styles.buttons}>
              <ul className={styles.states}>
                <li>
                  <Button
                    disabled={buttonState === "LOADING"}
                    classNames={
                      opgave.brugerStatus === OpgaveBrugerStatus.iGang
                        ? styles.active
                        : ""
                    }
                    onClick={() =>
                      asyncSetOpgaveBrugerStatus(
                        opgave.id,
                        opgave.brugerStatus === OpgaveBrugerStatus.iGang
                          ? OpgaveBrugerStatus.ikkeTildelt
                          : OpgaveBrugerStatus.iGang
                      )
                    }
                    aria-pressed="false"
                    type="small"
                  >
                    <Hourglass height={15} />
                    <span>I gang</span>
                  </Button>
                </li>
                <li>
                  <Button
                    disabled={buttonState === "LOADING"}
                    classNames={
                      opgave.brugerStatus === OpgaveBrugerStatus.opgaveLoest
                        ? styles.active
                        : ""
                    }
                    onClick={() =>
                      asyncSetOpgaveBrugerStatus(
                        opgave.id,
                        opgave.brugerStatus === OpgaveBrugerStatus.opgaveLoest
                          ? OpgaveBrugerStatus.ikkeTildelt
                          : OpgaveBrugerStatus.opgaveLoest
                      )
                    }
                    aria-pressed="false"
                    type="small"
                  >
                    <Checkmark />
                    <span>Færdig</span>
                  </Button>
                </li>
                {hasKanIkkeLoesesButton && (
                  <li>
                    <FoldOutMenu
                      direction={"up"}
                      element={
                        <Button
                          classNames={
                            opgave.brugerStatus ===
                            OpgaveBrugerStatus.kanIkkeLoeses
                              ? styles.active
                              : ""
                          }
                          type="icon"
                          onClick={() => {}}
                        >
                          <More /> <span className="sr-only">More</span>
                        </Button>
                      }
                      menu={
                        <>
                          <Button
                            disabled={buttonState === "LOADING"}
                            classNames={
                              opgave.brugerStatus ===
                              OpgaveBrugerStatus.kanIkkeLoeses
                                ? styles.active
                                : ""
                            }
                            onClick={() =>
                              asyncSetOpgaveBrugerStatus(
                                opgave.id,
                                opgave.brugerStatus ===
                                  OpgaveBrugerStatus.kanIkkeLoeses
                                  ? OpgaveBrugerStatus.ikkeTildelt
                                  : OpgaveBrugerStatus.kanIkkeLoeses
                              )
                            }
                            aria-pressed="false"
                            type="small"
                          >
                            <Pause></Pause>
                            {opgave.brugerStatus ===
                              OpgaveBrugerStatus.kanIkkeLoeses &&
                              "Kan ikke løses (afventer)"}
                            {opgave.brugerStatus !==
                              OpgaveBrugerStatus.kanIkkeLoeses &&
                              "Kan ikke løses (afventer)"}
                          </Button>
                        </>
                      }
                    />
                  </li>
                )}
              </ul>
              {goToEjendom && (
                <Button
                  type="primary"
                  classNames={styles.arrow}
                  onClick={goToEjendom}
                >
                  <span>Se ejendom</span>
                  <Arrow />
                </Button>
              )}
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
}

interface IListItemProps {
  opgave: Opgave;
  setOpgaveBrugerStatus: (
    id: string,
    brugerStatus: OpgaveBrugerStatus
  ) => Promise<Opgave>;
  listetype: OpgaveListeType;
  goToEjendom?: Function;
  brugerStatusUI: OpgaveBrugerStatusUI[];
}
interface IListItemState {
  headerHovered: boolean;
  toggled: boolean;
}

type IListItemAction =
  | {
      type: "SET_TOGGLED";
      toggled: boolean;
    }
  | { type: "SET_HOVERED"; hovered: boolean };
