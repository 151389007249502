import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./FoldOutSection.module.scss";
import Minus from "../icons/Minus";
import Plus from "../icons/Plus";
import MinusSimple from "../icons/MinusSimple";
import PlusSimple from "../icons/PlusSimple";

export default function FoldOutsection(props: IFoldOutsection) {
  const { element, opgaveIcon, children, isOpen, nestingLevel = 1 } = props;
  const [sectionOpen, setsectionOpen] = useState(false);

  // TODO: This may be redundant.
  const { open, setOpen } = {
    open: sectionOpen,
    setOpen: setsectionOpen,
  };

  const variants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  useEffect(() => {
    if (isOpen !== undefined) {
      setsectionOpen(isOpen);
    }
  }, [isOpen]);

  const renderHeaderButton = () => {
    if (nestingLevel === 3) {
      return (
        <button
          className={`${styles.header} ${styles.small}`}
          aria-expanded={open} // NB Toggle
          onClick={() => {
            setOpen(!open);
          }}
        >
          {element}
          <AnimatePresence>
            {open && (
              <motion.span
                className={styles.expand}
                key={open.toString()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <MinusSimple width={16} />
              </motion.span>
            )}
            {!open && (
              <motion.span
                className={styles.expand}
                key={open.toString()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <PlusSimple width={16} />
              </motion.span>
            )}
          </AnimatePresence>
        </button>
      );
    }
    return (
      <button
        className={styles.header}
        aria-expanded={open}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <AnimatePresence>
          {open && (
            <motion.span
              className={styles.expand}
              key={open.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Minus />
            </motion.span>
          )}
          {!open && (
            <motion.span
              className={styles.expand}
              key={open.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Plus />
            </motion.span>
          )}
        </AnimatePresence>
        {element}
        <div className={styles.opgaveIcon}>{opgaveIcon}</div>
      </button>
    );
  };
  return (
    <div
      className={nestingLevel === 3 ? styles.smallcontainer : styles.container}
    >
      {renderHeaderButton()}
      <AnimatePresence>
        {open && (
          <motion.div
            aria-hidden="false"
            style={{ overflow: "hidden" }}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
          >
            {children}
          </motion.div>
        )}
        {!open && (
          <motion.div
            aria-hidden="true"
            style={{ overflow: "hidden" }}
            variants={variants}
            initial="closed"
            animate="closed"
            exit={variants.closed}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IFoldOutsection {
  nestingLevel?: 1 | 2 | 3;
  element: JSX.Element;
  elementClassNames?: string;
  children: React.ReactNode;
  opgaveIcon?: React.ReactNode;
  sectionClassNames?: string;
  isOpen?: boolean;
}
