import React, { useRef, useEffect } from "react";
import "./Search.scss";
export default function Search({ onResult }) {
  let autocomplete = useRef(undefined);
  useEffect(() => {
    try {
      autocomplete.current = searchComponent.createInstance({
        elementOrSelector: "#searchcomponent",
        sources: [
          {
            name: "dawa.adresse",
            options: {
              fetchExtendedResult: true,
            },
          },
          {
            name: "dawa.jordstykke",
            options: {
              fetchExtendedResult: true,
            },
          },
          {
            name: "daf.ejendom",
            options: {
              fetchExtendedResult: true,
            },
          },
          {
            name: "daf.ejendomsnummer",
            options: {
              fetchExtendedResult: true,
            },
          },
        ],
        layout: {
          width: "100%",
        },
        localization: {
          placeholder: "Søg på adresse, matrikel-, ESR-, eller BFE-nummer",
        },
        events: {
          itemClicked: (item: JO.SearchResult) => {
            onResult(item);
          },
        },
      });
    } catch (error) {
      // searchComponent is not defined
    }
  }, [onResult]);
  if (autocomplete.current) {
    // issue #243, ønsker ikke autofocus
    // autocomplete.current.focus();
  }
  return <div id="searchcomponent" />;
}
