import React from "react";
import styles from "./Left.module.scss";
import { MemoizedMap } from "components/Map/Map";
import FullScreen from "components/common/icons/FullScreen";
import Links from "./Left/Links";
import Ejer from "./Left/Ejer";
import Accordion from "./Left/Accordion";
import { motion } from "framer-motion";
import { EjendomState } from "../Ejendom";
import Vurderingsejendom from "./Left/Vurderingsejendom";
import { constructGrundeFromEjendom } from "../../../util";

interface LeftProps {
  ejendom: EjendomState;
  showFullScreenMap: Function;
  currentAccordionOpen: undefined | "om-ejendommen" | "eksterne-links";
  setCurrentAccordionOpen: Function;
}

export default function Left(props: LeftProps) {
  const {
    ejendom,
    showFullScreenMap,
    currentAccordionOpen,
    setCurrentAccordionOpen,
  } = props;

  const setOmejendommenOpen = () => {
    if (currentAccordionOpen === "om-ejendommen") {
      setCurrentAccordionOpen(undefined);
    } else {
      setCurrentAccordionOpen("om-ejendommen");
    }
  };
  const setLinksOpen = () => {
    if (currentAccordionOpen === "eksterne-links") {
      setCurrentAccordionOpen(undefined);
    } else {
      setCurrentAccordionOpen("eksterne-links");
    }
  };
  const renderEjendommen = () => {
    return (
      <Accordion
        isOpen={currentAccordionOpen === "om-ejendommen"}
        toggleIsOpen={setOmejendommenOpen}
        headerText={"Om ejendommen"}
      >
        <div id="om-ejendommen">
          <Ejer ejendomState={ejendom}></Ejer>
          <Vurderingsejendom ejendom={ejendom}></Vurderingsejendom>

          <div className={styles.stickymap}>
            <h5>Kort</h5>
            <div className={styles.mapcontent}>
              {ejendom.state === "IDLE" && (
                <MemoizedMap
                  divId="ejendom-thumbnail-container"
                  grunde={constructGrundeFromEjendom(ejendom.data)}
                  strukturer={[]}
                  indstillinger={{
                    signatur: {
                      visBygningssignatur: true,
                      visStatusIkoner: false,
                    },
                  }}
                  containerStyle={{ height: 200 }}
                />
              )}
              {ejendom.state === "LOADING" && (
                <motion.div className={`${styles.loading}`}>
                  <div className={styles.box}></div>
                </motion.div>
              )}
              {ejendom.state === "ERROR" && <div>Kunne ikke hente kort.</div>}

              <button
                onClick={() => showFullScreenMap(true)}
                className={styles.fullscreen}
              >
                <span>
                  <FullScreen width={20} />
                </span>
              </button>
            </div>
          </div>
        </div>
      </Accordion>
    );
  };
  const renderLinks = () => {
    return (
      <Accordion
        isOpen={currentAccordionOpen === "eksterne-links"}
        toggleIsOpen={setLinksOpen}
        headerText={"Eksterne links"}
      >
        <div id="eksterne-links">
          <Links ejendomState={ejendom}></Links>
        </div>
      </Accordion>
    );
  };
  return (
    <div className={styles.container}>
      {renderEjendommen()}
      {renderLinks()}
    </div>
  );
}
