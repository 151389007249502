import React from "react";

export default function FullScreen(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (30 / 30) : 30}
      height={height ? height : width ? width * (30 / 30) : 30}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          fill={primaryColor ? primaryColor : "currentColor"}
          fillRule="nonzero"
        >
          <path
            d="M1,7.6 C1.4,7.6 1.8,7.2 1.8,6.8 C1.8,6.8 1.8,6.8 1.8,6.8 L1.8,3.2 C3.2,4.7 5.1,6.6 8.2,9.6 C8.5,9.9 9,9.9 9.3,9.6 C9.6,9.3 9.6,8.8 9.3,8.5 L9.3,8.5 C6.2,5.5 4.4,3.6 3.1,2.1 C4.8,2.2 6.5,2.1 8.2,1.9 C8.6,1.9 9,1.5 8.9,1 C8.8,0.6 8.4,0.3 8,0.3 L8,0.3 C5.7,0.6 3.4,0.6 1.2,0.3 C1,0.3 0.7,0.3 0.5,0.5 L0.5,0.5 C0.3,0.7 0.2,0.9 0.2,1.1 L0.2,6.8 C0.2,7.2 0.5,7.6 1,7.6 C1,7.6 1,7.6 1,7.6 Z"
            id="Path"
          ></path>
          <path
            d="M22.5,1 C22.5,1.4 22.9,1.8 23.3,1.8 C23.3,1.8 23.3,1.8 23.3,1.8 L26.9,1.8 C25.4,3.2 23.5,5.1 20.5,8.2 C20.2,8.5 20.2,9 20.5,9.3 C20.8,9.6 21.3,9.6 21.6,9.3 L21.6,9.3 C24.5,6.2 26.4,4.4 27.8,3 C27.7,4.7 27.8,6.4 28,8.1 C28.1,8.5 28.5,8.9 28.9,8.8 C29.3,8.7 29.7,8.3 29.6,7.9 L29.6,7.9 C29.3,5.6 29.3,3.3 29.6,1.1 C29.6,0.9 29.6,0.6 29.4,0.4 L29.4,0.4 C29.2,0.3 29,0.2 28.8,0.2 L23.1,0.2 C22.9,0.2 22.6,0.5 22.5,1 C22.5,1 22.5,1 22.5,1 Z"
            id="Path"
          ></path>
          <path
            d="M29,22.5 C28.6,22.5 28.2,22.9 28.2,23.3 C28.2,23.3 28.2,23.3 28.2,23.3 L28.2,26.9 C26.8,25.4 24.9,23.5 21.8,20.5 C21.5,20.2 21,20.2 20.7,20.5 C20.4,20.8 20.4,21.3 20.7,21.6 L20.7,21.6 C23.8,24.5 25.6,26.4 27,27.8 C25.3,27.7 23.6,27.8 21.9,28 C21.5,28.1 21.1,28.5 21.2,28.9 C21.3,29.3 21.7,29.7 22.1,29.6 L22.1,29.6 C24.4,29.3 26.7,29.3 28.9,29.6 C29.1,29.6 29.4,29.6 29.6,29.4 L29.6,29.4 C29.7,29.2 29.8,29 29.8,28.8 L29.8,23.1 C29.8,22.9 29.5,22.5 29,22.5 C29,22.5 29,22.5 29,22.5 Z"
            id="Path"
          ></path>
          <path
            d="M7.5,29 C7.5,28.6 7.1,28.2 6.7,28.2 C6.7,28.2 6.7,28.2 6.7,28.2 L3.1,28.2 C4.6,26.8 6.5,24.9 9.5,21.8 C9.8,21.5 9.8,21 9.5,20.7 C9.2,20.4 8.7,20.4 8.4,20.7 L8.4,20.7 C5.4,23.8 3.5,25.7 2,27 C2.1,25.3 2,23.6 1.8,21.9 C1.7,21.5 1.3,21.1 0.9,21.2 C0.5,21.3 0.1,21.7 0.2,22.1 L0.2,22.1 C0.5,24.4 0.5,26.7 0.2,28.9 C0.2,29.1 0.2,29.4 0.4,29.6 L0.4,29.6 C0.6,29.7 0.8,29.8 1,29.8 L6.7,29.8 C7.1,29.9 7.5,29.5 7.5,29 C7.5,29.1 7.5,29.1 7.5,29 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
}
