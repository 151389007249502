import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import styles from "./MainContent.module.scss";
import Header from "./Header";
import ListItem from "./ListItem";
import { useHistory } from "react-router-dom";
import opgaveApi from "api/opgave";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { OpgaveListePageOptions } from "interfaces/paging";
import { OpgaveBrugerStatus } from "interfaces/Opgaveliste/enums";
import { AuthContext } from "context/AuthProvider";
import IngenOpgaver from "./IngenOpgaver";
import { RealTimeContext } from "context/RealTimeProvider";
import { usePrevious } from "hooks/usePrevious";

interface MainContentProps {
  opgaveliste?: OpgaveListe;
  opgaveOptions: OpgaveListePageOptions;
  defaultOpgaveOptions: OpgaveListePageOptions;
  setOpgaveOptions: React.Dispatch<
    React.SetStateAction<OpgaveListePageOptions>
  >;
}
export default function MainContent(props: MainContentProps) {
  const { opgaveMessages } = useContext(RealTimeContext);
  const prevOpgaveMessages = usePrevious(opgaveMessages);
  const { opgaveOptions, opgaveliste, setOpgaveOptions, defaultOpgaveOptions } =
    props;
  const [opgaverState, setOpgaverState] = useState<{
    state: "IDLE" | "LOADING" | "ERROR";
    data: Array<Opgave>;
    totaltAntal: number;
  }>({ state: "IDLE", data: [], totaltAntal: 0 });

  useEffect(() => {
    if (
      opgaverState.data.length &&
      opgaveMessages.length &&
      (!prevOpgaveMessages || prevOpgaveMessages.length < opgaveMessages.length)
    ) {
      const newOpgaveMessage = opgaveMessages[opgaveMessages.length - 1];
      if (opgaverState.data.find((o) => o.id === newOpgaveMessage.id)) {
        setOpgaverState({
          ...opgaverState,
          data: opgaverState.data.map((o) => {
            if (o.id === newOpgaveMessage.id) {
              return { ...o, ...newOpgaveMessage };
            }
            return o;
          }),
        });
      }
    }
  }, [opgaveMessages, prevOpgaveMessages, opgaverState, setOpgaverState]);

  const history = useHistory();

  const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;

  const authState = useContext(AuthContext);

  useEffect(() => {
    if (opgaveOptions) {
      let stillMounted = true;

      const fetchData = async () => {
        setOpgaverState({
          state: "LOADING",
          data: [],
          totaltAntal: 0,
        });
        try {
          const { id, page, sortOrder, brugerId, brugerStatus, kommunenr } =
            opgaveOptions;
          if (id === undefined) {
            setOpgaverState({ state: "ERROR", data: [], totaltAntal: 0 });
          } else {
            const olResponse = await opgaveApi.getFromListe(id, authState, {
              page,
              pageSize,
              sortOrder,
              brugerId,
              brugerStatus,
              kommunenr,
            });
            const ol = olResponse.data;
            const listContentRange = olResponse.headers["list-content-range"];
            const totaltAntal = parseInt(listContentRange.split("/")[1]);
            if (stillMounted) {
              setOpgaverState({
                state: "IDLE",
                data: ol,
                totaltAntal,
              });
            } else {
              setOpgaverState({ state: "LOADING", data: [], totaltAntal: 0 });
            }
          }
        } catch (error) {
          if (error === "CANCEL") {
            // The request was canceled, all is well
            console.debug(error);
          } else {
            if (stillMounted) {
              // Request failed, set error state
              setOpgaverState({ state: "ERROR", data: [], totaltAntal: 0 });
            }
          }
        }
      };
      if (opgaveOptions.id !== "") {
        fetchData();
      }

      return () => {
        // The return function is called when
        // the component unmounts. We use the
        // stillmounted flag to avoid attempting
        // to set state on an unmounted component
        stillMounted = false;
      };
    }
  }, [opgaveOptions, pageSize, authState]);

  const setOpgaveBrugerStatus = async (
    opgaveId: string,
    brugerStatus: OpgaveBrugerStatus
  ) => {
    try {
      let opdateretOpgave = await opgaveApi.putOpgaveBrugerStatus(
        opgaveId,
        brugerStatus,
        authState
      );

      if (opdateretOpgave && opgaverState.state === "IDLE") {
        const index = opgaverState.data.findIndex((o) => o.id === opgaveId);
        setOpgaverState({
          ...opgaverState,
          data: [
            ...opgaverState.data.slice(0, index),
            // #242 - adresser kommer ikke med i put-response for opgave
            { ...opdateretOpgave, adresse: opgaverState.data[index].adresse },
            ...opgaverState.data.slice(index + 1),
          ],
        });
      }
      return opdateretOpgave;
    } catch (error) {
      if (error === "CANCEL") {
        // The request was canceled, all is well
        console.debug(error);
      } else {
        return undefined;
      }
    }
  };

  const renderLoading = () => {
    return Array.from(new Array(10)).map((o, i) => {
      return (
        <motion.li key={i} className={`${styles.loading}`}>
          <div className={styles.text}>
            <div
              style={{ width: 100 + "%", height: 80 }}
              className={styles.line}
            ></div>
          </div>
        </motion.li>
      );
    });
  };
  const renderError = () => {
    return (
      <motion.div layout transition={{ damping: 300 }}>
        <h3>En uventet fejl opstod</h3>
      </motion.div>
    );
  };
  const renderOpgaver = (
    opgaverState: {
      state: "IDLE" | "LOADING" | "ERROR";
      data: Array<Opgave>;
      totaltAntal: number;
    },
    opgaveliste: OpgaveListe | undefined,
    history,
    sortOrder
  ) => {
    if (!opgaveliste) return null;
    let data = opgaverState.data;
    if (data.length === 0) return <IngenOpgaver />;
    return data.map((o) => {
      return (
        <motion.div layout transition={{ damping: 300 }} key={o.id}>
          <ListItem
            opgave={o}
            setOpgaveBrugerStatus={setOpgaveBrugerStatus}
            listetype={opgaveliste.listetype}
            goToEjendom={() => {
              history.push(
                `/ejendom?bfe=${o.bfe}&opgaveid=${o.id}&listeid=${opgaveliste.id}&sortorder=${sortOrder}`
              );
            }}
            brugerStatusUI={opgaveliste.brugerstatus}
          />
        </motion.div>
      );
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.container}
      id="opgaveliste-main-content"
    >
      {opgaveliste && (
        <Header
          totaltAntal={opgaverState.totaltAntal}
          opgaveliste={opgaveliste}
          opgaveOptions={opgaveOptions}
          setOpgaveOptions={setOpgaveOptions}
          defaultOpgaveOptions={defaultOpgaveOptions}
          bruger={authState.bruger}
        />
      )}

      <motion.div
        className={styles.list}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {opgaverState.state === "IDLE" &&
          renderOpgaver(
            opgaverState,
            opgaveliste,
            history,
            opgaveOptions ? opgaveOptions.sortOrder : 0
          )}
        {opgaverState.state === "IDLE" && opgaveliste && (
          <div className={styles.pagingbottom}>
            {opgaveOptions && (
              <NextPreviousPage
                pageOptions={opgaveOptions}
                setPageOptions={setOpgaveOptions}
                totalCount={opgaverState.totaltAntal}
              />
            )}
          </div>
        )}

        {opgaverState.state === "LOADING" && renderLoading()}
        {opgaverState.state === "ERROR" && renderError()}
      </motion.div>
    </motion.div>
  );
}
