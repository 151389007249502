import React from "react";
import styles from "./SmileyBranchekoder.module.scss";

export default function SmileyBranchekoder(props: ISmileyBranchekoderProps) {
  const { smileyBranchekoder } = props;
  const { oplysninger } = smileyBranchekoder;
  return (
    <div className={styles.smileybranchekodercontainer}>
      <h4>Smiley branchekoder</h4>
      <ul>
        {oplysninger.map((o, idx) => {
          const { adgangsadresseId, branche, branchekode } = o;
          return (
            <li
              className={styles.smileybranchekode}
              key={adgangsadresseId + "_" + branche + "_" + branchekode}
            >
              <p>
                {branchekode}: {branche}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface ISmileyBranchekoderProps {
  smileyBranchekoder: SmileyBranchekoder;
}
