import React, { useState, useEffect } from "react";
import BBRAnvendelse from "components/common/icons/BBRBygAnvendelse";
import Label from "components/common/PriorityLabels/Label";
import ExpandablePaper from "components/common/ExpandablePaper";
import BygningInfo from "./BygningInfo";
import { usePrevious } from "hooks/usePrevious";

export default function Bygning(props: IBygningProps) {
  const {
    bygning,
    liste,
    opgave,
    setGrundMarkeret,
    expand,
    supplerendeOplysninger,
  } = props;

  const [bygningMarkeret, setBygningMarkeret] = useState(false);
  const [bygningOpen, setBygningOpen] = useState(false);

  const prevOpgave = usePrevious(opgave);

  // Bubble the state and deal with open and closing on relevant opgave
  useEffect(() => {
    if (opgave && prevOpgave && opgave.id !== prevOpgave.id) {
      setBygningMarkeret(false);
    } else if (bygningMarkeret === true) {
      setGrundMarkeret(true);
    }

    if (expand && bygningMarkeret) {
      setBygningOpen(true);
    } else {
      setBygningOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bygningMarkeret, expand]);

  useEffect(() => {
    if (opgave && prevOpgave && opgave.id !== prevOpgave.id) {
      setBygningMarkeret(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opgave]);
  const { byg021BygningensAnvendelseCLV } = bygning.bygning;
  return (
    <ExpandablePaper
      headerIcon={
        <BBRAnvendelse
          anvendelsesKode={
            byg021BygningensAnvendelseCLV !== null
              ? byg021BygningensAnvendelseCLV.key
              : 0
          }
          fontSize={27}
          opacity={0.8}
        />
      }
      opgaveIcon={
        bygningMarkeret ? <Label type={liste?.listetype} /> : undefined
      }
      title={"Bygning " + bygning.bygning.byg007Bygningsnummer}
      subtitle={
        byg021BygningensAnvendelseCLV !== null
          ? byg021BygningensAnvendelseCLV.title
          : "Kunne ikke hente information om bygningen."
      }
      nestingLevel={2}
      defaultMenuOpen={false}
      state={{ menuOpen: bygningOpen, setMenuOpen: setBygningOpen }}
    >
      <BygningInfo
        bygning={bygning}
        expand={expand}
        liste={liste}
        opgave={opgave}
        setBygningMarkeret={setBygningMarkeret}
        supplerendeOplysninger={supplerendeOplysninger}
      ></BygningInfo>
    </ExpandablePaper>
  );
}

interface IBygningProps {
  bygning: any;
  expand: boolean;
  setGrundMarkeret: Function;
  liste?: OpgaveListe;
  opgave?: Opgave;
  adresse?: string; //TODO: Maybe beliggenhedsadresse is better
  postnr?: number; //TODO: Maybe beliggenhedsadresse is better
  supplerendeOplysninger?: SupplerendeOplysninger;
}
