import React from "react";
import { OpgaveListePageOptions } from "interfaces/paging";
import Button from "components/common/Buttons/Button";
import Close from "components/common/icons/Close";
import { color } from "styles/theme";
import { OpgaveBrugerStatusToString } from "interfaces/Opgaveliste/enums";
import { kommuner } from "util/index";

export default function AktiveFiltre(props: {
  opgaveOptions: OpgaveListePageOptions;
  setOpgaveOptions: React.Dispatch<
    React.SetStateAction<OpgaveListePageOptions>
  >;
}) {
  const { opgaveOptions, setOpgaveOptions } = props;
  const { brugerStatus, brugerId, kommunenr } = opgaveOptions;
  const brugerStatuser: number[] = brugerStatus
    ? brugerStatus.split(",").map((s) => parseInt(s))
    : [];
  const kommunenumre: number[] = kommunenr
    ? kommunenr.split(",").map((s) => parseInt(s))
    : [];

  return (
    <div style={{ display: "flex", marginBottom: "1rem" }}>
      <div style={{ margin: ".65rem 1rem 0 0" }}>Filtre: </div>
      <div
        style={{
          display: "flex",
          alignItems: "top",
          flexWrap: "wrap",
          gap: ".5rem",
        }}
      >
        {brugerId && (
          <Filter
            filtertype="Mine opgaver"
            filterValue="Ja"
            removeFilter={() =>
              setOpgaveOptions({
                ...opgaveOptions,
                page: 1,
                brugerId: undefined,
              })
            }
          />
        )}
        {brugerStatuser.map((s) => {
          return (
            <Filter
              key={s}
              filtertype="Status"
              filterValue={OpgaveBrugerStatusToString(s)}
              removeFilter={() => {
                const newBrugerStatuser = brugerStatuser.filter(
                  (bs) => bs !== s
                );
                setOpgaveOptions({
                  ...opgaveOptions,
                  page: 1,
                  brugerStatus: newBrugerStatuser.join(","),
                });
              }}
            />
          );
        })}
        {kommunenumre.map((knr) => {
          return (
            <Filter
              key={knr}
              filtertype="Kommune"
              filterValue={
                kommuner.find((k) => k.knr === knr)?.navn ?? knr.toString()
              }
              removeFilter={() => {
                const newKommuner = kommunenumre.filter((bs) => bs !== knr);
                setOpgaveOptions({
                  ...opgaveOptions,
                  page: 1,
                  kommunenr: newKommuner.join(","),
                });
              }}
            />
          );
        })}
      </div>
      <Button
        type="x-small"
        onClick={() => {
          setOpgaveOptions({
            ...opgaveOptions,
            page: 1,
            brugerId: undefined,
            kommunenr: undefined,
            brugerStatus: undefined,
          });
        }}
        style={{ marginLeft: "auto", width: "auto", flexShrink: 0 }}
      >
        Ryd filtre
      </Button>
    </div>
  );
}

function Filter(props: {
  filtertype: string;
  filterValue: string;
  removeFilter: Function;
}) {
  const { filtertype, filterValue, removeFilter } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: color.green[300],
        padding: "0.5rem 1rem",
      }}
    >
      <span style={{ marginRight: "1rem" }}>{filtertype}:</span>
      <span style={{ fontWeight: 600 }}>{filterValue}</span>
      <button
        onClick={() => removeFilter()}
        style={{
          backgroundColor: color.green[900],
          marginLeft: "1rem",
          borderRadius: "100%",
          padding: 5,
          display: "flex",
        }}
      >
        <Close height={10} primaryColor={color.green[300]} />
      </button>
    </div>
  );
}
