import FoldOutMenu from "components/common/FoldOutMenu";
import React, { Fragment, useContext, useState } from "react";
import styles from "../Header.module.scss";
import ArrowTriangle from "components/common/icons/ArrowTriangle";
import Button from "components/common/Buttons/Button";
import { OpgaveBrugerStatus } from "interfaces/Opgaveliste/enums";
import { OpgaveListePageOptions } from "interfaces/paging";
import { AuthContext } from "context/AuthProvider";
import { kommuner } from "util/index";

export default function FilterOptions(props: {
  defaultOpgaveOptions: OpgaveListePageOptions;
  currentOpgaveOptions: OpgaveListePageOptions;
  setCurrentOpgaveOptions: React.Dispatch<
    React.SetStateAction<OpgaveListePageOptions>
  >;
  hide: () => void;
  brugerStatuser: OpgaveBrugerStatusUI[];
}) {
  const authState = useContext(AuthContext);
  const { bruger } = authState;
  const {
    currentOpgaveOptions,
    setCurrentOpgaveOptions,
    defaultOpgaveOptions,
    hide,
    brugerStatuser,
  } = props;
  const [opgaveOptions, setOpgaveOptions] =
    useState<OpgaveListePageOptions>(currentOpgaveOptions);
  const { brugerId, brugerStatus, kommunenr } = opgaveOptions;

  const statusButtonText = () => {
    if (!brugerStatus) return "Ingen valgt";
    const brugerStatuser = brugerStatus.split(",").map((s) => parseInt(s));
    let text = "";
    if (brugerStatuser.indexOf(OpgaveBrugerStatus.ikkeTildelt) > -1)
      text += "Åben, ";
    if (brugerStatuser.indexOf(OpgaveBrugerStatus.iGang) > -1)
      text += "I gang, ";
    if (brugerStatuser.indexOf(OpgaveBrugerStatus.opgaveLoest) > -1)
      text += "Færdig, ";
    if (brugerStatuser.indexOf(OpgaveBrugerStatus.ikkeFejl) > -1)
      text += "Ikke fejl, ";
    if (brugerStatuser.indexOf(OpgaveBrugerStatus.ikkeAktiv) > -1)
      text += "Ikke aktiv, ";
    if (brugerStatuser.indexOf(OpgaveBrugerStatus.kanIkkeLoeses) > -1)
      text += "Kan ikke løses, ";
    return text.substring(0, text.length - 2);
  };
  const brugerStatusIsActive = (value: number) => {
    if (!opgaveOptions.brugerStatus) return false;
    const brugrestatuses = opgaveOptions.brugerStatus
      .split(",")
      .map((b) => parseInt(b));
    return brugrestatuses.indexOf(value) > -1;
  };
  const setBrugerStatusIsActive = (value: number, isActive: boolean) => {
    let brugerStatuses: number[] = opgaveOptions.brugerStatus
      ? opgaveOptions.brugerStatus.split(",").map((bs) => parseInt(bs))
      : [];
    if (isActive) {
      if (brugerStatuses.indexOf(value) === -1) {
        brugerStatuses.push(value);
        setOpgaveOptions({
          ...opgaveOptions,
          page: 1,
          brugerStatus: brugerStatuses.join(","),
        });
      }
    } else {
      if (brugerStatuses.indexOf(value) > -1) {
        brugerStatuses = brugerStatuses.filter((b) => b !== value);
        setOpgaveOptions({
          ...opgaveOptions,
          page: 1,
          brugerStatus: brugerStatuses.length
            ? brugerStatuses.join(",")
            : undefined,
        });
      }
    }
  };

  const kommuneIsActive = (knr: number) => {
    if (!opgaveOptions.kommunenr) return false;
    const activeKnrs = opgaveOptions.kommunenr
      .split(",")
      .map((b) => parseInt(b));
    return activeKnrs.indexOf(knr) > -1;
  };

  const setKommuneIsActive = (value: number, isActive: boolean) => {
    let kommunenumre: number[] = opgaveOptions.kommunenr
      ? opgaveOptions.kommunenr.split(",").map((knr) => parseInt(knr))
      : [];
    if (isActive) {
      if (kommunenumre.indexOf(value) === -1) {
        kommunenumre.push(value);
        setOpgaveOptions({
          ...opgaveOptions,
          page: 1,
          kommunenr: kommunenumre.join(","),
        });
      }
    } else {
      if (kommunenumre.indexOf(value) > -1) {
        kommunenumre = kommunenumre.filter((b) => b !== value);
        setOpgaveOptions({
          ...opgaveOptions,
          page: 1,
          kommunenr: kommunenumre.length ? kommunenumre.join(",") : undefined,
        });
      }
    }
  };

  const gemKnapDisabled =
    currentOpgaveOptions.brugerId === opgaveOptions.brugerId &&
    currentOpgaveOptions.brugerStatus === opgaveOptions.brugerStatus &&
    currentOpgaveOptions.kommunenr === opgaveOptions.kommunenr;

  const showBrugerStatus = (status: OpgaveBrugerStatus) => {
    const uiStatus = brugerStatuser.find((s) => s.status === status);
    return uiStatus && uiStatus.visesUi ? true : false;
  };
  return (
    <div className={styles.filterswrapper}>
      <h3>Filtre</h3>
      <div className={styles.filters}>
        <label className={"ignore-onClickOutside " + styles.filterlabel}>
          Status
          <FoldOutMenu
            element={
              <button
                className={styles.filtrer}
                style={{
                  height: "3rem",
                  fontSize: "1rem",
                  minWidth: 200,
                  maxWidth: 200,
                }}
                title={statusButtonText()}
              >
                <span
                  style={{
                    maxWidth: 176,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {statusButtonText()}
                </span>
                <span className={styles.chevron}>
                  <ArrowTriangle />
                </span>
              </button>
            }
            menu={[
              <Fragment key="a">
                <FilterItem
                  key="Ikke tildelt"
                  isActive={brugerStatusIsActive(
                    OpgaveBrugerStatus.ikkeTildelt
                  )}
                  setIsActive={(isActive) =>
                    setBrugerStatusIsActive(
                      OpgaveBrugerStatus.ikkeTildelt,
                      isActive
                    )
                  }
                  label="Åben"
                />
                {showBrugerStatus(OpgaveBrugerStatus.iGang) && (
                  <FilterItem
                    key="I gang"
                    isActive={brugerStatusIsActive(OpgaveBrugerStatus.iGang)}
                    setIsActive={(isActive) =>
                      setBrugerStatusIsActive(
                        OpgaveBrugerStatus.iGang,
                        isActive
                      )
                    }
                    label="I gang"
                  />
                )}

                {showBrugerStatus(OpgaveBrugerStatus.opgaveLoest) && (
                  <FilterItem
                    key="Opgave løst"
                    isActive={brugerStatusIsActive(
                      OpgaveBrugerStatus.opgaveLoest
                    )}
                    setIsActive={(isActive) =>
                      setBrugerStatusIsActive(
                        OpgaveBrugerStatus.opgaveLoest,
                        isActive
                      )
                    }
                    label="Færdig"
                  />
                )}
                {showBrugerStatus(OpgaveBrugerStatus.ikkeFejl) && (
                  <FilterItem
                    key="Ikke fejl"
                    isActive={brugerStatusIsActive(OpgaveBrugerStatus.ikkeFejl)}
                    setIsActive={(isActive) =>
                      setBrugerStatusIsActive(
                        OpgaveBrugerStatus.ikkeFejl,
                        isActive
                      )
                    }
                    label="Ikke fejl"
                  />
                )}
                {showBrugerStatus(OpgaveBrugerStatus.ikkeAktiv) && (
                  <FilterItem
                    key="Ikke aktiv"
                    isActive={brugerStatusIsActive(
                      OpgaveBrugerStatus.ikkeAktiv
                    )}
                    setIsActive={(isActive) =>
                      setBrugerStatusIsActive(
                        OpgaveBrugerStatus.ikkeAktiv,
                        isActive
                      )
                    }
                    label="Ikke aktiv"
                  />
                )}
                {showBrugerStatus(OpgaveBrugerStatus.kanIkkeLoeses) && (
                  <FilterItem
                    key="Kan ikke løses"
                    isActive={brugerStatusIsActive(
                      OpgaveBrugerStatus.kanIkkeLoeses
                    )}
                    setIsActive={(isActive) =>
                      setBrugerStatusIsActive(
                        OpgaveBrugerStatus.kanIkkeLoeses,
                        isActive
                      )
                    }
                    label="Kan ikke løses"
                  />
                )}
              </Fragment>,
            ]}
          />
        </label>
        <label className={styles.filterlabel}>
          Vis kun mine opgaver
          <FoldOutMenu
            element={
              <button
                className={styles.filtrer}
                style={{
                  height: "3rem",
                  fontSize: "1rem",
                  minWidth: 200,
                }}
              >
                <span>{brugerId ? "Ja" : "Nej"}</span>
                <span className={styles.chevron}>
                  <ArrowTriangle />
                </span>
              </button>
            }
            menu={[
              <FilterItem
                key="visMine"
                isActive={brugerId ? true : false}
                setIsActive={(isActive) => {
                  setOpgaveOptions({
                    ...opgaveOptions,
                    page: 1,
                    brugerId: isActive && bruger ? bruger.id : undefined,
                  });
                }}
                label="Ja"
              />,
            ]}
          />
        </label>
        {bruger?.erSkatteforvaltningen && (
          <label className={styles.filterlabel}>
            Kommuner
            <FoldOutMenu
              element={
                <button
                  className={styles.filtrer}
                  style={{
                    height: "3rem",
                    fontSize: "1rem",
                    minWidth: 200,
                  }}
                >
                  <span>
                    {kommunenr
                      ? kommunenr.split(",").join(", ")
                      : "Ingen valgt"}
                  </span>
                  <span className={styles.chevron}>
                    <ArrowTriangle />
                  </span>
                </button>
              }
              menu={
                <div style={{ maxHeight: 350, overflowY: "auto" }}>
                  {kommuner
                    .sort((a, b) => {
                      const prepForSort = (navn: string) => {
                        return navn
                          .replace("Aa", "Å")
                          .replace("aa", "å")
                          .replace("Æ", "Z");
                      };
                      return prepForSort(a.navn) < prepForSort(b.navn) ? -1 : 1;
                    })
                    .map((kommune) => {
                      const { knr, navn } = kommune;
                      return (
                        <FilterItem
                          key={knr}
                          isActive={kommuneIsActive(knr)}
                          setIsActive={(isActive) => {
                            setKommuneIsActive(knr, isActive);
                          }}
                          label={navn}
                        />
                      );
                    })}
                </div>
              }
            />
          </label>
        )}
        <div
          className={styles.filterfooter}
          style={{
            display: "flex",
            marginLeft: "auto",
            alignSelf: "end",
            whiteSpace: "nowrap",
          }}
        >
          <Button
            type="small"
            classNames="color_green"
            onClick={() => {
              setOpgaveOptions(defaultOpgaveOptions);
              setCurrentOpgaveOptions(defaultOpgaveOptions);
              hide();
            }}
            style={{ marginRight: ".75em" }}
          >
            Ryd filtre
          </Button>
          <Button
            disabled={gemKnapDisabled}
            onClick={() => {
              setCurrentOpgaveOptions(opgaveOptions);
              hide();
            }}
            type="small-filled"
            classNames="color_green"
          >
            Filtrér
          </Button>
        </div>
      </div>
    </div>
  );
}

function FilterItem(props: {
  isActive: boolean;
  setIsActive: (active: boolean) => void;
  label: string;
}) {
  const { isActive, setIsActive, label } = props;
  return (
    <div key="a" className={styles.filtermenuitem}>
      <label
        className={styles.statuslabel}
        onClick={(e) => e.stopPropagation()}
      >
        <input
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === "Enter") {
              setIsActive(!isActive);
            } else if (e.key === " ") {
              e.preventDefault();
              setIsActive(!isActive);
            }
          }}
          checked={isActive}
          type="checkbox"
          onChange={(e) => {
            setIsActive(e.target.checked);
          }}
          onClick={(e) => e.stopPropagation()}
        ></input>
        {label}
      </label>
    </div>
  );
}
