import React, { useState, useEffect } from "react";
import styles from "./AlleArtikler.module.scss";
import artikelApi from "api/artikler";
import { motion } from "framer-motion";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { ArtikelPageOptions } from "interfaces/paging";
import { useHistory } from "react-router-dom";
import ArtikelItem from "./ArtikelItem";

export interface IAlleArtiklerState {
  state: "IDLE" | "LOADING" | "ERROR";
  data?: Artikel[];
}

export default function AlleArtikler() {
  let history = useHistory();
  const [state, setState] = useState<IAlleArtiklerState>({
    state: "LOADING",
  });
  const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;
  const [pageOptions, setPageOptions] = useState<ArtikelPageOptions>({
    page: 1,
    pageSize: pageSize,
    trimindhold: true,
  });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      setState({ state: "LOADING" }); // Might be redundant state update if state starts at loading
      if (pageOptions) {
        try {
          const ol = await artikelApi.getAllWithHeaders(pageOptions);
          if (stillMounted) {
            const listContentRange = ol.headers["list-content-range"];
            const tc = parseInt(listContentRange.split("/")[1]);
            setTotalCount(tc);
            setState({ state: "IDLE", data: ol.data });
          }
        } catch (error) {
          if (error === "CANCEL") {
            // The request was canceled, all is well
            console.debug(error);
          } else {
            if (stillMounted) {
              // Request failed, set error state
              setState({ state: "ERROR" });
            }
          }
        }
      }
    };
    fetchData();
    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOptions.page]);

  const renderArtikler = () => {
    if (!state.data) return null;
    let data = state.data;

    const jsx_elements: JSX.Element[] = data.map((o: Artikel) => {
      return (
        <li key={o.id}>
          <motion.button
            role="tab"
            aria-controls="artikel-main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={styles.opgave}
            title="Hjælp til fejllister"
            onClick={() => {
              history.push(
                `/admin?type=hjaelp-til-fejllister&handling=rediger&artikelid=${o.id}`
              );
            }}
          >
            <ArtikelItem artikel={o}></ArtikelItem>
          </motion.button>
        </li>
      );
    });
    return (
      <div>
        <ul role="tablist">
          <li className={styles.listHeader}>
            <div className={styles.titel}>
              <h2>Titel</h2>
            </div>
            <div className={styles.forfatter}>
              <h2>Forfatter</h2>
            </div>
            <div className={styles.dato}>
              <h2>Dato</h2>
            </div>
            <div className={styles.tema}>
              <h2>Tema</h2>
            </div>
            <div className={styles.forside}>
              <h2>På forsiden</h2>
            </div>
          </li>
          {jsx_elements}
        </ul>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <h1>Alle beskrivelser</h1>
      <div style={{ display: "flex" }}>
        <NextPreviousPage
          pageOptions={pageOptions}
          setPageOptions={setPageOptions}
          totalCount={totalCount}
        />
      </div>
      {state.state === "LOADING" && <p>Henter beskrivelser ...</p>}
      {state.state === "IDLE" && state.data && (
        <div className={styles.pagingbottom}>{renderArtikler()}</div>
      )}
    </div>
  );
}
