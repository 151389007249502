import React from "react";
import styles from "./EnergiOplysningerPeriodiseret.module.scss";
export default function EnergiOplysningerPeriodiseret(
  props: IEnergiOplysningerPeriodiseretProps
) {
  const { energioplysninger, visning, title } = props;

  // If the table does not contain any information, don't return anything
  if (
    ((energioplysninger && energioplysninger.length === 0) ||
      !energioplysninger) &&
    visning &&
    visning.referencer === null
  ) {
    return <></>;
  }
  const renderVisning = () => {
    if (!visning) {
      return <></>;
    }
    const key = `energi_grund_${visning.bbrId}`;
    return (
      <ul>
        <li className={styles.item} key={key}>
          <label htmlFor={key}>Energioplysninger</label>

          <div id={key}>
            <p>
              Se
              {visning?.referencer?.map((item, idx) => {
                if (
                  visning.referencer &&
                  idx === visning?.referencer?.length - 1
                ) {
                  return (
                    <React.Fragment key={idx}> {item.navn}</React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={idx}> {item.navn},</React.Fragment>
                  );
                }
              })}
            </p>
          </div>
        </li>
      </ul>
    );
  };
  const renderTable = () => {
    return (
      <section className="table-container table-container--energi">
        <h5 className="table-heading">{title ?? "Varmeforbrug"}</h5>
        <table className="c-table c-table--energi">
          <thead>
            <tr>
              <th>Årstal</th>
              <th>Forsyningsart</th>
              <th>Mængde</th>
              <th>Måleenhed</th>
            </tr>
          </thead>
          <tbody>
            {energioplysninger.map((o, i) => {
              const {
                //bbrId,
                aar,
                forsyningsart,
                kwh,
              } = o;
              let nbr_formatter = new Intl.NumberFormat("da-DK", {
                maximumFractionDigits: 0,
              });
              let kwh_thousands = nbr_formatter.format(kwh);

              return (
                <tr className="energi" key={i}>
                  <td>{aar}</td>
                  <td>{forsyningsart}</td>
                  <td className="right">
                    {kwh === -1 && <span className="nokwh">-</span>}
                    {kwh !== -1 && <span className="kwh">{kwh_thousands}</span>}
                  </td>
                  <td>kWh</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    );
  };
  return visning && visning.referencer === null
    ? renderTable()
    : renderVisning();
}

interface IEnergiOplysningerPeriodiseretProps {
  energioplysninger: EnergiOplysningerPeriodiseretOplysning[];
  visning: EnergiOplysningerPeriodiseretVisning;
  title?: string;
}
