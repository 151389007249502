import React from "react";
import styles from "./PageContainer.module.scss";
import { mergeClassNames } from "util/index";

export default function PageContainer({
  children = {},
  style = {},
  classNames = "",
}) {
  return (
    <div
      style={style}
      className={mergeClassNames(styles.container, classNames)}
    >
      {children}
    </div>
  );
}
