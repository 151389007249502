import React from "react";

export default function Hourglass(props: Icon) {
  const {
    height,
    width,
    // style = {}
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : height ? height * (12 / 12) : 12}
      height={height ? height : width ? width * (12 / 12) : 12}
      viewBox="0 0 12 12"
    >
      <g transform="translate(-213 -169)">
        <g transform="translate(-1054 -189)">
          <g
            transform="translate(1267 358)"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
          >
            <circle cx="6" cy="6" r="6" stroke="none" />
            <circle cx="6" cy="6" r="5.25" fill="none" />
          </g>
        </g>
        <g transform="translate(-1050.995 -185.995)">
          <g
            transform="translate(1266.995 357.995)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1"
          >
            <circle cx="3" cy="3" r="3" stroke="none" />
            <circle cx="3" cy="3" r="2.5" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
}
