import React from "react";

export default function Pause(props: Icon) {
  const { height, width, style = {} } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M5.2,2.5c0-0.4-0.3-0.8-0.7-0.9c-0.4,0-0.8,0.3-0.9,0.7c-0.2,2.2-0.2,4.4,0,6.5v4.6c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8l0-4.7C5,6.7,5,4.6,5.2,2.5z" />
      <path d="M12.5,8.8c-0.2-2.1-0.2-4.2,0-6.3c0-0.4-0.3-0.8-0.7-0.9c-0.4,0-0.8,0.3-0.9,0.7c-0.2,2.2-0.2,4.4,0,6.5v4.6c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8L12.5,8.8z" />
    </svg>
  );
}
