import React from "react";

export default function Arrow(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (15 / 15) : 15}
      height={height ? height : width ? width * (15 / 15) : 15}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          transform="translate(-606.000000, -627.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.5"
        >
          <g transform="translate(607.000000, 628.000000)">
            <path
              d="M4.32733777,1 C3.5257901,0.992425167 2.66539263,1.12457978 1.25604289,1.15967208 C0.859284763,1.16955121 0.437419745,1.00066793 0.0655723336,1.13939107 C-0.0755045673,1.19202188 0.101529375,1.43892744 0.103935198,1.58948277 C0.11587143,2.33644675 0.116533709,3.08363901 0.108577838,3.83065599 C0.0901430459,5.56159193 0.298124966,7.18489481 0.283898236,8.91587045 C0.270919791,10.4949664 -0.0249687508,12.1822342 0.0269651195,13.7605292 C0.0287504118,13.8147851 1.87457396,13.4432348 2.31294138,13.4484932 C4.210558,13.4712556 6.10214975,13.1721342 7.99885039,13.2353297 C8.68692877,13.2582555 12.6726014,14.0856134 12.901232,13.7605292 C13.2199019,13.3074206 12.8513195,12.6533891 12.8511404,12.0994416 C12.8507493,10.8899672 12.8972295,11.5777754 12.901232,10.3683076 C12.9034202,9.70710835 12.8813583,10.0028491 12.8714214,9.34172096"
              id="Path-59"
            ></path>
            <path
              d="M4.05832285,9.46727163 C4.80940014,8.88899877 6.18565152,7.86049072 6.88283881,7.19004476 C8.24053089,5.88442838 9.40335301,4.45092334 10.7517757,3.13573589 C11.5184051,2.38800209 13.1716644,1.84672802 13.5148912,0.854441974 C13.5194176,0.841355981 13.4871982,0.854279075 13.4733518,0.854197625"
              id="Path-60"
            ></path>
            <path
              d="M8.43105813,0.524814557 C9.28896907,0.583865676 10.5520298,0.662021305 11.4110424,0.701967913 C12.296941,0.743164806 13.2626092,0.555355483 13.5153232,0.701967913 C13.7680372,0.848580344 13.8508517,1.54795915 13.8083549,1.94888319 C13.7494681,2.50443407 13.7932376,3.066293 13.7680372,3.62438742 C13.7419947,4.20113262 13.7044922,4.77749298 13.6546591,5.35267112 C13.6534971,5.36608254 13.6295725,5.3624451 13.6170292,5.36733209"
              id="Path-61"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
