import React from "react";
import TwoPaneView from "components/common/layout/TwoPaneView";
import styles from "./Admin.module.scss";
import Sidebar from "./Sidebar/Sidebar";
import { parseQueryString } from "util/index";
import PageContainer from "components/common/layout/PageContainer";
import Pointer from "components/common/icons/Pointer";
import OpretListe from "./MainContent/Lister/OpretListe";
import AlleLister from "./MainContent/Lister/AlleLister";
import OpretArtikel from "./MainContent/Artikler/OpretArtikel";
import AlleArtikler from "./MainContent/Artikler/AlleArtikler";
import Brugere from "./MainContent/Brugere/Brugere";
import Organisationer from "./MainContent/Organisationer/Organisationer";

export default function Admin({ location }) {
  const { search } = location;
  const query = parseQueryString(search);
  const { type, handling, artikelid, listeid, page } = query;

  const renderMainContent = () => {
    return (
      <PageContainer>
        {type === undefined && handling === undefined && (
          <div className={styles.placeholder}>
            <span className={styles.arrow}>
              <Pointer width={45} />
            </span>
            <p>Vælg hvad du vil administrere i panelet til venstre</p>
          </div>
        )}
        {type === "lister" && handling === "opret" && <OpretListe />}
        {type === "lister" && handling === "rediger" && (
          <OpretListe listeId={listeid} />
        )}
        {type === "lister" && handling === "alle" && (
          <AlleLister page={parseInt(page)}></AlleLister>
        )}
        {type === "hjaelp-til-fejllister" && handling === "opret" && (
          <OpretArtikel />
        )}
        {type === "hjaelp-til-fejllister" && handling === "rediger" && (
          <OpretArtikel artikelId={artikelid} />
        )}
        {type === "hjaelp-til-fejllister" && handling === "alle" && (
          <AlleArtikler />
        )}
        {type === "brugere" && <Brugere />}
        {type === "organisationer" && <Organisationer />}
      </PageContainer>
    );
  };
  return <TwoPaneView sidebar={<Sidebar />} main={renderMainContent()} />;
}
