/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import styles from "./Links.module.scss";
import { motion } from "framer-motion";
import {
  getGeokoderUrl,
  getKortBbrUrl,
  getGoogleStreetViewUrl,
  getDanmarksAdresserUrl,
  getMarkblokkeUrl,
  getBbrKommuneUrl,
  getRetBbrUrl,
  getSkraafotoUrl,
  getBbrMeddelelseUrl,
  getByggesagsarkivurl,
  getGadefotoUrl,
} from "./linkutil";
import { EjendomState, dataIsEjenom } from "routes/Ejendom/Ejendom";
import LinkGroup from "components/common/LinkGroup";
import { getESRList } from "../../../../util";
import { Url } from "url";
import { AuthContext } from "context/AuthProvider";
import ExternalArrow from "components/common/icons/ExternalArrow";
// import { formatEjerlav } from "../Main";

interface ILinkProps {
  ejendomState: EjendomState;
  gadefotoUri?: Url;
}

export default function Links(props: ILinkProps) {
  const { ejendomState } = props;

  const user = useContext(AuthContext);
  const renderLinks = (ejendom: Ejendom) => {
    const esrNumre = getESRList(ejendom);
    const uniqueEsrNumre = Array.from(new Set(esrNumre));
    const { grunde, bfeNr, beliggenhedsadresse, kommune } = ejendom;

    // Destructuring potential null object
    const { betegnelse = "", x = 0, y = 0 } = beliggenhedsadresse || {}; //https://stackoverflow.com/a/45210379

    return (
      <div className={styles.links}>
        {grunde.length && grunde[0].grund?.bestemtFastEjendom && (
          <div className={styles.linkbuttonwrapper}>
            <a
              className="external"
              rel="noopener noreferrer"
              target="_blank"
              href={getBbrMeddelelseUrl(grunde[0].grund.bestemtFastEjendom)}
            >
              <span>BBR-meddelelse</span>
              <ExternalArrow />
            </a>
          </div>
        )}
        <div className={styles.linkbuttonwrapper}>
          <a
            className="external"
            rel="noopener noreferrer"
            target="_blank"
            href={getBbrKommuneUrl(bfeNr)}
          >
            <span>BBR Kommune</span>
            <ExternalArrow />
          </a>
        </div>
        <div className={styles.linkbuttonwrapper}>
          <a
            className="external"
            rel="noopener noreferrer"
            target="_blank"
            href={getKortBbrUrl(bfeNr)}
          >
            <span>BBR Kort</span>
            <ExternalArrow />
          </a>
        </div>
        {kommune?.byggesagsarkivUri && (
          <LinkGroup title="Byggesagsarkiv">
            {uniqueEsrNumre.map((esrnr, i) => {
              // Geokoderen TODO: Clean link logic up
              let appendix =
                uniqueEsrNumre.length > 1 ? `ESR ${esrnr}` : "Byggesagsarkiv";
              return (
                <div key={i} className={styles.linkbuttonwrapper}>
                  <a
                    className="external"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={kommune ? getByggesagsarkivurl(kommune, esrnr) : "#"}
                  >
                    <span>{appendix}</span>
                    <ExternalArrow />
                  </a>
                </div>
              );
            })}
          </LinkGroup>
        )}
        {x !== 0 && y !== 0 && (
          <div className={styles.linkbuttonwrapper}>
            <a
              className="external"
              rel="noopener noreferrer"
              target="_blank"
              href={getDanmarksAdresserUrl(x, y)}
            >
              <span>Danmarks Adresser</span>
              <ExternalArrow />
            </a>
          </div>
        )}
        {/* Done: issue #112 */}
        {user?.bruger?.gadefotoUri && x !== 0 && y !== 0 && (
          <div className={styles.linkbuttonwrapper}>
            <a
              className="external"
              target="_blank"
              rel="noopener noreferrer"
              href={getGadefotoUrl(user.bruger.gadefotoUri.toString(), x, y)}
            >
              <span>Danmarks Digitale Gadefoto</span>
              <ExternalArrow />
            </a>
          </div>
        )}
        <div className={styles.linkbuttonwrapper}>
          <a
            className="external"
            rel="noopener noreferrer"
            target="_blank"
            href="https://chr.fvst.dk/"
          >
            <span>Det Centrale Husdyrregister</span>
            <ExternalArrow />
          </a>
        </div>
        <div className={styles.linkbuttonwrapper}>
          <a
            className="external"
            rel="noopener noreferrer"
            target="_blank"
            href="https://datacvr.virk.dk/"
          >
            <span>Det Centrale Virksomhedsregister</span>
            <ExternalArrow />
          </a>
        </div>
        <LinkGroup title="Geokoderen">
          {grunde.map((grund, i) => {
            let appendix = "Geokoderen";
            if (grunde.length > 1) {
              appendix =
                grund.jordstykker && grund.jordstykker.length > 0
                  ? `Grund - ${grund.navnFraJordstykker}`
                  : `Grund ${i + 1}`;
            }
            return (
              <div key={i} className={styles.linkbuttonwrapper}>
                <a
                  className="external"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={getGeokoderUrl(grund)}
                >
                  <span>{appendix}</span>
                  <ExternalArrow />
                </a>
              </div>
            );
          })}
        </LinkGroup>
        {x !== 0 && y !== 0 && (
          <div className={styles.linkbuttonwrapper}>
            <a
              className="external"
              rel="noopener noreferrer"
              target="_blank"
              href={getGoogleStreetViewUrl(x, y)}
            >
              <span>Google Street View</span>
              <ExternalArrow />
            </a>
          </div>
        )}
        {betegnelse && x !== 0 && y !== 0 && (
          <div className={styles.linkbuttonwrapper}>
            <a
              className="external"
              rel="noopener noreferrer"
              target="_blank"
              href={getMarkblokkeUrl(x, y, betegnelse)}
            >
              <span>Markblokke</span>
              <ExternalArrow />
            </a>
          </div>
        )}
        {x !== 0 && y !== 0 && (
          <div className={styles.linkbuttonwrapper}>
            <a
              className="external"
              rel="noopener noreferrer"
              target="_blank"
              href={getSkraafotoUrl(x, y)}
            >
              <span>Skråfoto</span>
              <ExternalArrow />
            </a>
          </div>
        )}
        {grunde.length && grunde[0].grund?.bestemtFastEjendom && (
          <div className={styles.linkbuttonwrapper}>
            <a
              className="external"
              rel="noopener noreferrer"
              target="_blank"
              href={getRetBbrUrl(grunde[0].grund.bestemtFastEjendom)}
            >
              <span>Ret BBR</span>
              <ExternalArrow />
            </a>
          </div>
        )}
      </div>
    );
  };
  const renderLoading = () => {
    return Array.from(new Array(10)).map((o, i) => {
      return (
        <motion.li key={i} className={`${styles.ejer} ${styles.loading}`}>
          <div className={styles.text}>
            <div
              style={{ width: 60 + Math.random() * 20 + "%" }}
              className={styles.line}
            ></div>
          </div>
        </motion.li>
      );
    });
  };
  return (
    <>
      {ejendomState.state === "IDLE" &&
        dataIsEjenom(ejendomState.data) &&
        renderLinks(ejendomState.data)}
      {ejendomState.state === "LOADING" && renderLoading()}
    </>
  );
}
