import React, { useState, useEffect } from "react";

export default function Minus(props: Icon) {
  const {
    height,
    width,
    primaryColor = "currentColor",
    secondaryColor = "#ffffff",
    style = {},
    hovered,
  } = props;
  const [pColor, setPColor] = useState(primaryColor);
  const [sColor, setSColor] = useState(secondaryColor);
  useEffect(() => {
    setPColor(hovered ? secondaryColor : primaryColor);
    setSColor(hovered ? primaryColor : secondaryColor);
  }, [hovered, primaryColor, secondaryColor]);
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (30 / 30) : 30}
      height={height ? height : width ? width * (30 / 30) : 30}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/Plus-icon" strokeWidth="1.6">
          <g id="icon/minus-icon">
            <path
              d="M6.6437,26.6407 C4.3107,25.2417 -4.0893,14.0407 4.7767,5.1737 C13.6437,-3.6933 25.7777,2.8407 28.1107,7.9737 C30.4447,13.1077 28.5777,21.5077 21.5777,25.7077 C14.5777,29.9087 8.9767,28.0407 6.6437,26.6407 Z"
              id="Stroke-1"
              stroke={pColor}
              fill={sColor}
            ></path>
            <path
              d="M5.0641,13.1798 L13.0321,13.1798 C13.0321,13.1798 21.0001,12.6238 24.0641,13.1798"
              id="Stroke-5"
              stroke={pColor}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
