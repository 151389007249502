import React from "react";
import styles from "./Login.module.scss";
import Button from "components/common/Buttons/Button";
import LoginIcon from "components/common/icons/LoginIcon";
export default function Login() {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <div className={styles.grid}>
          <div className={styles.col1}>
            <h1>Velkommen til BBR Explore</h1>
            <p>
              BBR Explore er udviklet af BBR-kontoret i Vurderingsstyrelsen til
              internt brug.
            </p>
            <h3 className="my-4">Vælg din login-metode:</h3>
            <div className="d-flex flex-column flex-gap">
              <Button
                type="x-large"
                classNames="justify-content-between gap-4"
                onClick={() => (window.location.href = `/authsaml/login`)}
              >
                <span>
                  <span className="h2 d-block">
                    Kommunalt Login (Adgangsstyring)
                  </span>
                  <span className="text-muted small m-0 mt-2 d-block lh-base">
                    Login via single sign-on via rettighedsstyring i din
                    kommune.
                  </span>
                </span>
                <span className="rounded-circle bg-light text-primary d-flex align-items-center justify-content-center">
                  <LoginIcon />
                </span>
              </Button>
              <Button
                type="x-large"
                classNames="justify-content-between gap-4"
                onClick={() => (window.location.href = `/auth/login`)}
              >
                <span>
                  <span className="h2 d-block">Danmarks Miljøportal (DMP)</span>
                  <span className="text-muted small m-0 mt-2 d-block lh-base">
                    Login via DMP-brugeradgang. Dette er det almene login for
                    brugere af BBR Explore, der ikke er kommunale.
                  </span>
                </span>
                <span className="rounded-circle bg-light text-primary d-flex align-items-center justify-content-center">
                  <LoginIcon />
                </span>
              </Button>
            </div>
            <p className="small mt-4">
              Hvis du oplever problemer med at logge på, så kontakt{" "}
              <strong className="text-primary fw-semibold">bbr@bbr.dk</strong>
            </p>
          </div>

          <div className={styles.col2}>
            <img
              src="https://bbr.dk/file/654761/test2.jpeg"
              alt="Billede af hus"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
