import React from "react";
import styles from "./Logo.module.scss";
import Bbr from "components/common/icons/Bbr";
import { NavLink } from "react-router-dom";

export default function Logo() {
  return (
    <NavLink to="/" className={styles.logo} title="Til forsiden">
      <Bbr width={84} />
      <span aria-hidden="true" className={styles.text}>
        Explore
      </span>
      <span className={styles.hidden}>BBR Explore logo</span>
    </NavLink>
  );
}
