import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Home from "./routes/Home/Home";
import Nav from "./components/Nav/Nav";
import NotFound from "./components/Error/NotFound";
import FindEjendom from "./routes/FindEjendom/FindEjendom";
import Statistik from "./routes/Statistik/Statistik";
import Fejllister from "./routes/Fejllister/Fejllister";
import Artikler from "./routes/Artikler/Artikler";
import Ejendom from "routes/Ejendom/Ejendom";
import AuthProvider, { AuthContext } from "./context/AuthProvider";
import Admin from "routes/Admin/Admin";
import ToastProvider from "context/ToastProvider";
import Forbidden from "components/Error/Forbidden";
import Login from "components/Error/Login";
import RealTimeProvider from "context/RealTimeProvider";

function App() {
  const location = useLocation();
  const authState = useContext(AuthContext);
  return (
    <div id="App">
      <Nav authState={authState} />
      <Switch key={location.pathname}>
        <PrivateRoute
          requiredRolle="miljoe_bbrexplore_adgang"
          path="/search"
          component={FindEjendom}
          key={location.pathname}
          authState={authState}
        />
        <PrivateRoute
          requiredRolle="fejllister"
          path="/fejllister"
          component={Fejllister}
          key={location.pathname}
          authState={authState}
        />
        <PrivateRoute
          requiredRolle="fejllister"
          path="/hjaelp-til-fejllister"
          component={Artikler}
          key={location.pathname}
          authState={authState}
        />
        <PrivateRoute
          requiredRolle="statistik"
          path="/statistik"
          component={Statistik}
          key={location.pathname}
          authState={authState}
        />
        <PrivateRoute
          requiredRolle="miljoe_bbrexplore_adgang"
          path="/ejendom"
          component={Ejendom}
          key={location.pathname}
          authState={authState}
        />
        <PrivateRoute
          requiredRolle="administrator"
          path="/admin"
          component={Admin}
          key={location.pathname}
          authState={authState}
        />

        <Route
          exact
          path="/"
          render={() => {
            switch (authState.state) {
              case "FORBIDDEN":
                return <Route component={Forbidden} />;
              case "UNAUTHORIZED":
                return <Route component={Login} />;
              default:
                return <Home authState={authState} />;
            }
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

const AppWrapper = () => {
  return (
    <AuthProvider>
      <ToastProvider>
        <RealTimeProvider>
          <Router>
            <App />
          </Router>
        </RealTimeProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default AppWrapper;
