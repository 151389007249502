import React, { useState, useEffect } from "react";
import BBRTekKlassifikation from "components/common/icons/BBRTekKlassifikation";
import Label from "components/common/PriorityLabels/Label";
import ExpandablePaper from "components/common/ExpandablePaper";
import TekniskAnlaegInfo from "./TekniskAnlaegInfo";

export default function TekniskAnlaeg(props: ITekniskAnlaegProps) {
  const {
    tekniskAnlaeg,
    liste,
    opgave,
    setGrundMarkeret,
    expand,
    supplerendeOplysninger,
  } = props;

  const [tekniskAnlaegMarkeret, setTekniskAnlaegMarkeret] = useState(false);
  const [tekniskAnlaegOpen, setTekniskAnlaegOpen] = useState(false);

  // Bubble the state and deal with open and closing on relevant opgave
  useEffect(() => {
    if (tekniskAnlaegMarkeret === true) {
      setGrundMarkeret(true);
    }
    setTekniskAnlaegOpen(tekniskAnlaegMarkeret && expand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tekniskAnlaegMarkeret, expand]);

  return (
    <ExpandablePaper
      headerIcon={
        <BBRTekKlassifikation
          klassifikationsKode={tekniskAnlaeg.tekniskAnlaeg.tek020Klassifikation}
          fontSize={27}
          opacity={0.8}
        />
      }
      opgaveIcon={
        tekniskAnlaegMarkeret ? <Label type={liste?.listetype} /> : undefined
      }
      title={"Teknisk anlæg " + tekniskAnlaeg.tekniskAnlaeg.tek007Anlægsnummer}
      subtitle={
        tekniskAnlaeg.tekniskAnlaeg.tek020KlassifikationCLV !== null
          ? tekniskAnlaeg.tekniskAnlaeg.tek020KlassifikationCLV.title
          : "Kunne ikke hente information om det tekniske anlæg."
      }
      nestingLevel={2}
      defaultMenuOpen={false}
      state={{ menuOpen: tekniskAnlaegOpen, setMenuOpen: setTekniskAnlaegOpen }}
    >
      <TekniskAnlaegInfo
        tekniskAnlaeg={tekniskAnlaeg}
        expand={expand}
        liste={liste}
        opgave={opgave}
        setTekniskAnlaegMarkeret={setTekniskAnlaegMarkeret}
        supplerendeOplysninger={supplerendeOplysninger}
      ></TekniskAnlaegInfo>
    </ExpandablePaper>
  );
}

interface ITekniskAnlaegProps {
  tekniskAnlaeg: any;
  expand: boolean;
  setGrundMarkeret: Function;
  liste?: OpgaveListe;
  opgave?: Opgave;
  adresse?: string; //TODO: Maybe beliggenhedsadresse is better
  postnr?: number; //TODO: Maybe beliggenhedsadresse is better
  supplerendeOplysninger?: SupplerendeOplysninger;
}
