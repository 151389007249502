import React from "react";
import styles from "./BrugerList.module.scss";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { PageOptions } from "interfaces/paging";
import { motion } from "framer-motion";

interface BrugerListProps {
  pageOptions: PageOptions;
  setPageOptions: React.Dispatch<React.SetStateAction<PageOptions>>;
  totalCount: number;
  brugere: Bruger[];
  history: any;
}
export default function BrugerList(props: BrugerListProps) {
  const { pageOptions, setPageOptions, totalCount, brugere, history } = props;
  return (
    <>
      <div style={{ display: "flex" }}>
        <NextPreviousPage
          pageOptions={pageOptions}
          setPageOptions={setPageOptions}
          totalCount={totalCount}
        />
      </div>

      <ul role="tablist">
        <li className={styles.listHeader}>
          <div className={styles.navn}>
            <h2>Navn</h2>
          </div>
          <div className={styles.org}>
            <h2>Organisation</h2>
          </div>
          <div className={styles.cvr}>
            <h2>CVR</h2>
          </div>
          <div className={styles.roller}>
            <h2>Antal roller</h2>
          </div>
        </li>
        {brugere.map((bruger, i) => {
          return (
            <li key={bruger.id}>
              <motion.button
                role="tab"
                aria-controls="artikel-main-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={styles.opgave}
                title={bruger.navn}
                onClick={() => {
                  history.push(`/admin?type=brugere&brugerid=${bruger.id}`);
                }}
              >
                <div className={styles.listrow}>
                  <div className={styles.navn}>
                    <h2>{bruger.navn}</h2>
                  </div>
                  <div className={styles.org}>{bruger.organisationNavn}</div>
                  <div className={styles.cvr}>{bruger.cvr}</div>
                  <div className={styles.roller}>{bruger.roller.length}</div>
                </div>
              </motion.button>
            </li>
          );
        })}
      </ul>
    </>
  );
}
