import React from "react";
import styles from "./LinkGroup.module.scss";
export default function LinkGroup(props: ILinkGroupProps) {
  const { title, children } = props;
  return (
    // Checks if the length of the children is larger than 1, and styles accordingly
    <div
      className={
        React.Children.toArray(children).length > 1
          ? styles.container
          : styles.single
      }
    >
      {React.Children.toArray(children).length > 1 && (
        <span className={styles.title}>{title}</span>
      )}
      {children}
    </div>
  );
}

interface ILinkGroupProps {
  title: string;
  children: React.ReactNode;
}
