import React, { useEffect, useState } from "react";
import styles from "./Brugere.module.scss";
import brugerApi from "api/bruger";
import rolleApi from "api/rolle";
import { PageOptions } from "interfaces/paging";
import { useHistory } from "react-router-dom";
import { parseQueryString } from "util/index";
import BrugerList from "./BrugerList";
import Bruger from "./Bruger";

interface IBrugerState {
  state: "IDLE" | "LOADING" | "ERROR";
  data?: Bruger[];
}
interface IRolleState {
  state: "IDLE" | "LOADING" | "ERROR";
  data: Rolle[];
}

export default function Brugere() {
  const history = useHistory();
  const { search } = history.location;
  const query = parseQueryString(search);
  const { brugerid } = query;

  const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;

  const [state, setState] = useState<IBrugerState>({
    state: "LOADING",
  });
  const [rolleState, setRolleState] = useState<IRolleState>({
    state: "LOADING",
    data: [],
  });

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 1,
    pageSize: pageSize,
  });

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      setState({ state: "LOADING" });
      if (pageOptions) {
        try {
          const ol = await brugerApi.getAllWithHeaders(pageOptions);
          if (stillMounted) {
            const listContentRange = ol.headers["list-content-range"];
            const tc = parseInt(listContentRange.split("/")[1]);
            setTotalCount(tc);
            setState({ state: "IDLE", data: ol.data });
          }
        } catch (error) {
          if (error === "CANCEL") {
            console.debug(error);
          } else {
            if (stillMounted) {
              setState({ state: "ERROR" });
            }
          }
        }
      }
    };
    fetchData();
  }, [pageOptions]);
  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      try {
        const roller = await rolleApi.getAll();
        if (stillMounted) {
          setRolleState({ state: "IDLE", data: roller });
        }
      } catch (error) {
        if (error === "CANCEL") {
          console.debug(error);
        } else {
          if (stillMounted) {
            setRolleState({ state: "ERROR", data: [] });
          }
        }
      }
    };
    fetchData();
  }, []);
  const updateBruger = (bruger: Bruger) => {
    if (state.data) {
      const index = state.data.findIndex((b) => b.id === bruger.id);
      if (index > -1) {
        setState({
          ...state,
          data: [
            ...state.data.slice(0, index),
            bruger,
            ...state.data.slice(index + 1),
          ],
        });
      }
    }
  };
  return (
    <div className={styles.container}>
      <h1>Brugere</h1>
      {state.state === "IDLE" && state.data && !brugerid && (
        <BrugerList
          brugere={state.data}
          history={history}
          pageOptions={pageOptions}
          setPageOptions={setPageOptions}
          totalCount={totalCount}
        />
      )}
      {state.state === "IDLE" && state.data && brugerid && (
        <Bruger
          roller={rolleState.data}
          brugerid={brugerid}
          bruger={state.data.find((b) => b.id === brugerid)}
          history={history}
          updateBruger={updateBruger}
        />
      )}
    </div>
  );
}
