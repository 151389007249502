import React from "react";

export default function ArrowTriangle(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (11 / 9) : 11}
      height={height ? height : width ? width * (9 / 11) : 9}
      viewBox="0 0 11 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          transform="translate(-1189.000000, -48.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.5"
        >
          <g transform="translate(1194.518148, 52.266053) rotate(-180.000000) translate(-1194.518148, -52.266053) translate(1190.018148, 49.266053)">
            <path d="M3.38840067e-13,5.61414033 C2.65883451,1.97899603 1.161829,3.80147595 4.55454172,0.199504807"></path>
            <path d="M4.55454172,-7.46069873e-14 C7.47380371,4.33548055 5.97235915,2.4256534 8.94971056,5.81364513"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
