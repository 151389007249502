import React from "react";

export default function LoginIcon(props: Icon) {
  const { height, width } = props;
  return (
    <svg
      width={width ? width : height ? height : 17}
      height={height ? height : width ? width : 17}
      viewBox="0 0 17 17"
      fill="none"
      className="me-1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.375,18.113a.707.707,0,0,1-.212-.519.632.632,0,0,1,.212-.5l1.889-1.889H6.708a.708.708,0,1,1,0-1.417h7.508L12.3,11.879a.655.655,0,0,1-.189-.484.718.718,0,0,1,1.228-.508l3.14,3.14a.72.72,0,0,1,.165.236.738.738,0,0,1,0,.519.72.72,0,0,1-.165.236l-3.117,3.117a.655.655,0,0,1-.484.189.693.693,0,0,1-.508-.212ZM15.421,23a.708.708,0,1,1,0-1.417h6.162V7.417H15.421a.708.708,0,1,1,0-1.417h6.162A1.453,1.453,0,0,1,23,7.417V21.583A1.453,1.453,0,0,1,21.583,23Z"
        transform="translate(-6 -6)"
        fill="currentColor"
      />
    </svg>
  );
}
