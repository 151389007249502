import React, { useState, useEffect } from "react";
import styles from "./Organisation.module.scss";
import Chevron from "components/common/icons/Chevron";
import organisationApi from "api/organisation";
import { Spacing } from "components/common/layout/Spacing";
import Button from "components/common/Buttons/Button";

interface OrganisationProps {
  id: string;
  organisation?: Organisation;
  history: any;
  updateOrganisation: Function;
}
interface OrganisationState {
  state: "IDLE" | "LOADING" | "ERROR";
  organisation?: Organisation;
}

export default function Organisation(props: OrganisationProps) {
  const { id, organisation, history, updateOrganisation } = props;
  const [state, setState] = useState<OrganisationState>({
    state: organisation ? "IDLE" : "LOADING",
    organisation,
  });

  const [redigerEmail, setRedigerEmail] = useState<boolean>(false);
  const [orgEmail, setOrgEmail] = useState<string>("");

  useEffect(() => {
    if (redigerEmail) {
      setOrgEmail(state.organisation?.email ? state.organisation?.email : "");
    } else {
      setOrgEmail("");
    }
  }, [redigerEmail, state.organisation]);

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      try {
        const resp = await organisationApi.getById(id);
        if (stillMounted) {
          setState({ state: "IDLE", organisation: resp });
        }
      } catch (error) {
        if (error === "CANCEL") {
          console.debug(error);
        } else {
          if (stillMounted) {
            setState({ state: "ERROR" });
          }
        }
      }
    };
    if (!organisation) {
      fetchData();
    }
  }, [id, organisation]);

  const updateOrganisationEmail = async () => {
    setState({ state: "LOADING" });
    try {
      if (state.organisation) {
        await organisationApi.updateOrganisation(state.organisation.id, {
          ...state.organisation,
          email: orgEmail,
        });

        // Put fungerede, opdater organisation her, og i liste
        setState({
          state: "IDLE",
          organisation: { ...state.organisation, email: orgEmail },
        });
        updateOrganisation({ ...state.organisation, email: orgEmail });
      }
    } catch (error) {
      setState({ state: "ERROR" });
    }
    // setState({ ...state, bruger: updatedUser });
    setRedigerEmail(false);
  };

  return (
    <div className={styles.container}>
      {state.state === "IDLE" && state.organisation && (
        <div>
          <div className={styles.header}>
            <h2>{state.organisation.navn}</h2> <i>{state.organisation.cvr}</i>
          </div>
          <h3>Email</h3>
          <div>
            {!redigerEmail && (
              <div className={styles.rolle}>
                <span>
                  {state.organisation.email
                    ? state.organisation.email
                    : "Ingen email sat"}
                </span>
              </div>
            )}
            {redigerEmail && (
              <div className={styles.rolle}>
                <label>
                  <input
                    type="text"
                    value={orgEmail}
                    onChange={(e) => {
                      if (state.organisation) {
                        setOrgEmail(e.target.value);
                      }
                    }}
                  />
                  <span className={styles.checkbox}></span>
                </label>
              </div>
            )}

            <div className={styles.twobuttons}>
              {redigerEmail && (
                <>
                  <Button
                    type="small"
                    classNames={styles.cta}
                    onClick={() => updateOrganisationEmail()}
                  >
                    Gem email
                  </Button>
                  <Spacing />
                  <Button
                    type="small"
                    onClick={() => setRedigerEmail(!redigerEmail)}
                  >
                    {!redigerEmail && "Rediger"}
                    {redigerEmail && "Afbryd"}
                  </Button>
                </>
              )}
              {!redigerEmail && (
                <>
                  <Button
                    classNames={styles.cta}
                    type="small"
                    onClick={() => setRedigerEmail(!redigerEmail)}
                  >
                    <span>Rediger email</span>
                  </Button>
                  <Spacing />
                  <div className={styles.dummy}></div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {state.state === "ERROR" && (
        <div>Fandt ikke organisation med id {id}</div>
      )}
      {state.state === "LOADING" && <div>Laster ...</div>}
      <Spacing h={1} />
      <button
        className={styles.tilbage}
        onClick={() => history.push(`/admin?type=organisationer`)}
      >
        <Chevron /> Tilbage til listen
      </button>
    </div>
  );
}
