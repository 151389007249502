import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { mergeClassNames } from "util/index";
import styles from "./EnhedModal.module.scss";

export default function EnhedModal(props: IEnhedModal) {
  const { children, expand, shown } = props;

  const variants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };
  return (
    <div className={styles.container}>
      <AnimatePresence>
        {shown && (
          <motion.div
            className={mergeClassNames(
              styles.containerContent,
              expand ? styles.expand : ""
            )}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
            transition={{ duration: 0.15 }}
          >
            <div className={styles.inner}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IEnhedModal {
  expand: boolean;
  shown: boolean;
  children: React.ReactNode;
}
