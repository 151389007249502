import React, { useState, useEffect } from "react";
import styles from "./AlleLister.module.scss";
import opgavelisteApi from "api/opgavelister";
import { motion } from "framer-motion";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { useHistory } from "react-router-dom";
import OpgaveListeItem from "./OpgaveListeItem";
import { PageOptions } from "interfaces/paging";
import { useQuery } from "hooks/useQuery";

export interface IAlleListerState {
  state: "IDLE" | "LOADING" | "ERROR";
  data?: OpgaveListe[];
}

export default function AlleLister(props: { page: number }) {
  const { page } = props;
  let history = useHistory();
  const query = useQuery();
  const queryPage = query.get("page");
  const [state, setState] = useState<IAlleListerState>({
    state: "LOADING",
  });
  const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      setState({ state: "LOADING" }); // Might be redundant state update if state starts at loading
      try {
        const ol = await opgavelisteApi.getAllWithHeaders({ page, pageSize });
        if (stillMounted) {
          const listContentRange = ol.headers["list-content-range"];
          const tc = parseInt(listContentRange.split("/")[1]);
          setTotalCount(tc);
          setState({ state: "IDLE", data: ol.data });
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setState({ state: "ERROR" });
          }
        }
      }
    };
    fetchData();
    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const renderLister = () => {
    if (!state.data) return null;
    let data = state.data;

    const jsx_elements: JSX.Element[] = data.map((o: OpgaveListe) => {
      return (
        <li key={o.id}>
          <motion.button
            role="tab"
            aria-controls="opgaveliste-main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={styles.opgave}
            title="Lister"
            onClick={() => {
              history.push(
                `/admin?type=lister&handling=rediger&listeid=${o.id}&page=${page}`
              );
            }}
          >
            <OpgaveListeItem opgaveliste={o}></OpgaveListeItem>
          </motion.button>
        </li>
      );
    });
    return (
      <div>
        <ul role="tablist">
          <li className={styles.listHeader}>
            <div className={styles.navn}>
              <h2>Navn</h2>
            </div>
            <div className={styles.beskrivelse}>
              <h2>Beskrivelse</h2>
            </div>
            <div className={styles.antal}>
              <h2>Antal</h2>
            </div>
            <div className={styles.listetype}>
              <h2>Listetype</h2>
            </div>
            <div className={styles.status}>
              <h2>Status</h2>
            </div>
          </li>
          {jsx_elements}
        </ul>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <h1>Alle Lister</h1>
      <div style={{ display: "flex" }}>
        <NextPreviousPage
          pageOptions={{ page, pageSize }}
          setPageOptions={(options: PageOptions) => {
            console.log(options);
            if (queryPage && options.page !== parseInt(queryPage)) {
              query.set("page", options.page.toString());
              history.push("/admin?" + query.toString());
            }
          }}
          totalCount={totalCount}
        />
      </div>
      {state.state === "LOADING" && <p>Henter lister ...</p>}
      {state.state === "IDLE" && state.data && (
        <div className={styles.pagingbottom}>{renderLister()}</div>
      )}
    </div>
  );
}
