import React from "react";
import { useHistory } from "react-router";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Accordion.module.scss";
import MinusSimple from "components/common/icons/MinusSimple";
import PlusSimple from "components/common/icons/PlusSimple";
import { mergeClassNames } from "util/index";

const variants = {
  open: { opacity: 1, height: "auto" },
  closed: { opacity: 0, height: 0 },
};

export default function Accordion(props: AccordionProps) {
  const {
    isOpen,
    toggleIsOpen,
    headerText,
    fejllisterState,
    currentOpgaveliste,
  } = props;
  let history = useHistory();
  return (
    <div
      className={mergeClassNames(
        styles.container,
        isOpen ? styles.expanded : ""
      )}
    >
      <button
        aria-expanded={isOpen}
        aria-controls="tablist-content"
        className={styles.accordionheader}
        onClick={() => {
          toggleIsOpen();
        }}
      >
        <h1 className={styles.headertext}>{headerText}</h1>
        <AnimatePresence>
          {isOpen && (
            <motion.span
              className={styles.expand}
              key={isOpen.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <MinusSimple />
            </motion.span>
          )}
          {!isOpen && (
            <motion.span
              className={styles.expand}
              key={isOpen.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <PlusSimple />
            </motion.span>
          )}
        </AnimatePresence>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            id="tablist-content"
            className={styles.opgaver}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
            transition={{ damping: 300 }}
          >
            <ul role="tablist">
              {fejllisterState.state === "LOADING" &&
                Array.from(new Array(6)).map((o, i) => {
                  return (
                    <motion.li
                      key={i}
                      className={`${styles.opgave} ${styles.loading}`}
                    >
                      <div className={styles.text}>
                        <div className={styles.line}></div>
                        <div className={styles.line}></div>
                      </div>
                    </motion.li>
                  );
                })}
              {fejllisterState.state === "IDLE" &&
                fejllisterState.data.map((opgaveliste) => {
                  return (
                    <li key={opgaveliste.id}>
                      <motion.button
                        role="tab"
                        aria-controls="opgaveliste-main-content"
                        aria-selected={
                          currentOpgaveliste &&
                          currentOpgaveliste.id === opgaveliste.id
                            ? true
                            : false
                        }
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className={mergeClassNames(
                          styles.opgave,
                          currentOpgaveliste &&
                            currentOpgaveliste.id === opgaveliste.id
                            ? styles.selected
                            : ""
                        )}
                        title={opgaveliste.beskrivelse}
                        onClick={() => history.push("?id=" + opgaveliste.id)}
                      >
                        <div className={styles.titleContainer}>
                          <h2 className={styles.titel}>{opgaveliste.navn}</h2>
                          <p className={styles.beskrivelse}>
                            {opgaveliste.beskrivelse}
                          </p>
                          <p className={styles.antal}>
                            {opgaveliste.antalOpgaver} til gennemsyn
                          </p>
                        </div>
                      </motion.button>
                    </li>
                  );
                })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface AccordionProps {
  isOpen: boolean;
  toggleIsOpen: Function;
  headerText: string;
  fejllisterState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data: Array<OpgaveListe>;
  };
  currentOpgaveliste?: OpgaveListe;
}
