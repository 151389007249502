import React from "react";
import styles from "./Toast.module.scss";
import { mergeClassNames } from "util/index";
import { AnimatePresence, motion } from "framer-motion";

export interface IToast {
  status: "ERROR" | "OK";
  text: string;
  show: boolean;
  duration?: number;
}

interface ToastProps {
  options: IToast;
}

export default function Toast(props: ToastProps) {
  const { text, status, show } = props.options;
  const statusClassName = status === "OK" ? styles.ok : styles.error;
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9, x: "-50%" }} // animate from
          animate={{ opacity: 1, scale: 1, x: "-50%" }} // animate to
          exit={{ opacity: 0, scale: 0.9, x: "-50%" }} // animate exit
          className={mergeClassNames(styles.notification, statusClassName)}
        >
          {text}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
