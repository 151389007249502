import React, { useState, useEffect } from "react";
import authApi from "../api/auth";

export interface AuthState {
  state: "IDLE" | "LOADING" | "ERROR" | "UNAUTHORIZED" | "FORBIDDEN";
  bruger?: Bruger;
}

export const AuthContext = React.createContext<AuthState>({
  state: "LOADING",
});

const AuthProvider = (props: any) => {
  const [authState, setAuthState] = useState<AuthState>({
    state: "LOADING",
  });

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      try {
        const bruger = await authApi.getMe();
        // DEBUG: add roles
        //bruger.roller = [{
        //  id: "55b5c1a9-c6b9-45b2-8d73-1c2b28d46a9d",
        //  navn: "Testrolle"
        //},{
        //  id: "55b5c1a9-c6b9-45b2-8d73-1c2b28d46a9e",
        //  navn: "fejllister"
        //}
        //]

        if (stillMounted) {
          setAuthState({
            state: "IDLE",
            bruger,
          });
        }
      } catch (error) {
        // Error is an object here
        if (error === "CANCEL") {
        } else {
          if (stillMounted) {
            if ((error as any)?.isAxiosError) {
              // Login sucessful, but without access to bbr-explore
              if ((error as any)?.response.status === 401) {
                setAuthState((s) => {
                  return { state: "UNAUTHORIZED" };
                });
                // Login unsuccesful
              } else if ((error as any)?.response.status === 403) {
                setAuthState((s) => {
                  return {
                    state: "FORBIDDEN",
                    bruger: (error as any)?.response.data,
                  };
                });
              } else {
                // Unexpected error
                setAuthState((s) => {
                  return { state: "ERROR" };
                });
              }
            } else {
              // Unexpected error
              setAuthState((s) => {
                return { state: "ERROR" };
              });
            }
          }
        }
      }
    };
    fetchData();
    return () => {
      stillMounted = false;
    };
  }, []);
  return (
    <AuthContext.Provider value={authState}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
