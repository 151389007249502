import React from "react";
import styles from "./ArtikelItem.module.scss";
import { format } from "date-fns";
import { da } from "date-fns/esm/locale";

export default function ArtikelItem(props: IArtikelItemProps) {
  const { artikel } = props;
  const { forfatter, dato, forside } = artikel;

  // TODO: temporary hack because API does not have a title for artikler
  const titel = artikel.titel ?? "Indsæt titel her";
  // TODO: temporary hack because API does not correspond to typed interface
  const artikelTema = {
    id: artikel["temaId"],
    navn: artikel["temaNavn"],
  };
  let parsedDate = format(new Date(dato), "PPP", {
    locale: da,
  });
  return (
    <div className={styles.listrow}>
      <div className={styles.titel}>
        <h2>{titel}</h2>
      </div>
      <div className={styles.forfatter}>{forfatter}</div>
      <div className={styles.dato}>{parsedDate}</div>
      <div className={styles.tema}>{artikelTema.navn}</div>
      <div className={styles.forside}>{forside ? "Ja" : "Nej"}</div>
    </div>
  );
}

interface IArtikelItemProps {
  artikel: Artikel;
}
