import React, { useState, useEffect, useCallback, useMemo } from "react";
import artikelApi from "api/artikler";
import { ArtikelPageOptions } from "interfaces/paging";
import styles from "./Artikler.module.scss";
import TwoPaneView from "components/common/layout/TwoPaneView";
import Sidebar from "./Sidebar/Sidebar";
import ArtikelVisning from "./Main/Artikel";
import ArtikelList from "./Main/ArtikelList";
import { parseQueryString } from "util/index";
import { usePromise } from "hooks/usePromise";
import Pointer from "components/common/icons/Pointer";
import PageContainer from "components/common/layout/PageContainer";
//import { Artikel } from "interfaces/Artikler";

export interface IArtiklerState {
  state: "IDLE" | "LOADING" | "ERROR";
  visning: "LISTE" | "ARTIKEL" | "INGEN";
  data: Artikel[];
  temaer: { antal: number; tema: ArtikelTema }[];
  currentData: ArtikelListeMedTema | Artikel | undefined;
}
const pageoptions: ArtikelPageOptions = {
  page: 1,
  pageSize: 99999,
  trimindhold: true,
  offentlig: true,
};

export default function Artikler({ location }) {
  const { search } = location;
  const query = parseQueryString(search);
  const { temaid, artikelid } = query;

  const fetchArticles = useCallback(() => artikelApi.getAll(pageoptions), []);
  const defaultValue = useMemo(() => {
    return [];
  }, []);

  const artikelState = usePromise(
    fetchArticles,
    defaultValue // The default value ...
  ).state;

  const [state, setState] = useState<IArtiklerState>({
    state: "LOADING",
    visning: artikelid ? "ARTIKEL" : temaid ? "LISTE" : "INGEN",
    data: [],
    temaer: [],
    currentData: undefined,
  });

  useEffect(() => {
    if (state.state === "IDLE") {
      if (temaid) {
        if (temaid === "-1") {
          // "Alle artikler"
          setState((s) => {
            return {
              ...s,
              currentData: {
                tema: "Hjælp til fejllister",
                artikler: s.data,
              },
              visning: "LISTE",
            };
          });
        } else {
          setState((s) => {
            return {
              ...s,
              currentData: {
                tema:
                  s.temaer.find((t) => t.tema.id === temaid)?.tema.navn ?? "",
                artikler: s.data.filter((a) => a.temaId === temaid),
              },
              visning: "LISTE",
            };
          });
        }
      }
      if (artikelid) {
        setState((s) => {
          const artikel = s.data.find((a) => a.id === artikelid);
          return { ...s, currentData: artikel, visning: "ARTIKEL" };
        });
      }
    }
  }, [temaid, artikelid, state.state]);

  useEffect(() => {
    const temaer: { antal: number; tema: ArtikelTema }[] = [];
    if (artikelState.state === "IDLE") {
      artikelState.data.forEach((a) => {
        const tema = temaer.find((tema) => tema.tema.id === a.temaId);
        if (tema) {
          tema.antal = tema.antal + 1;
        } else {
          temaer.push({ antal: 1, tema: { id: a.temaId, navn: a.temaNavn } });
        }
      });
    }
    return setState({
      ...state,
      state: artikelState.state,
      data: artikelState.data,
      temaer: temaer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artikelState.state, artikelState.data]);

  const renderEmpty = () => {
    return (
      <PageContainer classNames={styles.placeholder_content}>
        <div className={styles.placeholder}>
          <span className={styles.arrow}>
            <Pointer width={45} />
          </span>
          <p>Vælg et tema i panelet til venstre</p>
        </div>
      </PageContainer>
    );
  };
  const renderMain = () => {
    if (
      (state.state === "LOADING" || state.state === "IDLE") &&
      state.visning === "INGEN"
    ) {
      return renderEmpty();
    }
    return (
      <div className={styles.container} id="artikler-content">
        {state.state === "IDLE" &&
          state.visning === "ARTIKEL" &&
          isArtikel(state.currentData) && (
            <ArtikelVisning artikelId={state.currentData.id} />
          )}
        {state.state === "IDLE" &&
          state.visning === "LISTE" &&
          isArtikelListeMedTema(state.currentData) && (
            <ArtikelList artikelListeMedTema={state.currentData} />
          )}
      </div>
    );
  };
  return (
    <TwoPaneView
      sidebar={
        <Sidebar
          totaltAntalArtikler={state.data?.length}
          currentTemaId={temaid}
          state={state.state}
          temaer={state.temaer}
        />
      }
      main={renderMain()}
    />
  );
}

/**
 * For at type checke Artikel[] | Artikel | undefined mod Artikel
 * @param object
 */
function isArtikel(
  object: ArtikelListeMedTema | Artikel | undefined
): object is Artikel {
  if (!object) return false;
  return "id" in object;
}
function isArtikelListeMedTema(
  object: ArtikelListeMedTema | Artikel | undefined
): object is ArtikelListeMedTema {
  if (!object) return false;
  return "tema" in object;
}
