import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./DefaultNav.module.scss";
import { motion } from "framer-motion";
import NavContainer from "./NavContainer";
import Logo from "./Logo";
import FoldOutMenu from "components/common/FoldOutMenu";
import Hamburger from "components/common/icons/Hamburger";
import UserMenu from "./UserMenu";
import { AuthState } from "context/AuthProvider";

interface NavProps {
  authState: AuthState;
}

export default function DefaultNav(props: NavProps) {
  const { authState } = props;
  console.log(authState.bruger);
  const roller =
    authState.bruger && authState.bruger.roller ? authState.bruger.roller : [];

  const renderNav = () => {
    return (
      <React.Fragment>
        <NavLink
          to="/search"
          className={styles.link}
          activeClassName={styles.selected}
        >
          Find ejendom
        </NavLink>
        {roller.findIndex((role) => role.navn === "fejllister") > -1 && ( // Claim is still called like so
          <NavLink
            to="/fejllister"
            className={styles.link}
            activeClassName={styles.selected}
          >
            Fejllister
          </NavLink>
        )}
        {roller.findIndex((role) => role.navn === "fejllister") > -1 && ( // Claim is still called like so
          <NavLink
            to="/hjaelp-til-fejllister"
            className={styles.link}
            activeClassName={styles.selected}
          >
            Hjælp til fejllister
          </NavLink>
        )}
        {roller.findIndex((role) => role.navn === "statistik") > -1 && (
          <NavLink
            to="/statistik"
            className={styles.link}
            activeClassName={styles.selected}
          >
            Statistik
          </NavLink>
        )}
      </React.Fragment>
    );
  };
  return (
    <NavContainer containerClassName={styles.container}>
      <Logo />
      <div className={styles.linkscontainer}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.links}
        >
          {roller.findIndex(
            (role) => role.navn === "miljoe_bbrexplore_adgang"
          ) > -1 && renderNav()}
          <div>
            <UserMenu authState={authState} />
          </div>
        </motion.div>
        <div className={styles.hamburger}>
          <FoldOutMenu
            element={
              <div>
                <Hamburger height={21} />
              </div>
            }
            menuClassNames={styles.hamburgermenu}
            menu={
              <>
                <NavLink to="/search" activeClassName={styles.selected}>
                  Find ejendom
                </NavLink>
                <NavLink to="/fejllister" activeClassName={styles.selected}>
                  Fejllister
                </NavLink>
                <NavLink
                  to="/hjaelp-til-fejllister"
                  activeClassName={styles.selected}
                >
                  Hjælp til fejllister
                </NavLink>
                <NavLink to="/statistik" activeClassName={styles.selected}>
                  Statistik
                </NavLink>

                {roller.findIndex((role) => role.navn === "administrator") >
                  -1 && (
                  <NavLink to="/admin" activeClassName={styles.selected}>
                    Admin
                  </NavLink>
                )}

                <form
                  action={authState.bruger?.logoutUrl ?? "/auth/logout"}
                  id="signoutForm"
                  method="post"
                >
                  <input
                    type="submit"
                    className={styles.formlink}
                    value="Log ud"
                  />
                </form>
              </>
            }
          />
        </div>
      </div>
    </NavContainer>
  );
}
