import React, { useState } from "react";
import styles from "./ListeAdgange.module.scss";
import { IOrganisationState } from "./OpretListe";
import Chevron from "components/common/icons/Chevron";
import { AnimatePresence, motion } from "framer-motion";
import PlusSimple from "components/common/icons/PlusSimple";
import MinusSimple from "components/common/icons/MinusSimple";
import Close from "components/common/icons/Close";

interface ListeAdgangeProps {
  listeOrganisationerState: IOrganisationState;
  setListeOrganisationerState: Function;
  organisationerState: IOrganisationState;
}

export default function ListeAdgange(props: ListeAdgangeProps) {
  const {
    listeOrganisationerState,
    organisationerState,
    setListeOrganisationerState,
  } = props;

  const [edit, setEdit] = useState(false);

  const newOrgs = organisationerState.data.filter((o) => {
    return (
      listeOrganisationerState.data.findIndex((lo) => lo.id === o.id) === -1
    );
  });
  return (
    <div className={styles.container}>
      <label>Adgang til listen</label>
      {listeOrganisationerState.state === "IDLE" &&
        organisationerState.state === "IDLE" && (
          <>
            {listeOrganisationerState.data.length > 0 && (
              <ul className={styles.list}>
                {listeOrganisationerState.data.map((org) => {
                  return (
                    <li className={styles.list} key={org.id}>
                      <span>{org.navn}</span>{" "}
                      <button
                        onClick={() => {
                          setListeOrganisationerState({
                            ...listeOrganisationerState,
                            data: listeOrganisationerState.data.filter(
                              (lo) => lo.id !== org.id
                            ),
                          });
                        }}
                      >
                        <Close height={15} />
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}
            {listeOrganisationerState.data.length === 0 && (
              <ul className={styles.list}>
                <li>Ingen organisationer har adgang til listen</li>
              </ul>
            )}
            <AnimatePresence exitBeforeEnter key={edit.toString()}>
              <div className={styles.additem}>
                {!edit && (
                  <motion.button
                    type="button"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    <PlusSimple width={15} />
                    <span>Føj til</span>
                  </motion.button>
                )}
                {edit && (
                  <>
                    <motion.button
                      type="button"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      <MinusSimple width={15} />
                      <span>Afbryd</span>
                    </motion.button>
                    <motion.div
                      className={styles.selectbox}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <select
                        defaultValue=""
                        onChange={(e) => {
                          setListeOrganisationerState({
                            ...listeOrganisationerState,
                            data: [
                              ...listeOrganisationerState.data,
                              organisationerState.data.find(
                                (o) => o.id === e.target.value
                              ),
                            ],
                          });
                          setEdit(false);
                        }}
                      >
                        <option value="" disabled />
                        {newOrgs.map((o) => {
                          return (
                            <option key={o.id} value={o.id}>
                              {o.navn}
                            </option>
                          );
                        })}
                      </select>
                      <span className={styles.arrowdown}>
                        <Chevron />
                      </span>
                    </motion.div>
                  </>
                )}
              </div>
            </AnimatePresence>
          </>
        )}
      {listeOrganisationerState.state === "LOADING" && (
        <p>Laster adgange ...</p>
      )}
      {listeOrganisationerState.state === "ERROR" && (
        <p>Henting af adgange fejlede.</p>
      )}
    </div>
  );
}
