import React, { useCallback, useMemo } from "react";
import artikelApi from "api/artikler";
import { sanitize } from "dompurify";
import styles from "./Artikel.module.scss";
import { usePromise } from "hooks/usePromise";

export default function ArtikelVisning(props: IArtikelProps) {
  const { artikelId } = props;

  const fetchArticle = useCallback(
    () => artikelApi.getArtikelFromId(artikelId),
    [artikelId]
  );
  const defaultValue = useMemo(() => {
    return undefined;
  }, []);

  const artikelState = usePromise(
    fetchArticle,
    defaultValue // The default value ...
  ).state;

  if (artikelState.state === "LOADING") {
    return <div className={styles.container}>Henter artikel ...</div>;
  }
  if (artikelState.state === "ERROR") {
    return (
      <div className={styles.container}>
        Noget gik galt ved henting af artikel.
      </div>
    );
  }
  if (artikelState.state === "IDLE" && artikelState.data) {
    const { titel, indhold } = artikelState.data;
    return (
      <div className={styles.container}>
        <h1>{titel}</h1>
        <article
          dangerouslySetInnerHTML={{ __html: sanitize(indhold) }}
        ></article>
      </div>
    );
  }
  return null;
}

interface IArtikelProps {
  artikelId: string;
}
