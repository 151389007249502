import React, { useContext, useEffect, useState } from "react";
import styles from "./Right.module.scss";
import Chevron from "components/common/icons/Chevron";
import Hourglass from "components/common/icons/Hourglass";
import Checkmark from "components/common/icons/Checkmark";
import OpgaveInfo from "./Right/OpgaveInfo";
import Button from "components/common/Buttons/Button";
import { useHistory, Link } from "react-router-dom";
import {
  OpgaveListeType,
  OpgaveBrugerStatus,
  OpgaveKommentarAdgang,
} from "interfaces/Opgaveliste/enums";
import opgaveApi from "api/opgave";
import Arrow from "components/common/icons/Arrow";
import { mergeClassNames } from "util/index";
import { AuthContext } from "context/AuthProvider";
import KommentarFelt from "./Right/KommentarFelt";
import Avatar from "components/common/Avatar";
import FoldOutMenu from "components/common/FoldOutMenu";
import More from "components/common/icons/More";
import Pause from "components/common/icons/Pause";
import StatusLabel from "components/common/StatusLabels/StatusLabel";

export default function Right(props: RightProps) {
  const { opgaveState, listeState, setOpgaveState, listetype, brugerStatusUI } =
    props;

  const history = useHistory();
  const { push } = history;
  const [idx, setIdx] = useState(
    opgaveState.idx !== undefined ? opgaveState.idx : 0
  );
  const [buttonState, setButtonState] = useState<ButtonState>({
    state: "LOADING",
  });
  const authState = useContext(AuthContext);
  const currentOpgave = opgaveState.data[idx]
    ? opgaveState.data[idx]
    : undefined;
  // If the index of the opgaveState changes, push the opgave to the router
  useEffect(() => {
    if (opgaveState.idx !== undefined && idx !== undefined) {
      opgaveState.idx = idx;
      let opgave_id = opgaveState.data?.[idx]?.id;
      if (opgave_id) {
        let params = new URLSearchParams(history.location.search);
        if (params.get("opgaveid") !== opgave_id) {
          params.set("opgaveid", opgave_id);
          push(history.location.pathname + "?" + params);
        }
      }
      setOpgaveState(opgaveState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  useEffect(() => {
    if (opgaveState.idx !== undefined) {
      setIdx(opgaveState.idx);
    }
    if (opgaveState.data) {
      setButtonState({ state: "IDLE" });
    }
  }, [opgaveState.idx, opgaveState.data, currentOpgave]);

  const putOpgaveBrugerStatus = async (
    id: string,
    brugerStatus: OpgaveBrugerStatus
  ) => {
    setButtonState({ state: "LOADING" });
    try {
      let ol = await opgaveApi.putOpgaveBrugerStatus(
        id,
        brugerStatus,
        authState
      );

      if (ol && opgaveState.data) {
        let new_data = opgaveState.data;
        new_data[idx] = ol;

        setOpgaveState({ ...opgaveState, data: new_data });
      }

      return ol;
    } catch (error) {
      if (error === "CANCEL") {
        // The request was canceled, all is well
        console.debug(error);
        setButtonState({ state: "ERROR" });
      } else {
        return undefined;
      }
    }
  };
  const fetchOpgave = async (opgaveId) => {
    try {
      return await opgaveApi.getId(opgaveId, authState);
    } catch (error) {
      if (error === "CANCEL") {
        // The request was canceled, all is well
        console.debug(error);
      } else {
        return undefined;
      }
    }
  };
  let hasKanIkkeLoesesButton =
    brugerStatusUI !== undefined &&
    brugerStatusUI.findIndex((b) => b.status === 5 && b.visesUi) > -1;

  return (
    <div className={styles.container}>
      <div className={styles.opgaver}>
        <div className={styles.opgaveheader}>
          <h3>Fejl på ejendommen</h3>
          {opgaveState.state === "IDLE" && (
            <div className={styles.opgavebuttons}>
              <button
                style={{ paddingRight: 3 }}
                className={styles.opgavebutton}
                onClick={() => {
                  idx > 0 ? setIdx(idx - 1) : setIdx(0);
                }}
              >
                <Chevron style={{ transform: "rotate(180deg)" }} />
              </button>
              <span>
                <b>{opgaveState.data && opgaveState.idx + 1}</b> /{" "}
                {opgaveState.data?.length}
              </span>
              <button
                style={{ paddingLeft: 3 }}
                className={styles.opgavebutton}
                onClick={() => {
                  opgaveState.data !== undefined &&
                  idx < opgaveState.data?.length - 1
                    ? setIdx(idx + 1)
                    : setIdx(idx); //no-op assignment?
                }}
              >
                <Chevron />
              </button>
            </div>
          )}
        </div>
      </div>
      {currentOpgave && (
        <div className={styles.scroll}>
          <div className={mergeClassNames(styles.statuslabels, styles.section)}>
            <h5>Status</h5>
            <div>
              {currentOpgave.brugerStatus === 0 && (
                <StatusLabel type="open">Åben</StatusLabel>
              )}
              {currentOpgave.brugerStatus === 2 && (
                <StatusLabel type="done">Færdig</StatusLabel>
              )}
              {currentOpgave.brugerStatus === 1 && (
                <StatusLabel type="onit">I gang</StatusLabel>
              )}
              {currentOpgave.brugerStatus === 5 && (
                <StatusLabel type="pause">Fejl</StatusLabel>
              )}
              {currentOpgave.tilhoererBruger && (
                <Avatar bruger={currentOpgave.tilhoererBruger} />
              )}
            </div>
          </div>
          <OpgaveInfo listetype={listetype} opgaveState={opgaveState} />
          {opgaveState.state === "IDLE" && opgaveState.data.length > 0 && (
            <div className={mergeClassNames(styles.states, styles.section)}>
              <div className={styles.states__header}>
                <h5>Sæt status</h5>
              </div>
              <ul>
                <li>
                  <Button
                    disabled={buttonState.state !== "IDLE"}
                    classNames={
                      currentOpgave.brugerStatus === OpgaveBrugerStatus.iGang
                        ? styles.active
                        : ""
                    }
                    aria-pressed="false"
                    type="small"
                    onClick={() => {
                      putOpgaveBrugerStatus(
                        currentOpgave.id,
                        currentOpgave.brugerStatus === OpgaveBrugerStatus.iGang
                          ? OpgaveBrugerStatus.ikkeTildelt
                          : OpgaveBrugerStatus.iGang
                      );
                    }}
                  >
                    <Hourglass height={15} />
                    <span>I gang</span>
                  </Button>
                </li>
                <li>
                  <Button
                    disabled={buttonState.state !== "IDLE"}
                    classNames={
                      currentOpgave.brugerStatus ===
                      OpgaveBrugerStatus.opgaveLoest
                        ? styles.active
                        : ""
                    }
                    aria-pressed="false"
                    type="small"
                    onClick={() => {
                      putOpgaveBrugerStatus(
                        currentOpgave.id,
                        currentOpgave.brugerStatus ===
                          OpgaveBrugerStatus.opgaveLoest
                          ? OpgaveBrugerStatus.ikkeTildelt
                          : OpgaveBrugerStatus.opgaveLoest
                      );
                    }}
                  >
                    <Checkmark />
                    <span>Færdig</span>
                  </Button>
                </li>
                {hasKanIkkeLoesesButton && (
                  <li className={styles.moreli}>
                    <FoldOutMenu
                      menuClassNames={styles.states__foldoutMenu}
                      direction={"up"}
                      element={
                        <div
                          aria-label="Flere brugerstatuser"
                          aria-haspopup="true"
                          className={
                            currentOpgave.brugerStatus ===
                            OpgaveBrugerStatus.kanIkkeLoeses
                              ? mergeClassNames(styles.more, styles.active)
                              : styles.more
                          }
                        >
                          <More /> <span className="sr-only">More</span>
                        </div>
                      }
                      menu={
                        <>
                          <Button
                            classNames={
                              currentOpgave.brugerStatus ===
                              OpgaveBrugerStatus.kanIkkeLoeses
                                ? styles.active
                                : ""
                            }
                            onClick={() => {
                              putOpgaveBrugerStatus(
                                currentOpgave.id,
                                currentOpgave.brugerStatus ===
                                  OpgaveBrugerStatus.kanIkkeLoeses
                                  ? OpgaveBrugerStatus.ikkeTildelt
                                  : OpgaveBrugerStatus.kanIkkeLoeses
                              );
                            }}
                            aria-pressed="false"
                            type="small"
                          >
                            <Pause />
                            {currentOpgave.brugerStatus ===
                              OpgaveBrugerStatus.kanIkkeLoeses &&
                              "Kan ikke løses (afventer)"}
                            {currentOpgave.brugerStatus !==
                              OpgaveBrugerStatus.kanIkkeLoeses &&
                              "Kan ikke løses (afventer)"}
                          </Button>
                        </>
                      }
                    />
                  </li>
                )}
              </ul>
            </div>
          )}
          {currentOpgave.kommentarAdgang !==
            OpgaveKommentarAdgang.ingenAdgang && (
            <KommentarFelt
              opgaveState={opgaveState}
              setOpgaveState={setOpgaveState}
            />
          )}
          <div className={mergeClassNames(styles.fejlliste, styles.section)}>
            <h5>Fra listen </h5>
            <Link
              className="internal"
              to={`/fejllister?id=${listeState.data?.id}`}
            >
              {listeState.data?.navn} <Arrow />
            </Link>
            {listeState.state === "IDLE" && (
              <div className={styles.nextprevious}>
                <Button
                  type="small-light"
                  onClick={() => {
                    if (
                      listeState.opgaveOrder &&
                      listeState.opgaveOrder.previousOpgaveId
                    ) {
                      // TODO: Sanity check this logic
                      // From the opgaveId, we have to resolve the opgave, and find the BFE number it refers
                      // then with the opgave, we can build the history.push() call
                      fetchOpgave(listeState.opgaveOrder.previousOpgaveId).then(
                        (opgave) => {
                          if (opgave) {
                            let params = new URLSearchParams(
                              history.location.search
                            );
                            params.set("bfe", opgave.bfe.toString());
                            params.set("opgaveid", opgave.id.toString());
                            params.set("listeid", opgave.listeId.toString());
                            push(history.location.pathname + "?" + params);
                            if (
                              opgaveState.data.filter(
                                (e) =>
                                  listeState.opgaveOrder?.previousOpgaveId ===
                                  e.id
                              ).length > 0
                            ) {
                              setIdx(idx - 1);
                              setOpgaveState({ ...opgaveState, idx: idx - 1 });
                            }
                          }
                        }
                      );
                    }
                  }}
                  disabled={listeState.opgaveOrder?.previousOpgaveId === null}
                  classNames={mergeClassNames(
                    styles.left,
                    listeState.opgaveOrder?.previousOpgaveId === null
                      ? styles.disabled
                      : styles.enabled
                  )}
                >
                  <Chevron height={12} />
                  Forrige på listen
                </Button>
                <Button
                  type="small-light"
                  onClick={() => {
                    if (
                      listeState.opgaveOrder &&
                      listeState.opgaveOrder.nextOpgaveId
                    ) {
                      // TODO: Sanity check this logic
                      // From the opgaveId, we have to resolve the opgave, and find the BFE number it refers
                      // then with the opgave, we can build the history.push() call
                      fetchOpgave(listeState.opgaveOrder.nextOpgaveId).then(
                        (opgave) => {
                          if (opgave) {
                            let params = new URLSearchParams(
                              history.location.search
                            );

                            params.set("bfe", opgave.bfe.toString());
                            params.set("opgaveid", opgave.id.toString());
                            params.set("listeid", opgave.listeId.toString());
                            push(history.location.pathname + "?" + params);
                            if (
                              opgaveState.data.filter(
                                (e) =>
                                  listeState.opgaveOrder?.nextOpgaveId === e.id
                              ).length > 0
                            ) {
                              setIdx(idx + 1);
                              setOpgaveState({ ...opgaveState, idx: idx + 1 });
                            }
                          }
                        }
                      );
                    }
                  }}
                  disabled={listeState.opgaveOrder?.nextOpgaveId === null}
                  classNames={mergeClassNames(
                    styles.right,
                    listeState.opgaveOrder?.nextOpgaveId === null
                      ? styles.disabled
                      : styles.enabled
                  )}
                >
                  Næste på listen
                  <Chevron height={12} />
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

interface RightProps {
  opgaveState: {
    state: "LOADING" | "IDLE" | "ERROR";
    data: Opgave[];
    error: any;
    idx: number;
  };
  listeState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data?: OpgaveListe;
    opgaveOrder?: OpgaveOrder;
  };
  setOpgaveState: Function;
  listetype?: OpgaveListeType;
  brugerStatusUI?: OpgaveBrugerStatusUI[];
}

export interface ButtonState {
  state: "IDLE" | "LOADING" | "ERROR";
}
