import React, { useState } from "react";
import styles from "./Enheder.module.scss";
import { getRelevantSupplerendeOplysninger } from "util/index";
import { OpgaveListeType } from "interfaces/Opgaveliste/enums";
import SupplerendeOplysninger from "routes/Ejendom/SupplerendeOplysninger/SupplerendeOplysninger";
import Enhed from "./Enhed";
import Etage from "./Etage";

/* Enheder kommer tilsyneladende ikke med ud i BFE kaldet? */
export default function Enheder(props: IEnheder) {
  // element is the "opgang" object
  const {
    opgangList,
    referencer,
    templater,
    listeType,
    setMarkeret,
    supplerendeOplysninger,
  } = props;

  const [enhedModalState, setEnhedModalState] = useState("");
  const energiOplysninger = supplerendeOplysninger?.energiOplysninger;

  return (
    <section className={styles.container}>
      <div className={styles.opgangContainer}>
        {opgangList.length === 0 && (
          <ul className={styles.enheder}>
            <li>
              <p>Ingen enheder på bygningen.</p>
            </li>
          </ul>
        )}
        {opgangList.map((opgang, opgang_idx) => {
          const relevantSupplerendeOplysninger = supplerendeOplysninger
            ? getRelevantSupplerendeOplysninger(
                supplerendeOplysninger,
                opgang["id"]
              )
            : undefined;
          return (
            <div className={styles.item} key={opgang["id"]}>
              <h4>
                Opgang: <b>{opgang["adgangFraHusnummer"]["betegnelse"]}</b>
              </h4>
              <ul className={styles.enheder}>
                {(() => {
                  let currentEtage = undefined;
                  let currentEtageEnheder: JSX.Element[] = [];
                  let etager: JSX.Element[] = [];
                  opgang["enhedList"].forEach((enhed, idx) => {
                    if (currentEtage === undefined) {
                      currentEtage = enhed.etagebetegelse;
                    }
                    if (currentEtage !== enhed.etagebetegelse) {
                      etager.push(
                        <Etage key={currentEtage} isLast={false}>
                          {currentEtageEnheder}
                        </Etage>
                      );
                      currentEtage = enhed.etagebetegelse;
                      currentEtageEnheder = [];
                    }

                    currentEtageEnheder.push(
                      <Enhed
                        key={enhed["enhed"]["id_lokalId"]}
                        enhed={enhed}
                        modalExpand={
                          // If the enhed is in the last opgang and it's one of the last 4 enheder.
                          opgangList.length - 1 === opgang_idx &&
                          opgang["enhedList"].length - 4 <= idx
                            ? true
                            : false
                        }
                        referencer={referencer}
                        setMarkering={setMarkeret}
                        listeType={listeType}
                        templater={templater}
                        enhedModalState={enhedModalState}
                        setEnhedModalState={setEnhedModalState}
                        energiOplysninger={energiOplysninger}
                      />
                    );
                  });

                  etager.push(
                    <Etage key={currentEtage} isLast={false}>
                      {currentEtageEnheder}
                    </Etage>
                  );
                  return etager;
                })()}
              </ul>
              {relevantSupplerendeOplysninger && (
                <SupplerendeOplysninger
                  supplerendeOplysninger={relevantSupplerendeOplysninger}
                />
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}

// Interface
interface IEnheder {
  opgangList: Array<Object>;
  setMarkeret: Function;
  templater: any;
  listeType?: OpgaveListeType;
  referencer?: Array<VisningsdataElementReference>;
  supplerendeOplysninger?: SupplerendeOplysninger;
}
