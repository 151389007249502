import React from "react";
import styles from "./SmileyVirksomheder.module.scss";
import ExternalArrow from "components/common/icons/ExternalArrow";

export default function SmileyVirksomheder(props: ISmileyVirksomhederProps) {
  const { smileyVirksomheder } = props;
  const { oplysninger } = smileyVirksomheder;
  return (
    <div className={styles.virksomhedercontainer}>
      <h4>{oplysninger.length} smiley virksomheder</h4>
      <ul>
        {oplysninger.map((o, idx) => {
          const { cvrnr, navn1, adresse1, postnr, by, url } = o;
          return (
            <li
              className={styles.virksomhed}
              key={cvrnr + "_" + adresse1 + "_" + idx}
            >
              <p>{navn1}</p>
              <p>
                {adresse1}, {postnr} {by}
              </p>
              <p>
                CVR:{" "}
                <a
                  className="external"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={url}
                >
                  <span>{cvrnr}</span>
                  <ExternalArrow />
                </a>
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface ISmileyVirksomhederProps {
  smileyVirksomheder: SmileyVirksomheder;
}
