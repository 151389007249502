import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from "react";
import styles from "./Ejendom.module.scss";
import Header from "./Panes/Header";
import Left from "./Panes/Left";
import FullScreenMap from "./FullScreenMap";
import Main from "./Panes/Main";
import Right from "./Panes/Right";
import { useQuery } from "hooks/useQuery";
import ejendomApi from "api/ejendom";
import chrApi from "api/chr";
import opgaveApi from "api/opgave";
import opgaveListeApi from "api/opgavelister";
import { mergeClassNames } from "util/index";
import BBRAdvarsel from "components/common/icons/BBRAdvarsel";
import Close from "components/common/icons/Close";
import InfoIcon from "components/common/icons/Info";
import Bygning from "components/common/icons/Bygning";
import ExternalArrow from "components/common/icons/ExternalArrow";
import { OpgaveSortOrder } from "interfaces/Opgaveliste/enums";
import { useMedia } from "hooks/useMedia";
import { usePromise } from "hooks/usePromise";
import { AuthContext } from "../../context/AuthProvider";
import { useHistory } from "react-router";
import { RealTimeContext } from "context/RealTimeProvider";
import { usePrevious } from "hooks/usePrevious";

// Relates to "enhedModal"
// Allows the modals to see if the right pane is open or not without passing props
// If enhedModal is not used, the context is obsolete, but it's fine.
export const RightPaneOpenContext = createContext<boolean>(false);

export default function Ejendom() {
  const { opgaveMessages } = useContext(RealTimeContext);
  const prevOpgaveMessages = usePrevious(opgaveMessages);
  const [fullScreenMapToggled, setFullScreenMapToggled] = useState(false);
  const [currentAccordionOpen, setCurrentAccordionOpen] = useState<
    undefined | "om-ejendommen" | "eksterne-links"
  >("om-ejendommen");
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const query = useQuery();
  const queryBfe = query.get("bfe");
  const queryOpgaveid = query.get("opgaveid");
  const querySortOrder = query.get("sortorder");
  const authState = useContext(AuthContext);
  const { bruger } = authState;
  const roller = bruger ? bruger.roller : [];

  // Vis ejendomsvisning (undefined) når man skifter til mobilvisning
  const defaultAccordion = useMedia(
    ["(min-width: 901px)", "(max-width: 900px)"],
    ["om-ejendommen", undefined],
    undefined
  );
  useEffect(() => {
    setCurrentAccordionOpen(defaultAccordion);
  }, [defaultAccordion]);

  const history = useHistory();

  /* DATA FETCHING */
  // Get the ejendom
  const fetchEjendom = useCallback(
    () => ejendomApi.getBfe(Number(queryBfe), true),
    [queryBfe]
  );

  // Get Chr oplysninger
  const fetchChr = useCallback(
    () => chrApi.getChr(Number(queryBfe)),
    [queryBfe]
  );
  const fetchOpgave = useCallback(
    () => opgaveApi.getBfe(Number(queryBfe), authState),
    [queryBfe, authState]
  );

  // Get kommune oplysninger
  //const fetchKommune = useCallback(() => kommuneApi.getKommune(Number(kommunenr)))
  /* Set the states */
  const ejendomState = usePromise(
    fetchEjendom,
    queryBfe // The default value ...
  ).state;

  const opgaveDefault = useMemo(() => {
    return { undefined };
  }, []);
  const { state: opgaveState, setState: setOpgaveState } = usePromise(
    fetchOpgave,
    opgaveDefault,
    { idx: 0 }
  ) as {
    state: OpgaveState;
    setState: (state: OpgaveState) => void;
  };

  useEffect(() => {
    if (
      opgaveState.data.length &&
      opgaveMessages.length &&
      (!prevOpgaveMessages || prevOpgaveMessages.length < opgaveMessages.length)
    ) {
      const newOpgaveMessage = opgaveMessages[opgaveMessages.length - 1];
      if (opgaveState.data.find((o) => o.id === newOpgaveMessage.id)) {
        setOpgaveState({
          ...opgaveState,
          data: opgaveState.data.map((o) => {
            if (o.id === newOpgaveMessage.id) {
              return { ...o, ...newOpgaveMessage };
            }
            return o;
          }),
        });
      }
    }
  }, [opgaveMessages, prevOpgaveMessages, opgaveState, setOpgaveState]);

  const setOpgaveStateHandler = (opgaveState: OpgaveState) => {
    setOpgaveState({
      ...opgaveState,
    });
  };

  // TODO: Rewrite to use "UsePromise" - this still works though.
  const [listeState, setListeState] = useState<{
    state: "IDLE" | "LOADING" | "ERROR";
    data?: OpgaveListe;
    opgaveOrder?: OpgaveOrder;
  }>({ state: "LOADING" });

  // If ejendom is done loading, and we don't already have chrOplysninger, promise chain them onto the ejendomState
  if (
    ejendomState.state === "IDLE" &&
    !ejendomState.data.supplerendeOplysninger.chrOplysninger
  ) {
    fetchChr().then((chr) => {
      ejendomState.data.supplerendeOplysninger.chrOplysninger = chr;
    });
  }

  useEffect(() => {
    let stillMounted = true;
    const fetchListeData = async (listeId, opgaveId) => {
      try {
        let sortOrder = OpgaveSortOrder.opgaveIdAsc;

        if (querySortOrder && querySortOrder in OpgaveSortOrder) {
          sortOrder = parseInt(querySortOrder);
        }
        const liste = await opgaveListeApi.getListeFromId(listeId);
        const opgaveOrder = await opgaveApi.getOpgaveOrder(
          opgaveId,
          sortOrder,
          authState
        );
        if (stillMounted && liste) {
          setListeState({
            state: "IDLE",
            data: liste,
            opgaveOrder: opgaveOrder,
          });
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setListeState({ state: "ERROR" });
          }
        }
      }
    };
    if (opgaveState.state === "IDLE") {
      let idx = 0;
      if (queryOpgaveid) {
        if (opgaveState.data.length > 0) {
          idx = opgaveState.data.findIndex(
            (opgave: Opgave) => opgave.id === queryOpgaveid
          );
          if (idx === -1 && opgaveState.data[0]) {
            let listeId = opgaveState.data[0].listeId;
            let opgaveId = opgaveState.data[0].id;
            setOpgaveState({ ...opgaveState, idx: 0 });
            fetchListeData(listeId, opgaveId);
          } else {
            let listeId = opgaveState.data[idx].listeId;
            setOpgaveState({ ...opgaveState, idx: idx });
            fetchListeData(listeId, queryOpgaveid);
          }
        }
      } else {
        // if there is no query opgaveId, take the first returned.
        // ref issue #266, 248
        if (opgaveState.data.length > 0) {
          let listeId = opgaveState.data[0].listeId;
          let opgaveId = opgaveState.data[0].id;
          fetchListeData(listeId, opgaveId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opgaveState.idx, opgaveState.data, history, queryOpgaveid]);

  let rightClasses = mergeClassNames(
    `${styles.sidebar} ${styles.right}`,
    styles.rightpane
  );
  if (rightSidebarOpen)
    rightClasses = mergeClassNames(rightClasses, styles.open);
  if (
    opgaveState.data?.length === 0 ||
    (opgaveState.state === "LOADING" && !queryOpgaveid)
  )
    rightClasses = mergeClassNames(rightClasses, styles.empty);

  return (
    <RightPaneOpenContext.Provider
      value={
        !(
          opgaveState.data?.length === 0 ||
          (opgaveState.state === "LOADING" && !queryOpgaveid)
        )
      }
    >
      <div className={styles.container}>
        <nav className={styles.sidemenu}>
          <ul>
            <li>
              <button
                onClick={() => setCurrentAccordionOpen(undefined)}
                aria-label="Bygningsoplysninger"
                className={
                  !currentAccordionOpen
                    ? mergeClassNames(styles.showmain, styles.active)
                    : styles.showmain
                }
              >
                {/* add "global" classes openbygninger on main AND right sidebar, active-class on this */}
                <span>
                  <Bygning width={26} />
                </span>
              </button>
            </li>
            <li>
              <button
                onClick={() => setCurrentAccordionOpen("om-ejendommen")}
                aria-label="Om ejendommen"
                className={
                  currentAccordionOpen === "om-ejendommen"
                    ? mergeClassNames(styles.showleft, styles.active)
                    : styles.showleft
                }
              >
                <span>
                  <InfoIcon height={26} />
                </span>
              </button>
            </li>
            <li>
              <button
                onClick={() => setCurrentAccordionOpen("eksterne-links")}
                aria-label="Eksterne links"
                className={
                  currentAccordionOpen === "eksterne-links"
                    ? mergeClassNames(styles.showleft, styles.active)
                    : styles.showleft
                }
              >
                <span>
                  <ExternalArrow width={18} />
                </span>
              </button>
            </li>
          </ul>
        </nav>
        <div
          className={
            currentAccordionOpen === "eksterne-links"
              ? `${styles.sidebar} ${styles.left} ${styles.accordionopen}`
              : currentAccordionOpen === "om-ejendommen"
              ? `${styles.sidebar} ${styles.left} ${styles.accordionopen}`
              : `${styles.sidebar} ${styles.left}`
          }
        >
          <div className={styles.sidebarcontent}>
            <Header
              bfe={Number(queryBfe)}
              ejendomState={{ ...ejendomState, data: ejendomState.data }}
              listeId={listeState.data?.id}
            />
            <Left
              showFullScreenMap={setFullScreenMapToggled}
              ejendom={ejendomState}
              currentAccordionOpen={currentAccordionOpen}
              setCurrentAccordionOpen={setCurrentAccordionOpen}
            />
          </div>
        </div>
        <div
          className={
            opgaveState.data?.length === 0
              ? mergeClassNames(styles.main, styles.empty)
              : styles.main
          }
        >
          <Main
            ejendomState={{ ...ejendomState, data: ejendomState.data }}
            opgaveState={opgaveState}
            listeState={{
              ...listeState,
              data: listeState.data,
            }}
          />
        </div>
        {roller.findIndex((role) => role.navn === "fejllister") > -1 && (
          <div className={rightClasses}>
            {/* add opensidebar on right sidebar on click */}
            <button
              title="Fejl på ejendommen"
              onClick={() => setRightSidebarOpen(true)}
              className={styles.showright}
            >
              <BBRAdvarsel height={27} />
            </button>
            <div className={styles.sidebarcontent}>
              <Right
                opgaveState={opgaveState}
                listeState={listeState}
                setOpgaveState={setOpgaveStateHandler}
                listetype={listeState.data?.listetype}
                brugerStatusUI={listeState.data?.brugerstatus}
              />
            </div>
          </div>
        )}
        <div
          onClick={() => setRightSidebarOpen(false)}
          className={styles.overlay}
        >
          {/* remove opensidebar when click on button / overlay */}
          <button className={styles.hidesidebar}>
            <Close width={20} />
          </button>
        </div>
      </div>

      <FullScreenMap
        isToggled={fullScreenMapToggled}
        setIsToggled={setFullScreenMapToggled}
        ejendom={ejendomState}
      />
    </RightPaneOpenContext.Provider>
  );
}

export interface EjendomState {
  state: "IDLE" | "LOADING" | "ERROR";
  data?: Ejendom;
}

export interface OpgaveState {
  state: "LOADING" | "IDLE" | "ERROR";
  data: Opgave[];
  error: any;
  idx: number;
}

export function dataIsEjenom(
  data: Ejendom | string | undefined
): data is Ejendom {
  if (!data) return false;
  if (typeof data === "string") return false;
  return "bfeNr" in data;
}
