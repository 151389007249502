import React from "react";
import styles from "./StatusLabel.module.scss";
import { mergeClassNames } from "util/index";
import Checkmark from "components/common/icons/Checkmark";
import Hourglass from "components/common/icons/Hourglass";
import Pause from "components/common/icons/Pause";
import Open from "components/common/icons/Open";

export default function StatusLabel(props: IStatusLabelProps) {
  const {
    children = <span></span>,
    style = {},
    type = "default",
    classNames = "",
  } = props;
  let className = "";
  switch (type) {
    case "open":
      className = styles.open;
      break;
    case "onit":
      className = styles.onit;
      break;
    case "done":
      className = styles.done;
      break;
    case "pause":
      className = styles.pause;
      break;
    default:
      break;
  }
  className = mergeClassNames(className, classNames);
  return (
    <div className={mergeClassNames(styles.status, className)} style={style}>
      <i>
        {type === "open" && <Open />}
        {type === "done" && <Checkmark width={16} />}
        {type === "onit" && <Hourglass />}
        {type === "pause" && <Pause height={15} />}
      </i>
      <span>{children}</span>
    </div>
  );
}

interface IStatusLabelProps {
  children?: any;
  style?: React.CSSProperties;
  type?: "default" | "open" | "onit" | "done" | "pause";
  classNames?: string;
}
