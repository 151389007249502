import React, { useCallback, useEffect, useState, createContext } from "react";
import Toast, { IToast } from "components/common/Toast";

export const ToastContext = createContext<any>(undefined);

const ToastProvider = ({ children }) => {
  const [toastState, setToastState] = useState<IToast>({
    show: false,
    status: "OK",
    text: "",
  });
  useEffect(() => {
    if (toastState.show) {
      const timer = setTimeout(
        () => setToastState({ ...toastState, show: false }),
        toastState.duration ? toastState.duration : 5000
      );
      return () => clearTimeout(timer);
    }
  }, [toastState]);

  const toast = useCallback(
    function (t: IToast) {
      setToastState(t);
    },
    [setToastState]
  );
  return (
    <ToastContext.Provider value={toast}>
      {children}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => setToastState({ ...toastState, show: false })}
      >
        <Toast options={toastState} />
      </div>
    </ToastContext.Provider>
  );
};
export default ToastProvider;
