import React from "react";

export default function More(props: Icon) {
  const {
    height,
    width,
    // primaryColor, style = {}
  } = props;
  return (
    <svg
      version="1.1"
      width={width ? width : height ? height * (26 / 12) : 26}
      height={height ? height : width ? width * (12 / 12) : 26}
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M6.9,4.1C6.1,3.5,5.9,2.4,6.6,1.6c0,0,0-0.1,0.1-0.1c0.7-0.7,1.9-0.7,2.6,0c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.8,0,1.7-0.8,2.2C8.2,4.4,7.4,4.5,6.9,4.1z" />
      <path d="M6.9,9.5C6.1,8.9,5.9,7.7,6.6,6.9c0,0,0-0.1,0.1-0.1c0.7-0.7,1.9-0.7,2.6,0C9.3,7,9.4,7.1,9.5,7.2c0.3,0.8,0,1.7-0.8,2.2C8.2,9.7,7.4,9.8,6.9,9.5z" />
      <path d="M6.9,14.8c-0.8-0.6-0.9-1.8-0.3-2.6c0,0,0-0.1,0.1-0.1c0.7-0.7,1.9-0.7,2.6,0c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.8,0,1.7-0.8,2.2C8.2,15.1,7.4,15.1,6.9,14.8z" />
    </svg>
  );
}
