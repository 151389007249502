import React from "react";
import ChrOplysninger from "./ChrOplysninger";
import CvrVirksomheder from "./CvrVirksomheder";
import CvrBranchekoder from "./CvrBranchekoder";
import ElOplysninger from "./ElOplysninger";
//import EnergiOplysninger from "./EnergiOplysninger";
import SmileyBranchekoder from "./SmileyBranchekoder";
import SmileyVirksomheder from "./SmileyVirksomheder";
import styles from "./SupplerendeOplysninger.module.scss";

export default function SupplerendeOplysninger(props: ISupplerendeOplysninger) {
  const { supplerendeOplysninger } = props;
  const {
    chrOplysninger,
    cvrBranchekoder,
    cvrVirksomheder,
    elOplysninger,
    //energiOplysninger,
    smileyBranchekoder,
    smileyVirksomheder,
  } = supplerendeOplysninger;

  if (
    !chrOplysninger?.oplysninger.length &&
    !cvrBranchekoder?.oplysninger.length &&
    !cvrVirksomheder?.oplysninger.length &&
    !elOplysninger?.oplysninger.length &&
    //!energiOplysninger?.oplysninger.length &&
    !smileyBranchekoder?.oplysninger.length &&
    !smileyVirksomheder?.oplysninger.length
  )
    return null;
  const renderOplysninger = () => {
    // TODO: energioplysning -
    // indsæt:
    /*
        {energiOplysninger && energiOplysninger.oplysninger.length > 0 && (
          <EnergiOplysninger energioplysninger={energiOplysninger} />
        )}
    */
    return (
      <>
        {chrOplysninger && chrOplysninger.oplysninger.length > 0 && (
          <ChrOplysninger chrOplysninger={chrOplysninger} />
        )}
        {cvrBranchekoder && cvrBranchekoder.oplysninger.length > 0 && (
          <CvrBranchekoder branchekoder={cvrBranchekoder} />
        )}
        {cvrVirksomheder && cvrVirksomheder.oplysninger.length > 0 && (
          <CvrVirksomheder virksomheder={cvrVirksomheder} />
        )}
        {elOplysninger && elOplysninger.oplysninger.length > 0 && (
          <ElOplysninger eloplysninger={elOplysninger} />
        )}
        {/*energiOplysninger && energiOplysninger.oplysninger.length > 0 && (
          <EnergiOplysninger energioplysninger={energiOplysninger} />
        )*/}
        {smileyBranchekoder && smileyBranchekoder.oplysninger.length > 0 && (
          <SmileyBranchekoder smileyBranchekoder={smileyBranchekoder} />
        )}
        {smileyVirksomheder && smileyVirksomheder.oplysninger.length > 0 && (
          <SmileyVirksomheder smileyVirksomheder={smileyVirksomheder} />
        )}
      </>
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>Supplerende oplysninger</h4>
        <i>Disse oplysninger stammer ikke fra BBR</i>
      </div>
      <div className={styles.content}>{renderOplysninger()}</div>
    </div>
  );
}

// Interface
interface ISupplerendeOplysninger {
  supplerendeOplysninger: SupplerendeOplysninger;
}
