import { AuthContext } from "context/AuthProvider";
import React, { useContext } from "react";
import styles from "./Forbidden.module.scss";

export default function Forbidden() {
  const authState = useContext(AuthContext);
  const { bruger } = authState;
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <div className={styles.grid}>
          <div className={styles.col1}>
            <h1>Ingen adgang</h1>
            <p>
              Du har ikke adgang til dette indhold - kontakt din
              systemadministrator
            </p>
            <form
              action={bruger?.logoutUrl ?? "/auth/logout"}
              id="signoutForm"
              method="post"
            >
              <input type="submit" className={styles.formlink} value="Log ud" />
            </form>
          </div>

          <div className={styles.col2}>
            <img
              src="https://bbr.dk/file/654761/test2.jpeg"
              alt="Billede af hus"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
