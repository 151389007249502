import React from "react";

export default function Open(props: Icon) {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : height ? height * (12 / 12) : 12}
      height={height ? height : width ? width * (12 / 12) : 12}
      viewBox="0 0 12 12"
    >
      <g transform="translate(-1267 -358)">
        <g
          transform="translate(1267 358)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <circle cx="6" cy="6" r="6" stroke="none" />
          <circle cx="6" cy="6" r="5.25" fill="none" />
        </g>
      </g>
    </svg>
  );
}
