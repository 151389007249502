import React, { useState } from "react";
import styles from "./TwoPaneView.module.scss";
import Close from "components/common/icons/Close";
import { mergeClassNames } from "util/index";
import Chevron from "../icons/Chevron";

export default function TwoPaneView(props: ITwoPaneViewProps) {
  const { sidebar, main } = props;
  const [isOpen, setIsOpen] = useState(false);
  const sidebarClassNames = isOpen
    ? mergeClassNames(styles.sidebar, styles.opensidebar)
    : styles.sidebar;
  return (
    <div className={styles.container}>
      <div id="sidebar-left" className={sidebarClassNames}>
        <button onClick={() => setIsOpen(true)} className={styles.showsidebar}>
          {/* add opensidebar-class on click */}
          <span>
            <Chevron width={14} />
          </span>
        </button>
        <div className={styles.sidebarcontent}>{sidebar}</div>
      </div>
      <div className={styles.main}>{main}</div>
      <div onClick={() => setIsOpen(false)} className={styles.overlay}>
        {/* remove opensidebar-class on click */}
        <button onClick={() => setIsOpen(false)} className={styles.hidesidebar}>
          <Close width={20} />
        </button>
      </div>
    </div>
  );
}
interface ITwoPaneViewProps {
  sidebar: React.ReactNode;
  main: React.ReactNode;
}
