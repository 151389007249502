import React, { useState, useEffect } from "react";
import styles from "./ExpandablePaper.module.scss";
import { motion } from "framer-motion";
import Minus from "./icons/Minus";
import Plus from "./icons/Plus";
import { mergeClassNames } from "util/index";

export default function ExpandablePaper(props: IExpandablePaperProps) {
  const {
    state,
    defaultMenuOpen = false,
    nestingLevel = 0,
    opgaveIcon,
    headerIcon,
    title,
    subtitle,
    children,
    disabled = false,
  } = props;

  const [menuOpen, setMenuOpen] = useState(defaultMenuOpen);
  const { open, setOpen } = {
    open: state ? state.menuOpen : menuOpen,
    setOpen: state ? state.setMenuOpen : setMenuOpen,
  };
  const [hovered, setHovered] = useState(false);

  const classNames = mergeClassNames(
    styles.container,
    nestingLevel
      ? nestingLevel === 1
        ? `${styles.nested} ${styles.nestinglevelone}`
        : `${styles.nested} ${styles.nestingleveltwo}`
      : undefined
  );
  useEffect(() => {
    setMenuOpen(defaultMenuOpen);
  }, [defaultMenuOpen]);

  const renderHeaderContents = () => {
    return (
      <>
        {headerIcon}
        <div className={styles.titleblock}>
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
        <div className={styles.opgaveIcon}>{opgaveIcon}</div>
      </>
    );
  };

  return (
    <div
      className={mergeClassNames(
        classNames,
        !disabled ? styles.hover : undefined
      )}
    >
      {disabled && (
        <div className={styles.header}>{renderHeaderContents()}</div>
      )}
      {!disabled && (
        <button
          aria-expanded={open}
          className={mergeClassNames(
            styles.header,
            open ? styles.expanded : ""
          )}
          onClick={() => {
            setOpen(!open);
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {open && (
            <span className={styles.expand} key={open.toString()}>
              <Minus hovered={hovered} />
            </span>
          )}
          {!open && (
            <span className={styles.expand} key={open.toString()}>
              <Plus hovered={hovered} />
            </span>
          )}
          {renderHeaderContents()}
        </button>
      )}

      <motion.div
        className={styles.content}
        style={{ overflow: "hidden" }}
        initial={{ height: 0 }}
        animate={{ height: open ? "auto" : 0 }}
        aria-hidden={!open}
      >
        {children}
      </motion.div>
    </div>
  );
}

interface IExpandablePaperProps {
  state?: { menuOpen: boolean; setMenuOpen: Function };
  defaultMenuOpen?: boolean;
  nestingLevel?: 0 | 1 | 2;
  opgaveIcon?: React.ReactNode;
  headerIcon: React.ReactNode;
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  disabled?: boolean;
}
