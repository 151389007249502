import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { breakWords } from "util/index";

interface IHorizontalStackedBarChartProps {
  vis?: "absolut" | "procentvis";
  data: StackedBarChartData;
}

interface StackedBarChartData {
  labels: Array<string>;
  datasets: Array<StackedBarChartDataPoint>;
}
interface StackedBarChartDataPoint {
  label: string;
  data: Array<number>;
  backgroundColor: string;
  borderColor?: string;
  borderWidth?: number;
}

export default function HorizontalStackedBarChart(
  props: IHorizontalStackedBarChartProps
) {
  const { data, vis = "absolut" } = props;
  const round = function (value) {
    return Math.round(value * 100 * 10) / 10;
  };
  const getProcentVisData = (
    data: StackedBarChartData
  ): StackedBarChartData => {
    const procentvisDatasets = data.datasets.map((dataset) => {
      return {
        ...dataset,
        data: dataset.data.map((d, index) => {
          const total = data.datasets.reduce((acc, curr) => {
            return acc + curr.data[index];
          }, 0);
          return round(d / total);
        }),
      };
    });

    return { ...data, datasets: procentvisDatasets };
  };
  const pData = getProcentVisData(data);
  const totals: number[] = [];
  data.datasets[0].data.forEach((d, i) => {
    totals[i] = data.datasets.reduce((acc, curr) => {
      return acc + curr.data[i];
    }, 0);
  });

  const options = {
    legend: {
      position: "bottom",
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          stacked: true,
          barThickness: 20,
          ticks: {
            fontSize: 13,
            fontColor: "#003c16",
            fontFamily: "Open Sans",
            callback: function (value, index, values) {
              const words = breakWords(value);

              const total = totals[index];
              words[words.length - 1] = `${words[words.length - 1]} - ${total}`;
              return words;
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            max: undefined,
          },
        },
      ],
    },
  };
  const procentvisOptions = {
    ...options,
    scales: {
      ...options.scales,
      xAxes: [
        {
          stacked: true,
          ticks: {
            max: 100,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          var datasetIndex = tooltipItem.datasetIndex;
          var index = tooltipItem.index;
          var datasetLabel = data.datasets[datasetIndex].label || "";
          var originalValue = data.datasets[datasetIndex].data[index];
          var rateValue = pData.datasets[datasetIndex].data[index];
          return (
            "" + datasetLabel + ": " + rateValue + "% (" + originalValue + ")"
          );
        },
      },
    },
  };
  return (
    <div style={{ height: totals.length * 40 + 70 }}>
      <HorizontalBar
        data={vis === "absolut" ? data : pData}
        options={vis === "absolut" ? options : procentvisOptions}
      />
    </div>
  );
}
