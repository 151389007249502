import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./UserMenu.module.scss";
import ArrowTriangle from "components/common/icons/ArrowTriangle";
import FoldOutMenu from "components/common/FoldOutMenu";
import { AuthState } from "context/AuthProvider";
import Avatar from "components/common/Avatar";

interface UserMenuProps {
  authState: AuthState;
}

export default function UserMenu(props: UserMenuProps) {
  const { bruger } = props.authState;
  const roller = bruger && bruger.roller ? bruger.roller : [];
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const logoutUrl = bruger ? bruger.logoutUrl : "auth/logout";

  const renderElement = () => {
    const containerClassName = menuOpen
      ? `${styles.usertoggle} ${styles.active}`
      : styles.usertoggle;
    return (
      <div
        aria-label="Brugermenu"
        aria-haspopup="true"
        className={containerClassName}
      >
        <Avatar bruger={bruger} size={"large"} />
        &ensp;
        <div className={styles.triangle}>
          <ArrowTriangle />
        </div>
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <div className={styles.menu}>
        {bruger && (
          <>
            <div>{bruger.navn}</div>
            {roller.findIndex((role) => role.navn === "administrator") > -1 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/admin");
                }}
              >
                Admin
              </button>
            )}
            <form action={`${logoutUrl}`} id="signoutForm" method="post">
              <input type="submit" className={styles.formlink} value="Log ud" />
            </form>
          </>
        )}
        {!bruger && <div>Ikke logget ind</div>}
      </div>
    );
  };
  return (
    <FoldOutMenu
      state={{ menuOpen, setMenuOpen }}
      element={renderElement()}
      menu={renderMenu()}
    />
  );
}
