import React, { useEffect } from "react";
import { Interaktionstype } from "./enums";
import "./Map.scss";

export default function Map(props: IMapProps) {
  const {
    grunde,
    strukturer,
    containerStyle = {},
    divId,
    indstillinger,
  } = props;
  useEffect(() => {
    if (grunde.length) {
      try {
        SKAT_BBR.RetBBRKort.init(
          divId,
          grunde,
          strukturer,
          Interaktionstype.vis,
          undefined,
          undefined,
          indstillinger
        );
      } catch (error) {
        // If there is an error, it's probably "strukturer" so remove it
        SKAT_BBR.RetBBRKort.init(
          divId,
          grunde,
          [],
          Interaktionstype.vis,
          undefined,
          undefined,
          indstillinger
        );
      }
    }

    return function cleanup() {
      //@ts-ignore
      document.getElementById(divId).innerHTML = "";
      //@ts-ignore
      document.getElementById(divId).classList.remove("ret-bbr-basemap");
    };
  }, [grunde, strukturer, divId, indstillinger]);
  return <div style={{ ...containerStyle }} id={divId} />;
}
function mapPropsAreEqual(prevMap: IMapProps, nextMap: IMapProps) {
  try {
    return (
      prevMap.grunde[0].matrikler[0].matrikelnr ===
      nextMap.grunde[0].matrikler[0].matrikelnr
    );
  } catch (error) {
    return false;
  }
}

// MemoizedMap is a map that does not rerender if mapPropsAreEqual is true
export const MemoizedMap = React.memo(Map, mapPropsAreEqual);

interface IMapProps {
  divId: string;
  grunde: Array<JO.Grund>;
  strukturer: Array<any>;
  containerStyle?: any;
  indstillinger: JO.KortIndstillinger;
}
