import React, { useEffect } from "react";
import styles from "./GrundInfo.module.scss";
import { GrundTemplate } from "../Template";
import Info from "./Info";
import { getRelevantSupplerendeOplysninger } from "util/index";
import SupplerendeOplysninger from "../SupplerendeOplysninger/SupplerendeOplysninger";
//import EnergiOplysninger from "../SupplerendeOplysninger/EnergiOplysninger";
export default function GrundInfo(props: IGrundInfoProps) {
  const { grund, setGrundMarkeret, liste, opgave, supplerendeOplysninger } =
    props;
  const relevantSupplerendeOplysninger = supplerendeOplysninger
    ? getRelevantSupplerendeOplysninger(
        supplerendeOplysninger,
        grund.id_lokalId
      )
    : undefined;
  const getGrundReference = (grund, opgave: Opgave) => {
    let visningsdata: Visningsdata = opgave?.visningsdata;
    let referencer: Array<VisningsdataElementReference> = [];

    if (visningsdata) {
      visningsdata?.elementer?.forEach((o: VisningsdataElement, idx) => {
        o?.referencer?.forEach((r: VisningsdataElementReference, idx) => {
          // referencer can be null according to schema.
          if (grund.id_lokalId === r.bbr_id) {
            // Sub-units on the building will be added if push_flag is set
            if (r.attribut_kilde === undefined) {
              r.attribut_kilde = "bbr";
            }
            referencer.push(r);
          }
        });
      });
    }
    return referencer;
  };
  const referencer: Array<VisningsdataElementReference> =
    opgave !== undefined ? getGrundReference(grund, opgave) : [];

  // const bbrid = grund.id_lokalId;
  // let energiOplysningerGrund = supplerendeOplysninger?.energiOplysninger?.oplysninger.filter(
  //   (oplysning) => oplysning.bbrId === bbrid
  // );
  const energiOplysningerPeriodiseretGrundOplysninger =
    supplerendeOplysninger?.energiOplysningerPeriodiseret?.oplysninger.filter(
      (oplysning) => oplysning.bbrId === grund.id_lokalId
    );
  const energiOplysningerPeriodiseretGrundVisning =
    supplerendeOplysninger?.energiOplysningerPeriodiseret?.visninger.find(
      // There is only one hopefully
      (oplysning) => oplysning.bbrId === grund.id_lokalId
    );

  /*
    const energiOplysningerPeriodiseretEl =
    supplerendeOplysninger?.energiOplysningerPeriodiseretEl?.oplysninger.filter(
      (oplysning) => oplysning.bbrId === grund.id_lokalId
    );
  */

  // If the reference list for the opgave has atleast 1 element, mark the building
  useEffect(() => {
    if (referencer.length > 0) {
      setGrundMarkeret(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencer]);

  return (
    <div className={styles.container}>
      <Info
        element={grund}
        renderEmpty={false} // Empty fields are not shown unless they have a reference
        referencer={referencer}
        templater={new GrundTemplate()}
        setMarkeret={setGrundMarkeret}
        listeType={liste?.listetype}
        energiOplysningerPeriodiseretOplysning={
          energiOplysningerPeriodiseretGrundOplysninger
        }
        energiOplysningerPeriodiseretVisning={
          energiOplysningerPeriodiseretGrundVisning
        }
        visEnergiOplysningerPeriodiseretInfo={true}
        //energiOplysningerPeriodiseretEl={energiOplysningerPeriodiseretEl}
      />

      {/* {energiOplysningerGrund && (
        <EnergiOplysninger energioplysninger={energiOplysningerGrund} />
      )} */}
      {relevantSupplerendeOplysninger && (
        <SupplerendeOplysninger
          supplerendeOplysninger={relevantSupplerendeOplysninger}
        />
      )}
    </div>
  );
}

interface IGrundInfoProps {
  grund: GrundGrund;
  setGrundMarkeret: Function;
  liste?: OpgaveListe;
  opgave?: Opgave;
  supplerendeOplysninger?: SupplerendeOplysninger;
}
