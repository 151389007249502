/**
 * Returns strings divided by space if second is defined and has content.
 * Otherwise just return first.
 * @param first
 * @param second
 */
export function mergeClassNames(first: string, second?: string): string {
  return second ? `${first} ${second}` : first;
}

/**
 *
 * @param oplysninger Supplerende oplysninger for current ejendom
 * @param bbrId an id, that may point to grund, bygning, teknisk anlæg, opgang, or ejendom if null
 */
export function getRelevantSupplerendeOplysninger(
  oplysninger: SupplerendeOplysninger,
  bbrId: string | null
) {
  const relevant: SupplerendeOplysninger = {
    chrOplysninger: oplysninger.chrOplysninger
      ? {
          ...oplysninger.chrOplysninger,
          oplysninger: oplysninger.chrOplysninger.oplysninger.filter(
            (o) => o.bbrId === bbrId
          ),
        }
      : undefined,
    cvrBranchekoder: oplysninger.cvrBranchekoder
      ? {
          ...oplysninger.cvrBranchekoder,
          oplysninger: oplysninger.cvrBranchekoder.oplysninger.filter(
            (o) => o.bbrId === bbrId
          ),
        }
      : undefined,
    cvrVirksomheder: oplysninger.cvrVirksomheder
      ? {
          ...oplysninger.cvrVirksomheder,
          oplysninger: oplysninger.cvrVirksomheder.oplysninger.filter(
            (o) => o.bbrId === bbrId
          ),
        }
      : undefined,
    elOplysninger: oplysninger.elOplysninger
      ? {
          ...oplysninger.elOplysninger,
          oplysninger: oplysninger.elOplysninger.oplysninger.filter(
            (o) => o.bbrId === bbrId
          ),
        }
      : undefined,
    energiOplysninger: oplysninger.energiOplysninger
      ? {
          ...oplysninger.energiOplysninger,
          oplysninger: oplysninger.energiOplysninger.oplysninger.filter(
            (o) => o.bbrId === bbrId
          ),
        }
      : undefined,
    smileyBranchekoder: oplysninger.smileyBranchekoder
      ? {
          ...oplysninger.smileyBranchekoder,
          oplysninger: oplysninger.smileyBranchekoder.oplysninger.filter(
            (o) => o.bbrId === bbrId
          ),
        }
      : undefined,
    smileyVirksomheder: oplysninger.smileyVirksomheder
      ? {
          ...oplysninger.smileyVirksomheder,
          oplysninger: oplysninger.smileyVirksomheder.oplysninger.filter(
            (o) => o.bbrId === bbrId
          ),
        }
      : undefined,
  };

  return relevant;
}

export const parseQueryString = (qs: string): any => {
  const response = {};
  if (!qs) return response;
  if (qs.indexOf("?") === 0) qs = qs.substr(1, qs.length - 1);
  if (!qs || qs.indexOf("=") < 0) return response;
  const vars = qs.split("&");
  vars.forEach((v) => {
    const pair = v.split("=");
    const key = decodeURIComponent(pair[0]).toLowerCase();
    const val = decodeURIComponent(pair[1]);
    response[key] = val;
  });
  return response;
};

/**
 * Turn a string of words into a string array of lines of max length
 * @param input string to convert
 * @param maxLineLength max line length
 */
export const breakWords = (
  input: string,
  maxLineLength: number = 24
): string[] => {
  const words = input.split(" ");
  return words.reduce(
    (prev, curr) => {
      const currentLine = prev[prev.length - 1];
      const withAddedWord = currentLine + " " + curr;
      if (withAddedWord.length > maxLineLength) return [...prev, curr];
      prev[prev.length - 1] = prev[prev.length - 1] + " " + curr;
      return prev;
    },
    [""]
  );
};

interface kommune {
  knr: number;
  navn: string;
}
export const kommuner: kommune[] = [
  { knr: 101, navn: "København" },
  { knr: 147, navn: "Frederiksberg" },
  { knr: 151, navn: "Ballerup" },
  { knr: 153, navn: "Brøndby" },
  { knr: 155, navn: "Dragør" },
  { knr: 157, navn: "Gentofte" },
  { knr: 159, navn: "Gladsaxe" },
  { knr: 161, navn: "Glostrup" },
  { knr: 163, navn: "Herlev" },
  { knr: 165, navn: "Albertslund" },
  { knr: 167, navn: "Hvidovre" },
  { knr: 169, navn: "Høje-Taastrup" },
  { knr: 173, navn: "Lyngby-Taarbæk" },
  { knr: 175, navn: "Rødovre" },
  { knr: 183, navn: "Ishøj" },
  { knr: 185, navn: "Tårnby" },
  { knr: 187, navn: "Vallensbæk" },
  { knr: 190, navn: "Furesø" },
  { knr: 201, navn: "Allerød" },
  { knr: 210, navn: "Fredensborg" },
  { knr: 217, navn: "Helsingør" },
  { knr: 219, navn: "Hillerød" },
  { knr: 223, navn: "Hørsholm" },
  { knr: 230, navn: "Rudersdal" },
  { knr: 240, navn: "Egedal" },
  { knr: 250, navn: "Frederikssund" },
  { knr: 253, navn: "Greve" },
  { knr: 259, navn: "Køge" },
  { knr: 260, navn: "Halsnæs" },
  { knr: 265, navn: "Roskilde" },
  { knr: 269, navn: "Solrød" },
  { knr: 270, navn: "Gribskov" },
  { knr: 306, navn: "Odsherred" },
  { knr: 316, navn: "Holbæk" },
  { knr: 320, navn: "Faxe" },
  { knr: 326, navn: "Kalundborg" },
  { knr: 329, navn: "Ringsted" },
  { knr: 330, navn: "Slagelse" },
  { knr: 336, navn: "Stevns" },
  { knr: 340, navn: "Sorø" },
  { knr: 350, navn: "Lejre" },
  { knr: 360, navn: "Lolland" },
  { knr: 370, navn: "Næstved" },
  { knr: 376, navn: "Guldborgsund" },
  { knr: 390, navn: "Vordingborg" },
  { knr: 400, navn: "Bornholm" },
  { knr: 410, navn: "Middelfart" },
  { knr: 411, navn: "Christiansø" },
  { knr: 420, navn: "Assens" },
  { knr: 430, navn: "Faaborg-Midtfyn" },
  { knr: 440, navn: "Kerteminde" },
  { knr: 450, navn: "Nyborg" },
  { knr: 461, navn: "Odense" },
  { knr: 479, navn: "Svendborg" },
  { knr: 480, navn: "Nordfyns" },
  { knr: 482, navn: "Langeland" },
  { knr: 492, navn: "Ærø" },
  { knr: 510, navn: "Haderslev" },
  { knr: 530, navn: "Billund" },
  { knr: 540, navn: "Sønderborg" },
  { knr: 550, navn: "Tønder" },
  { knr: 561, navn: "Esbjerg" },
  { knr: 563, navn: "Fanø" },
  { knr: 573, navn: "Varde" },
  { knr: 575, navn: "Vejen" },
  { knr: 580, navn: "Aabenraa" },
  { knr: 607, navn: "Fredericia" },
  { knr: 615, navn: "Horsens" },
  { knr: 621, navn: "Kolding" },
  { knr: 630, navn: "Vejle" },
  { knr: 657, navn: "Herning" },
  { knr: 661, navn: "Holstebro" },
  { knr: 665, navn: "Lemvig" },
  { knr: 671, navn: "Struer" },
  { knr: 706, navn: "Syddjurs" },
  { knr: 707, navn: "Norddjurs" },
  { knr: 710, navn: "Favrskov" },
  { knr: 727, navn: "Odder" },
  { knr: 730, navn: "Randers" },
  { knr: 740, navn: "Silkeborg" },
  { knr: 741, navn: "Samsø" },
  { knr: 746, navn: "Skanderborg" },
  { knr: 751, navn: "Aarhus" },
  { knr: 756, navn: "Ikast-Brande" },
  { knr: 760, navn: "Ringkøbing-Skjern" },
  { knr: 766, navn: "Hedensted" },
  { knr: 773, navn: "Morsø" },
  { knr: 779, navn: "Skive" },
  { knr: 787, navn: "Thisted" },
  { knr: 791, navn: "Viborg" },
  { knr: 810, navn: "Brønderslev" },
  { knr: 813, navn: "Frederikshavn" },
  { knr: 820, navn: "Vesthimmerlands" },
  { knr: 825, navn: "Læsø" },
  { knr: 840, navn: "Rebild" },
  { knr: 846, navn: "Mariagerfjord" },
  { knr: 849, navn: "Jammerbugt" },
  { knr: 851, navn: "Aalborg" },
  { knr: 860, navn: "Hjørring" },
];

/**
 *
 * @param ejendom
 * @returns liste over ESR-numre i ejendommen
 */
export const getESRList = (ejendom: Ejendom, includeKnr: boolean = false) => {
  return ejendom.grunde
    .filter((g) => g.grund?.bestemtFastEjendom?.ejendomsnummer)
    .map((g) => {
      if (includeKnr) {
        return `(${g.grund.bestemtFastEjendom.kommunekode}) ${g.grund.bestemtFastEjendom.ejendomsnummer}`;
      }
      return g.grund.bestemtFastEjendom.ejendomsnummer.toString();
    });
};

/**
 *
 * @param data: Ejendom
 * @returns grunde: Array<JO.Grund>
 */
// TODO: Fix data type, should be "Ejendom", but jordstykker breaks it
export const constructGrundeFromEjendom = (data): Array<JO.Grund> => {
  if (Object.keys(data).length === 0) {
    return [];
  }
  let grunde: Array<JO.Grund> = [];

  data.grunde.forEach((grund, idx) => {
    let grund_obj = {} as JO.Grund;

    grund_obj.id = "test";
    grund_obj.matrikler = [];

    grund.jordstykker.forEach((jordstykke, idx) => {
      let matrikel = {
        landsejerlavskode: jordstykke.ejerlavskode,
        matrikelnr: jordstykke.matrikelNummer,
      } as JO.Matrikel;

      grund_obj.matrikler.push(matrikel);
    });
    if (grund_obj.matrikler.length > 0) {
      grunde.push(grund_obj);
    }
  });

  return grunde;
};
