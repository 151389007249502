import React from "react";
import styles from "./OpgaveInfo.module.scss";
import ExternalArrow from "components/common/icons/ExternalArrow";
import { motion } from "framer-motion";
import { OpgaveListeType } from "interfaces/Opgaveliste/enums";

export default function OpgaveInfo(props: OpgaveInfoProps) {
  const {
    opgaveState,
    // listetype
  } = props;

  const retILinks = opgaveState.data?.[opgaveState.idx]?.visningsdata?.links
    ? opgaveState.data?.[opgaveState.idx]?.visningsdata?.links?.filter(
        (o) => !o.skjul
      )
    : [];

  // let prioritet = opgaveState.data?.[opgaveState.idx]?.prioritet;
  return (
    <div className={styles.opgave}>
      {opgaveState.state === "LOADING" && (
        <ul className={styles.info}>
          {Array.from(new Array(6)).map((o, i) => {
            return (
              <motion.li key={i} className={`${styles.loading}`}>
                <div className={styles.text}>
                  <div
                    style={{ width: 50 + Math.random() * 70 + "%" }}
                    className={styles.line}
                  ></div>
                </div>
              </motion.li>
            );
          })}
        </ul>
      )}
      {opgaveState.state === "IDLE" && renderOpgaveElementer(opgaveState)}
      {opgaveState.state === "EMPTY" && (
        <ul className={styles.info}>
          <li key={"empty"}>
            <h5 className={styles.itemheader}>{"Ingen fejl på ejendommen."}</h5>
            <br></br>
            <p className={styles.itemvalue}>
              {"Der blev ikke fundet nogle fejl på ejendommen."}
            </p>
          </li>
        </ul>
      )}
      {retILinks && retILinks.length > 0 && (
        <div className={styles.links}>
          <h5>Ret i</h5>
          <ul>
            {opgaveState.state === "IDLE" && renderOpgaveLinks(retILinks)}
          </ul>
        </div>
      )}
    </div>
  );
}
const renderOpgaveElementer = (opgaveState) => {
  const elementer =
    opgaveState.data?.[opgaveState.idx]?.visningsdata?.elementer;
  if (!elementer || elementer.length === 0) return null;
  return (
    <ul className={styles.info}>
      {elementer.map((o: VisningsdataElement, idx: number) => {
        return (
          <li key={idx}>
            <h5 className={styles.itemheader}>{o.info.navn}</h5>
            <p className={styles.itemvalue}>{o.info.vaerdi}</p>
          </li>
        );
      })}
    </ul>
  );
};
const renderOpgaveLinks = (retILinks) => {
  return retILinks.map((o: VisningsdataLink, idx: number) => {
    return (
      <li key={idx}>
        <a
          className="external"
          rel="noopener noreferrer"
          target="_blank"
          href={o.url}
        >
          <span>{o.system}</span>
          <ExternalArrow />
        </a>
      </li>
    );
  });
};
interface OpgaveInfoProps {
  opgaveState: {
    state: "IDLE" | "LOADING" | "ERROR" | "EMPTY";
    data?: Array<Opgave>;
    idx: number;
  };
  listetype?: OpgaveListeType;
}
