import React, { useRef, MutableRefObject } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./Modal.module.scss";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import Close from "./icons/Close";

export default function Modal({ isToggled, setIsToggled, children }) {
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside(ref, () => setIsToggled(false));

  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          className={styles.backdrop}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            ref={ref as MutableRefObject<HTMLDivElement>}
            className={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={styles.modalcont}
              initial={{ y: 50 }}
              animate={{ y: 0 }}
            >
              <button
                className={styles.close}
                onClick={() => setIsToggled(false)}
              >
                <Close height={18} /> <span>Luk</span>
              </button>
              <div className={styles.content}>{children}</div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
