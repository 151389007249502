import React from "react";

export default function Download(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (25 / 23) : 25}
      height={height ? height : width ? width * (23 / 25) : 23}
      viewBox="0 0 25 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon-download--green"
          transform="translate(-1.000000, 0.000000)"
          fill={primaryColor ? primaryColor : "currentColor"}
          fillRule="nonzero"
        >
          <path
            d="M18.5,12.24 C18.659655,12.3940643 18.7481589,12.6075123 18.7443668,12.8293482 C18.7405748,13.051184 18.6448273,13.2614824 18.48,13.41 L14.48,17.41 C14.3264348,17.556786 14.1224325,17.6391027 13.91,17.64 L13.84,17.64 C13.6020756,17.6215943 13.3852206,17.496627 13.25,17.3 C11.867862,15.4801326 10.242728,13.8583632 8.42,12.48 C8.19313634,12.3013672 8.07880513,12.0152734 8.12007385,11.7294873 C8.16134256,11.4437012 8.35194149,11.2016406 8.62007386,11.0944873 C8.88820623,10.987334 9.19313635,11.0313672 9.42,11.21 C10.7766082,12.2494907 12.0351947,13.4110054 13.18,14.68 C13.29,12.7 13.34,5.1 13.18,0.87 C13.1634315,0.4281722 13.5081722,0.0565685425 13.95,0.04 C14.3918278,0.0234314575 14.7634315,0.3681722 14.78,0.81 C14.9,5.13 14.84,12.81 14.78,14.81 L17.36,12.24 C17.5103659,12.0873137 17.7157035,12.0013377 17.93,12.0013377 C18.1442965,12.0013377 18.3496341,12.0873137 18.5,12.24 Z M24.82,22.66 L2.82,22.12 C2.46184836,22.1100974 2.15275274,21.8660745 2.06,21.52 C1.50593597,19.0457495 1.1514633,16.531 1,14 C0.986316598,13.7877259 1.05773615,13.5787422 1.19847279,13.4192407 C1.33920943,13.2597392 1.53767405,13.1628541 1.75,13.15 C2.18833965,13.132379 2.56290457,13.4628775 2.6,13.9 C2.73601506,16.1197849 3.019979,18.3280222 3.45,20.51 L24,21 C24,18.55 24,16.07 23.95,13.62 C23.9361929,13.1781722 24.2831722,12.8088071 24.725,12.795 C25.1668278,12.7811929 25.5361929,13.1281722 25.55,13.57 C25.64,16.32 25.65,19.1 25.55,21.85 C25.5392557,22.0612726 25.4501155,22.2609467 25.3,22.41 C25.1729033,22.5452537 25.0036819,22.6333899 24.82,22.66 L24.82,22.66 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
