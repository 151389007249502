import React from "react";
import Modal from "components/common/Modal";
import Map from "components/Map/Map";
import "./MapOverride.scss";
import { StrukturType } from "components/Map/enums";
import { Strukturtilstande } from "components/Map/enums";
import { constructGrundeFromEjendom } from "../../../util";
export default function FullScreenMap({ ejendom, isToggled, setIsToggled }) {
  const state = ejendom.state;

  return (
    <Modal isToggled={isToggled} setIsToggled={setIsToggled}>
      <div className="fullscreen-map-override">
        {state === "IDLE" && (
          <Map
            divId="ejendom-full-screen-map-container"
            grunde={constructGrundeFromEjendom(ejendom.data)}
            strukturer={constructStrukturerFromEjendom(ejendom.data)}
            indstillinger={{
              signatur: {
                visBygningssignatur: true,
                visStatusIkoner: false,
              },
            }}
            containerStyle={{
              height: "80vh",
              width: "80vw",
              margin: 15,
            }}
          />
        )}
      </div>
    </Modal>
  );
}

function constructStrukturerFromEjendom(data): Array<JO.Struktur> {
  let strukturer: Array<JO.Struktur> = [];
  data.grunde.forEach((grund, idx) => {
    // Bygninger
    grund.bygninger.forEach((bygningobj, idx) => {
      let struktur = {} as any;
      try {
        let bygning = bygningobj.bygning;
        let x: number;
        let y: number;

        let wkt_pattern = /\(.*?\)/i;

        let geom = bygning.byg404Koordinat.match(wkt_pattern);

        x = geom[0].replace("(", "").replace(")", "").split(" ")[0];
        y = geom[0].replace("(", "").replace(")", "").split(" ")[1];

        struktur.id = bygning.id_lokalId;
        struktur.anvendelseskode = bygning.byg021BygningensAnvendelse;
        struktur.anvendelse = bygning.byg021BygningensAnvendelseCLV.title;
        struktur.matrikelId = `${grund.jordstykker[0].ejerlavskode}-${grund.jordstykker[0].matrikelNummer}`;
        struktur.sikkerhedsklassifikation = "Sikker";
        struktur.tilstand = Strukturtilstande.StamData;
        struktur.strukturType = StrukturType.Bygning;
        struktur.nummer = bygning.byg007Bygningsnummer.toString();
        struktur.maaFlyttes = false;

        struktur.oprindeligGeometri = {
          x: x,
          y: y,
        };
        strukturer.push(struktur);
      } catch (error) {
        return [];
      }
    });
    // Tekniske anlæg
    grund.tekniskeAnlaeg.forEach((teobj, idx) => {
      let struktur = {} as any;
      try {
        let tekniskAnlaeg = teobj.tekniskAnlaeg;
        let x: number;
        let y: number;

        let wkt_pattern = /\(.*?\)/i;

        let geom = tekniskAnlaeg.tek109Koordinat.match(wkt_pattern);

        x = geom[0].replace("(", "").replace(")", "").split(" ")[0];
        y = geom[0].replace("(", "").replace(")", "").split(" ")[1];
        struktur.id = tekniskAnlaeg.id_lokalId;
        struktur.anvendelseskode = tekniskAnlaeg.tek020Klassifikation;
        struktur.anvendelse = tekniskAnlaeg.tek020KlassifikationCLV.title;
        struktur.matrikelId = `${grund.jordstykker[0].ejerlavskode}-${grund.jordstykker[0].matrikelNummer}`;
        struktur.sikkerhedsklassifikation = "Sikker";
        struktur.tilstand = Strukturtilstande.StamData;
        struktur.strukturType = StrukturType.TekniskAnlæg;
        struktur.nummer = tekniskAnlaeg.tek007Anlægsnummer.toString();
        struktur.maaFlyttes = false;

        struktur.oprindeligGeometri = {
          x: x,
          y: y,
        };
        strukturer.push(struktur);
      } catch (error) {
        return [];
      }
    });
  });
  //return grunde;
  return strukturer;
}
