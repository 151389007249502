import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Drawer.module.scss";
import PlusSimple from "components/common/icons/PlusSimple";
import MinusSimple from "components/common/icons/MinusSimple";

export default function Drawer(props: IDrawer) {
  const { headerText, children, isOpen } = props;
  const [sectionOpen, setsectionOpen] = useState(false);

  // TODO: This may be redundant.
  const { open, setOpen } = {
    open: sectionOpen,
    setOpen: setsectionOpen,
  };

  const variants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  useEffect(() => {
    if (isOpen !== undefined) {
      setsectionOpen(isOpen);
    }
  }, [isOpen]);

  const renderHeaderButton = () => {
    return (
      <button
        className={styles.header}
        aria-expanded={open} // NB Toggle
        onClick={() => {
          setOpen(!open);
        }}
      >
        <h2>{headerText}</h2>
        <AnimatePresence>
          {open && (
            <motion.span
              className={styles.expand}
              key={open.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <MinusSimple width={17} />
            </motion.span>
          )}
          {!open && (
            <motion.span
              className={styles.expand}
              key={open.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <PlusSimple width={17} />
            </motion.span>
          )}
        </AnimatePresence>
      </button>
    );
  };
  return (
    <div className={styles.container}>
      {renderHeaderButton()}
      <AnimatePresence>
        {open && (
          <motion.div
            style={{ overflow: "hidden" }}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
          >
            {children}
          </motion.div>
        )}
        {!open && (
          <motion.div
            style={{ overflow: "hidden" }}
            variants={variants}
            initial="closed"
            animate="closed"
            exit={variants.closed}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IDrawer {
  children: React.ReactNode;
  headerText: string;
  isOpen?: boolean;
}
