import React, { useState, useEffect, useContext } from "react";
import styles from "./Fejllister.module.scss";
import Sidebar from "./Sidebar/Sidebar";
import PageContainer from "components/common/layout/PageContainer";
import Accordion from "./Sidebar/Accordion";
import opgavelisteApi from "api/opgavelister";
import { useQuery } from "hooks/useQuery";
import MainContent from "./MainContent/MainContent";
import TwoPaneView from "components/common/layout/TwoPaneView";
import {
  OpgaveListeStatus,
  OpgaveSortOrder,
} from "interfaces/Opgaveliste/enums";
import Pointer from "components/common/icons/Pointer";
import { OpgaveListePageOptions } from "interfaces/paging";
import { useLocalStorage } from "hooks/useLocalStorage";
import { AuthContext } from "context/AuthProvider";

enum CurrentAccordionOpen {
  fejllister,
  udgåede,
  ingen,
}

export default function Fejllister() {
  const authState = useContext(AuthContext);
  const query = useQuery();
  const queryId = query.get("id");
  const [fejllisterState, setFejllisterState] = useState<{
    state: "IDLE" | "LOADING" | "ERROR";
    data: Array<OpgaveListe>;
  }>({ state: "IDLE", data: [] });

  const [lsSortOrder, setLsSortOrder] = useLocalStorage<number>(
    "opgave-options-sort-order",
    OpgaveSortOrder.bfeAsc
  );
  const [lsBrugerStatus, setLsBrugerStatus] = useLocalStorage<
    undefined | string
  >("opgave-options-bruger-status", undefined);
  const [lsBrugerId, setLsBrugerId] = useLocalStorage<boolean>(
    "opgave-options-bruger-id",
    false
  );
  const [lsKommuneNr, setLsKommuneNr] = useLocalStorage<undefined | string>(
    "opgave-options-kommunenr",
    undefined
  );

  const [lsOpgavePage, setLsOgavePage] = useLocalStorage<
    undefined | { page: number; opgaveId: string }
  >("opgave-options-opgave-page", undefined);

  const defaultOpgaveOptions: OpgaveListePageOptions = {
    id: "",
    page: 1,
    sortOrder: OpgaveSortOrder.bfeAsc,
    brugerId: undefined,
    brugerStatus: undefined,
    kommunenr: undefined,
  };

  const [opgaveOptions, setOpgaveOptions] = useState<OpgaveListePageOptions>({
    id: "",
    page: 1,
    sortOrder: lsSortOrder,
    brugerId: lsBrugerId && authState.bruger ? authState.bruger.id : undefined,
    brugerStatus: lsBrugerStatus,
    kommunenr:
      authState.bruger && authState.bruger.erSkatteforvaltningen
        ? lsKommuneNr
        : undefined,
  });
  useEffect(() => {
    // Gem ændringer i sortorder, og filtre i localStorage,
    // så de kan bibeholdes på tvers af pageviews
    // e.g. gå ind på ejendom, og tryk "back" for at
    // komme tilbage til fejllisten, se issue #304
    setLsSortOrder(
      opgaveOptions.sortOrder
        ? parseInt(opgaveOptions.sortOrder)
        : OpgaveSortOrder.bfeAsc
    );
    setLsBrugerStatus(opgaveOptions.brugerStatus);
    setLsBrugerId(opgaveOptions.brugerId ? true : false);
    setLsKommuneNr(opgaveOptions.kommunenr);
  }, [
    setLsSortOrder,
    setLsBrugerStatus,
    setLsBrugerId,
    setLsKommuneNr,
    opgaveOptions.sortOrder,
    opgaveOptions.brugerId,
    opgaveOptions.brugerStatus,
    opgaveOptions.kommunenr,
  ]);

  useEffect(() => {
    if (
      opgaveOptions.id &&
      !(
        opgaveOptions.id === lsOpgavePage?.opgaveId &&
        opgaveOptions.page === lsOpgavePage?.page
      )
    ) {
      if (opgaveOptions.id) {
        setLsOgavePage({
          page: opgaveOptions.page,
          opgaveId: opgaveOptions.id,
        });
      } else {
        setLsOgavePage(undefined);
      }
    }
  }, [lsOpgavePage, setLsOgavePage, opgaveOptions.page, opgaveOptions.id]);
  let currentOpgaveliste: OpgaveListe | undefined;
  if (queryId) {
    currentOpgaveliste = fejllisterState.data.find((o) => o.id === queryId);
  }
  if (
    currentOpgaveliste &&
    (!opgaveOptions || opgaveOptions.id !== currentOpgaveliste.id)
  ) {
    if (opgaveOptions.brugerStatus) {
      const currentBrugerStatuser = opgaveOptions.brugerStatus.split(",");
      const legalBrugerStatuser = [
        "0", // 0 allways allowed, but visesUI false for some reason.
        ...currentOpgaveliste.brugerstatus
          .filter((s) => s.visesUi)
          .map((s) => s.status.toString()),
      ];
      const newBrugerStatuser = currentBrugerStatuser
        .filter((s) => legalBrugerStatuser.indexOf(s) > -1)
        .join(",");

      opgaveOptions.brugerStatus = newBrugerStatuser;
    }
    const page =
      lsOpgavePage && lsOpgavePage.opgaveId === currentOpgaveliste.id
        ? lsOpgavePage.page
        : 1;

    setOpgaveOptions({
      ...opgaveOptions,
      id: currentOpgaveliste.id,
      page,
    });
  }
  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      setFejllisterState({ state: "LOADING", data: [] });
      try {
        const ol = await opgavelisteApi.getAll();
        if (stillMounted) {
          setFejllisterState({ state: "IDLE", data: ol });
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setFejllisterState({ state: "ERROR", data: [] });
          }
        }
      }
    };

    fetchData();

    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
  }, []);

  const [currentAccordionOpen, setCurrentAccordionOpen] = useState(
    CurrentAccordionOpen.fejllister
  );
  const setOpgaveListeOpen = () => {
    if (currentAccordionOpen === CurrentAccordionOpen.fejllister) {
      setCurrentAccordionOpen(CurrentAccordionOpen.ingen);
    } else {
      setCurrentAccordionOpen(CurrentAccordionOpen.fejllister);
    }
  };
  const setUdgåedeOpen = () => {
    if (currentAccordionOpen === CurrentAccordionOpen.udgåede) {
      setCurrentAccordionOpen(CurrentAccordionOpen.ingen);
    } else {
      setCurrentAccordionOpen(CurrentAccordionOpen.udgåede);
    }
  };

  const renderSidebar = () => {
    return (
      <Sidebar>
        <Accordion
          isOpen={currentAccordionOpen === CurrentAccordionOpen.fejllister}
          toggleIsOpen={setOpgaveListeOpen}
          headerText="Fejllister"
          fejllisterState={{
            ...fejllisterState,
            data: fejllisterState.data.filter(
              (o) => o.status === OpgaveListeStatus.aktiv
            ),
          }}
          currentOpgaveliste={currentOpgaveliste}
        />
        <Accordion
          isOpen={currentAccordionOpen === CurrentAccordionOpen.udgåede}
          toggleIsOpen={setUdgåedeOpen}
          headerText="Udgåede fejllister"
          fejllisterState={{
            ...fejllisterState,
            data: fejllisterState.data.filter(
              (o) => o.status === OpgaveListeStatus.udgaaet
            ),
          }}
          currentOpgaveliste={currentOpgaveliste}
        />
      </Sidebar>
    );
  };

  const renderMain = () => {
    return (
      <PageContainer classNames={styles.content}>
        <MainContent
          opgaveliste={currentOpgaveliste}
          opgaveOptions={opgaveOptions}
          defaultOpgaveOptions={defaultOpgaveOptions}
          setOpgaveOptions={setOpgaveOptions}
        />
        {!currentOpgaveliste && (
          <div className={styles.placeholder}>
            <span className={styles.arrow}>
              <Pointer width={45} />
            </span>
            <p>Vælg en fejlliste i oversigten til venstre</p>
          </div>
        )}
      </PageContainer>
    );
  };
  return <TwoPaneView sidebar={renderSidebar()} main={renderMain()} />;
}

export interface OpgaveOptions {
  id: string;
  page: number;
  totalCount: number;
  sortOrder?: OpgaveSortOrder;
}
