import React from "react";

export default function Bygning(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (30 / 30) : 30}
      height={height ? height : width ? width * (30 / 30) : 30}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <path
              fill={primaryColor ? primaryColor : "currentColor"}
              d="M28.5,13L28.5,13c0,0-5.9-3.8-7.8-5.2c-1.3-1-5.2-4-5.2-4c-0.3-0.2-0.7-0.2-1,0L8.1,8.4c0,0,0.2-1.6-0.1-2.2
              C7.8,5.6,5.7,6.1,5.7,6.6c0,0.6,0,3.5,0,3.5L5,10.5L1.5,13c-0.3,0.3-0.4,0.8-0.2,1.2c0.2,0.3,0.7,0.5,1.1,0.3l0.5-0.2
              c0,0,0.3,3.6,0.3,4.8c0,1-0.3,3.9-0.3,3.9l-0.4,0.1c-0.5,0.2-0.9,0.7-0.9,1.3v0.6c0,0.7,0.6,1.4,1.4,1.4l4.4-0.2l5.7,0.2v-9.6
              c0,0,3.3-0.2,4.4,0c0.2,0,0,9.2,0,9.6c0,0.6,9.8,0,9.8,0c0.7,0,1.4-0.6,1.4-1.4l0-0.8c-0.1-0.6-0.5-1-1-1.1l-0.4-0.1l0-9.1
              l0.7,0.5c0.2,0.1,0.5,0,0.7-0.1c0.1-0.1,0.3-0.2,0.4-0.4C29,13.6,28.8,13.2,28.5,13z M18.6,24.8c0,0,0.3-9.1,0.1-9.5
              c-0.3-0.7-7.2-0.4-7.4-0.1c-0.2,0.4,0,9.6,0,9.6H4.5l0.3-5.6L4.5,13L15,5.6c0,0,3.4,2.7,4.5,3.5c1.4,1,5.9,3.8,5.9,3.8l0,11.9
              l-3.8,0.1L18.6,24.8z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
