import React, { useEffect, useState } from "react";
import styles from "./Organisationer.module.scss";
import organisationerApi from "api/organisation";
import { PageOptions } from "interfaces/paging";
import { useHistory } from "react-router-dom";
import { parseQueryString } from "util/index";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { motion } from "framer-motion";
import Organisation from "./Organisation";

interface IOrganisationerState {
  state: "IDLE" | "LOADING" | "ERROR";
  organisationer?: Organisation[];
}

export default function Organisationer() {
  const history = useHistory();
  const { search } = history.location;
  const query = parseQueryString(search);
  const { id } = query;

  // const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
  //   ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
  //   : 50;
  const pageSize = 10;

  const [state, setState] = useState<IOrganisationerState>({
    state: "LOADING",
  });

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 1,
    pageSize: pageSize,
  });

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      setState({ state: "LOADING" });
      if (pageOptions) {
        try {
          const ol = await organisationerApi.getAllWithHeaders(pageOptions);
          if (stillMounted) {
            //TODO
            const listContentRange = ol.headers["list-content-range"];
            const tc = parseInt(listContentRange.split("/")[1]);
            setTotalCount(tc);
            setState({ state: "IDLE", organisationer: ol.data });
          }
        } catch (error) {
          if (error === "CANCEL") {
            console.debug(error);
          } else {
            if (stillMounted) {
              setState({ state: "ERROR" });
            }
          }
        }
      }
    };
    fetchData();
  }, [pageOptions]);

  const updateOrganisation = (organisation: Organisation) => {
    if (state.organisationer) {
      const index = state.organisationer.findIndex(
        (o) => o.id === organisation.id
      );
      if (index > -1) {
        setState({
          ...state,
          organisationer: [
            ...state.organisationer.slice(0, index),
            organisation,
            ...state.organisationer.slice(index + 1),
          ],
        });
      }
    }
  };
  return (
    <div className={styles.container}>
      <h1>Organisationer</h1>
      {state.state === "ERROR" && <div>why?</div>}
      {state.state === "IDLE" && state.organisationer && !id && (
        <>
          <div style={{ display: "flex" }}>
            <NextPreviousPage
              pageOptions={pageOptions}
              setPageOptions={setPageOptions}
              totalCount={totalCount}
            />
          </div>

          <ul role="tablist">
            <li className={styles.listHeader}>
              <div className={styles.navn}>
                <h2>Navn</h2>
              </div>
              <div className={styles.cvr}>
                <h2>CVR</h2>
              </div>
              <div className={styles.email}>
                <h2>Email</h2>
              </div>
            </li>
            {state.organisationer.map((org, i) => {
              return (
                <li key={org.id}>
                  <motion.button
                    role="tab"
                    aria-controls="artikel-main-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={styles.opgave}
                    title={org.navn}
                    onClick={() => {
                      history.push(`/admin?type=organisationer&id=${org.id}`);
                    }}
                  >
                    <div className={styles.listrow}>
                      <div className={styles.navn}>
                        <h2>{org.navn}</h2>
                      </div>
                      <div className={styles.cvr}>{org.cvr}</div>
                      <div className={styles.email}>
                        {org.email ? org.email : ""}
                      </div>
                    </div>
                  </motion.button>
                </li>
              );
            })}
          </ul>
        </>
      )}
      {state.state === "IDLE" && state.organisationer && id && (
        <Organisation
          id={id}
          organisation={state.organisationer.find((o) => o.id === id)}
          history={history}
          updateOrganisation={updateOrganisation}
        />
      )}
    </div>
  );
}
