import React from "react";
import styles from "./OpgaveListeItem.module.scss";
import {
  OpgaveListeType,
  OpgaveListeStatus,
} from "interfaces/Opgaveliste/enums";
import { mergeClassNames } from "util/index";

export default function OpgaveListeItem(props: IOpgaveListeItemProps) {
  const { opgaveliste } = props;
  const { navn, beskrivelse, antalOpgaver, listetype, status } = opgaveliste;
  return (
    <div className={styles.container}>
      <div className={styles.navn}>
        <h2>{navn}</h2>
      </div>
      <div className={styles.beskrivelse}>
        <p>{beskrivelse}</p>
      </div>
      <div className={styles.antal}>
        <p>{antalOpgaver}</p>
      </div>
      <div className={styles.listetype}>
        <span
          className={mergeClassNames(
            styles.circle,
            listetype === OpgaveListeType.skal ? styles.skal : styles.kan
          )}
        ></span>
        {listetype === OpgaveListeType.skal && <p> Skal</p>}
        {listetype === OpgaveListeType.kan && <p> Bør</p>}
      </div>
      <div className={styles.status}>
        {status === OpgaveListeStatus.aktiv && (
          <p className={styles.aktiv}>Aktiv</p>
        )}
        {status === OpgaveListeStatus.passiv && (
          <p className={styles.passiv}>Passiv</p>
        )}
        {status === OpgaveListeStatus.udgaaet && (
          <p className={styles.udgaaet}>Udgået</p>
        )}
      </div>
    </div>
  );
}

interface IOpgaveListeItemProps {
  opgaveliste: OpgaveListe;
}
