import React from "react";
import styles from "./BygningPaaFremmedGrund.module.scss";

export default function BygningPaaFremmedGrund(
  props: IBygningPaaFremmedGrundProps
) {
  const { BygningPaaFremmedGrund } = props;
  const { bfeNummer, id_lokalid } = BygningPaaFremmedGrund;
  return (
    <div className={styles.oplysning}>
      <h4>Bygning På Fremmed Grund</h4>
      <ul key={id_lokalid} className={styles.bpfg}>
        <li className={styles.item}>
          <label htmlFor={"bfenummer"}>BFE</label>

          <div id={"bfenummer"}>
            <p>
              <span>{bfeNummer}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}

interface IBygningPaaFremmedGrundProps {
  BygningPaaFremmedGrund: any;
}
