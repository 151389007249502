import React, { FunctionComponent } from "react";
import { units } from "util/style";

export const Spacing: FunctionComponent<ISpacingProps> = ({
  h = 0,
  w = 0,
  bottom = 0,
  left = 0,
  right = 0,
  top = 0,
  children = <span>&nbsp;</span>,
}) => {
  const { sizeBase } = units;
  const spacingTop = top ? top : h ? h : 0;
  const spacingRight = right ? right : w ? w : 0;
  const spacingBottom = bottom ? bottom : h ? h : 0;
  const spacingLeft = left ? left : w ? w : 0;
  return (
    <div
      style={{
        marginTop: sizeBase * spacingTop,
        marginRight: sizeBase * spacingRight,
        marginBottom: sizeBase * spacingBottom,
        marginLeft: sizeBase * spacingLeft,
      }}
    >
      {children}
    </div>
  );
};

interface ISpacingProps {
  h?: number;
  w?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}
