import React, { useState } from "react";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import Arrow from "components/common/icons/Arrow";
import Download from "components/common/icons/Download";
import Checkmark from "components/common/icons/Checkmark";
import FoldOutMenu from "components/common/FoldOutMenu";
import ArrowTriangle from "components/common/icons/ArrowTriangle";
import { OpgaveSortOrder } from "interfaces/Opgaveliste/enums";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { OpgaveListePageOptions } from "interfaces/paging";
import FilterOptions from "./Header/FilterOptions";
import AktiveFiltre from "./Header/AktiveFiltre";

interface HeaderProps {
  opgaveliste: OpgaveListe;
  opgaveOptions: OpgaveListePageOptions;
  defaultOpgaveOptions: OpgaveListePageOptions;
  setOpgaveOptions: React.Dispatch<
    React.SetStateAction<OpgaveListePageOptions>
  >;
  totaltAntal: number;
  bruger: Bruger | undefined;
}
export default function Header(props: HeaderProps) {
  const {
    opgaveliste,
    opgaveOptions,
    setOpgaveOptions,
    totaltAntal,
    defaultOpgaveOptions,
    bruger,
  } = props;
  const roller = bruger ? bruger.roller : [];
  const sortOrder = opgaveOptions?.sortOrder ?? OpgaveSortOrder.undefined;
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const hideFilterOptions = () => {
    setShowFilterOptions(false);
  };
  const getFileQuery = () => {
    const params = new URLSearchParams();
    params.append("listeId", opgaveliste.id);
    if (sortOrder) params.append("sortOrder", sortOrder.toString());
    if (opgaveOptions) {
      const { brugerStatus, brugerId, kommunenr } = opgaveOptions;
      if (brugerId) params.append("brugerId", brugerId);
      if (brugerStatus) params.append("brugerStatus", brugerStatus);
      if (kommunenr) params.append("kommunenr", kommunenr);
    }
    return params.toString();
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerrow}>
        <div className={styles.col_1}>
          <div className={styles.navn}>
            <h2 className={styles.heading}>{opgaveliste.navn}</h2>
            {/*<span className={styles.antal}>{opgaveliste.antalOpgaver}</span>*/}
          </div>
          <div className={styles.beskrivelse}>
            <p>{opgaveliste.beskrivelse} </p>
            <p>
              <Link
                className="internal"
                to={`../hjaelp-til-fejllister?artikelid=${opgaveliste.artikelId}`}
                target="_blank"
              >
                Læs dette, før du begynder på fejllisten <Arrow width={22} />
              </Link>
            </p>
          </div>
        </div>
        <div className={styles.col_2}>
          <a
            href={`api/download/liste/excel?${getFileQuery()}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.download}
          >
            Download Excel <Download width={21} />
          </a>

          {roller.findIndex((role) => role.navn === "administrator") > -1 && (
            <a
              href={`api/download/liste/csv?${getFileQuery()}`}
              className={styles.download}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download CSV <Download width={21} />
            </a>
          )}
        </div>
      </div>
      <div className={styles.subrow}>
        {opgaveOptions && (
          <NextPreviousPage
            pageOptions={opgaveOptions}
            setPageOptions={setOpgaveOptions}
            totalCount={totaltAntal}
          />
        )}

        <div className={styles.filtercont}>
          <FoldOutMenu
            element={
              <button className={styles.filtrer}>
                {/* <Sorter /> */}
                <span>Sortér efter:</span>

                {sortOrder === OpgaveSortOrder.medarbejderInitialerAsc && (
                  <span>
                    <b>Medarbejder</b>
                    <SortArrowAsc />
                  </span>
                )}
                {sortOrder === OpgaveSortOrder.medarbejderInitialerDesc && (
                  <span>
                    <b>Medarbejder</b>
                    <SortArrowDesc />
                  </span>
                )}

                {sortOrder === OpgaveSortOrder.bfeAsc && (
                  <span>
                    <b>BFE-nummer</b>
                    <SortArrowAsc />
                  </span>
                )}
                {sortOrder === OpgaveSortOrder.bfeDesc && (
                  <span>
                    <b>BFE-nummer</b>
                    <SortArrowDesc />
                  </span>
                )}

                {sortOrder === OpgaveSortOrder.adresseAsc && (
                  <span>
                    <b>Adresse</b>
                    <SortArrowAsc />
                  </span>
                )}
                {sortOrder === OpgaveSortOrder.adresseDesc && (
                  <span>
                    <b>Adresse</b>
                    <SortArrowDesc />
                  </span>
                )}

                <span className={styles.chevron}>
                  <ArrowTriangle />
                </span>
              </button>
            }
            menuClassNames={styles.filtermenu}
            menu={[
              <div
                title="Sortér efter medarbejder"
                key="medarbejder"
                className={styles.filtermenuitem}
                onClick={() => {
                  setOpgaveOptions({
                    ...opgaveOptions,
                    page: 1,
                    sortOrder:
                      sortOrder === OpgaveSortOrder.medarbejderInitialerAsc
                        ? OpgaveSortOrder.medarbejderInitialerDesc
                        : OpgaveSortOrder.medarbejderInitialerAsc,
                  });
                }}
              >
                {(sortOrder === OpgaveSortOrder.medarbejderInitialerDesc ||
                  sortOrder === OpgaveSortOrder.medarbejderInitialerAsc) && (
                  <span className={styles.check}>
                    <Checkmark width={12} />
                  </span>
                )}
                <span>Medarbejder</span>
                {sortOrder === OpgaveSortOrder.medarbejderInitialerDesc && (
                  <span className={styles.sortarrow}>
                    <SortArrowDesc />
                  </span>
                )}
                {sortOrder === OpgaveSortOrder.medarbejderInitialerAsc && (
                  <span className={styles.sortarrow}>
                    <SortArrowAsc />
                  </span>
                )}
              </div>,
              <div
                title="Sortér efter BFE-nummer"
                key="bfe"
                className={styles.filtermenuitem}
                onClick={() => {
                  setOpgaveOptions({
                    ...opgaveOptions,
                    page: 1,
                    sortOrder:
                      sortOrder === OpgaveSortOrder.bfeAsc
                        ? OpgaveSortOrder.bfeDesc
                        : OpgaveSortOrder.bfeAsc,
                  });
                }}
              >
                {(sortOrder === OpgaveSortOrder.bfeDesc ||
                  sortOrder === OpgaveSortOrder.bfeAsc) && (
                  <span className={styles.check}>
                    <Checkmark width={12} />
                  </span>
                )}
                <span>BFE-nummer</span>
                {sortOrder === OpgaveSortOrder.bfeDesc && (
                  <span className={styles.sortarrow}>
                    <SortArrowDesc />
                  </span>
                )}
                {sortOrder === OpgaveSortOrder.bfeAsc && (
                  <span className={styles.sortarrow}>
                    <SortArrowAsc />
                  </span>
                )}
              </div>,
              <div
                title="Sortér efter adresse"
                key="adresse"
                className={styles.filtermenuitem}
                onClick={() => {
                  setOpgaveOptions({
                    ...opgaveOptions,
                    page: 1,
                    sortOrder:
                      sortOrder === OpgaveSortOrder.adresseAsc
                        ? OpgaveSortOrder.adresseDesc
                        : OpgaveSortOrder.adresseAsc,
                  });
                }}
              >
                {(sortOrder === OpgaveSortOrder.adresseDesc ||
                  sortOrder === OpgaveSortOrder.adresseAsc) && (
                  <span className={styles.check}>
                    <Checkmark width={12} />
                  </span>
                )}
                <span>Adresse</span>
                {sortOrder === OpgaveSortOrder.adresseDesc && (
                  <span className={styles.sortarrow}>
                    <SortArrowDesc />
                  </span>
                )}
                {sortOrder === OpgaveSortOrder.adresseAsc && (
                  <span className={styles.sortarrow}>
                    <SortArrowAsc />
                  </span>
                )}
              </div>,
            ]}
          />
          <button
            title="Vis filtervalg"
            className={styles.sorter}
            onClick={() => setShowFilterOptions(!showFilterOptions)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.5px"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                id="Path_71"
                data-name="Path 71"
                d="M22.052,2.789l0-.043,0-.054c0-.069.008-.177.009-.295a.855.855,0,0,0-.073-.385.47.47,0,0,0-.432-.259c-4.736-.015-9.3-.344-14.429-.344q-1.065,0-2.165.02h0L3.574,1.45l-.036,0L3.4,1.46c-.084,0-.187,0-.3,0a1.9,1.9,0,0,0-.39.034.749.749,0,0,0-.358.185l0,0c-.006,0-.009.012-.016.017s-.008,0-.012.01l0,0a.763.763,0,0,0-.223.585c0,.108.008.2.008.268v.055l.079,2.765,0,.035.014.078.054.119C4.4,8.119,6.832,10.5,8.913,13.029l0,0a1.986,1.986,0,0,1,.3,1.25c0,.287-.019.574-.023.827l-.066,2.445v0l-.092,4.889v.01a1.022,1.022,0,0,0,.047.319.616.616,0,0,0,.233.313l0,0a.064.064,0,0,0,.016.011l0,0h0a.913.913,0,0,0,.563.24l.241-.045v0l4.693-1.508.073-.036.224-.165.064-.069a1.021,1.021,0,0,0,.252-.551v-.017q.008-.678.007-1.355c0-1.14-.014-2.277-.014-3.413h0l-.011-2.378V13.8c-.005-.131-.009-.26-.009-.387a2.008,2.008,0,0,1,.358-1.375c2.068-2.144,4.339-4.231,6.262-6.508l.059-.164c-.006-.552-.034-2.051-.038-2.574m-7.776,8.525-.049.062-.064.119.006,0a.219.219,0,0,0-.037.091l0,.01-.015.184c-.313,2.846-.28,5.756-.335,8.609L10.495,21.6c-.021-1.231-.035-2.462-.035-3.692q0-2.375.064-4.749l-.015-.094-.1-.27-.045-.075C8.17,10.146,5.979,7.568,3.732,5.037l.02-1.9q1.062,0,2.125.005,7.536,0,15.071-.27l.108,2.286c-2.319,2.028-4.563,4.054-6.78,6.154"
              />
            </svg>
          </button>
        </div>
      </div>
      {showFilterOptions && (
        <FilterOptions
          currentOpgaveOptions={opgaveOptions}
          setCurrentOpgaveOptions={setOpgaveOptions}
          defaultOpgaveOptions={defaultOpgaveOptions}
          hide={hideFilterOptions}
          brugerStatuser={opgaveliste.brugerstatus}
        />
      )}
      {!showFilterOptions &&
        (opgaveOptions.brugerId ||
          opgaveOptions.brugerStatus ||
          opgaveOptions.kommunenr) && (
          <AktiveFiltre
            opgaveOptions={opgaveOptions}
            setOpgaveOptions={setOpgaveOptions}
          />
        )}
    </div>
  );
}

function SortArrowAsc() {
  return <Arrow style={{ transform: "rotate(-90deg)" }} width={15} />;
}
function SortArrowDesc() {
  return <Arrow style={{ transform: "rotate(90deg)" }} width={15} />;
}
