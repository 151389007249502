import React from "react";

export default function Checkmark(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (18 / 14) : 18}
      height={height ? height : width ? width * (14 / 18) : 14}
      viewBox="0 0 18 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7 5.245c7.322 10.343 2.67 9.61 14.645-4.472"
        stroke={primaryColor ? primaryColor : "currentColor"}
        strokeWidth="2.2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      />
    </svg>
  );
}
