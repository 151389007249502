import React, { useState, useRef, MutableRefObject } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./FoldOutMenu.module.scss";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { mergeClassNames } from "util/index";

const variants = {
  open: { height: "auto" },
  closed: { height: 0 },
};

export default function FoldOutMenu(props: IFoldOutMenu) {
  const {
    element,
    elementClassNames,
    menu,
    menuClassNames,
    state,
    direction = "down",
  } = props;
  const ref = useRef<HTMLDivElement>();
  const [menuOpen, setMenuOpen] = useState(false);
  const { open, setOpen } = {
    open: state ? state.menuOpen : menuOpen,
    setOpen: state ? state.setMenuOpen : setMenuOpen,
  };
  useOnClickOutside(ref, () => setOpen(false));
  const menuClass = mergeClassNames(
    styles.menu,
    direction === "up" ? styles.menu__up : ""
  );
  return (
    <div
      ref={ref as MutableRefObject<HTMLDivElement>}
      className={mergeClassNames(styles.container, elementClassNames)}
      onClick={() => setOpen(!open)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.keyCode === 32) {
          e.preventDefault();
          setOpen(!open);
        } else if (e.keyCode === 13) {
          e.preventDefault();
          setOpen(!open);
        }
      }}
    >
      {element}
      <AnimatePresence>
        {open && (
          <motion.div
            className={mergeClassNames(menuClass, menuClassNames)}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
            transition={{ damping: 300 }}
          >
            {menu}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IFoldOutMenu {
  element: JSX.Element;
  elementClassNames?: string;
  menu: Array<JSX.Element> | JSX.Element;
  menuClassNames?: string;
  state?: { menuOpen: boolean; setMenuOpen: Function };
  direction?: "up" | "down";
}
