import React, { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

import opgaveApi from "api/opgave";
// import Checkmark from "components/common/icons/Checkmark";
import styles from "./KommentarFelt.module.scss";
import { AuthContext } from "context/AuthProvider";

export default function KommentarFelt(props: KommentarFeltProps) {
  const { opgaveState, setOpgaveState } = props;
  const authState = useContext(AuthContext);
  // Kommentartekst
  const [kommentarText, setKommentarText] = useState(
    opgaveState.data?.[opgaveState.idx]?.kommentar
  );
  const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (showExitPrompt) {
        // Nicolai: Det her fyrer faktisk onBlur eventet og gemmer teksten.
        // Hvis vi skal rette det skal vi implicit *ikke* gemme. Det kræver en showExitPrompt state.
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "Hey";
        }
        return "";
      }
    };
    return true;
  };

  useEffect(() => {
    initBeforeUnLoad(
      kommentarText !== opgaveState.data?.[opgaveState.idx]?.kommentar
    );
  }, [kommentarText, opgaveState]);

  useEffect(() => {
    setKommentarText(opgaveState.data?.[opgaveState.idx]?.kommentar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opgaveState.data?.[opgaveState.idx]?.kommentar]);

  const putKommentarText = async (
    id: string,
    kommentar: Opgave["kommentar"]
  ) => {
    try {
      opgaveApi
        .putKommentarText(
          id,
          opgaveState.data?.[opgaveState.idx]?.kommentarAdgang,
          kommentar,
          authState
        )
        .then((opgave: Opgave) => {
          let data = opgaveState.data;
          if (data) {
            data[opgaveState.idx] = opgave;
            setOpgaveState({
              ...opgaveState,
              data: data,
            });
          }
          initBeforeUnLoad(false);
          return data;
        });
    } catch (error) {
      if (error === "CANCEL") {
        // The request was canceled, all is well
        console.debug(error);
      } else {
        return undefined;
      }
    }
  };
  const areTextsEqual = (
    a: string | null | undefined,
    b: string | null | undefined
  ): boolean => {
    if (a === null || a === undefined) {
      a = "";
    }
    if (b === null || b === undefined) {
      b = "";
    }

    if (a === b) {
      return true;
    }

    return false;
  };
  return (
    <div className={styles.kommentar}>
      <div className={styles.kommentar__header}>
        <h5>Noter</h5>
      </div>
      <div className={styles.notefield}>
        <div className={styles.notefield__pulltab}></div>
        <textarea
          onBlur={(e) =>
            opgaveState.data?.[opgaveState.idx].id &&
            putKommentarText(
              opgaveState.data?.[opgaveState.idx].id,
              e.target.value
            )
          }
          className={styles.notefield__textarea}
          placeholder="Indtast noter"
          value={kommentarText ? kommentarText : ""}
          onChange={(e) => setKommentarText(e.target.value)}
        ></textarea>
        <div className={styles.notefield__status} aria-live="polite">
          <span>
            <i>
              Gemmer automatisk{" "}
              {!areTextsEqual(
                kommentarText,
                opgaveState.data?.[opgaveState.idx]?.kommentar
              )
                ? "Du har ugemte ændringer"
                : ""}
            </i>
            <i>
              <Prompt
                when={
                  !areTextsEqual(
                    kommentarText,
                    opgaveState.data?.[opgaveState.idx]?.kommentar
                  )
                }
                message="Du har ugemte ændringer, de gemmes hvis du fortsætter."
              ></Prompt>
            </i>
          </span>
        </div>
      </div>
    </div>
  );
}

interface KommentarFeltProps {
  opgaveState: {
    state: "LOADING" | "IDLE" | "ERROR";
    data: any;
    error: any;
    idx: number;
  };
  setOpgaveState: Function;
}
