import React, { useState, useEffect } from "react";
export default function Plus(props: Icon) {
  const {
    height,
    width,
    primaryColor = "currentColor",
    secondaryColor = "#ffffff",
    style = {},
    hovered,
  } = props;
  const [pColor, setPColor] = useState(primaryColor);
  const [sColor, setSColor] = useState(secondaryColor);
  useEffect(() => {
    setPColor(hovered ? secondaryColor : primaryColor);
    setSColor(hovered ? primaryColor : secondaryColor);
  }, [hovered, primaryColor, secondaryColor]);
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (30 / 30) : 30}
      height={height ? height : width ? width * (30 / 30) : 30}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Question-accordian-blue"
          transform="translate(0.000000, -1.000000)"
          stroke={pColor}
        >
          <g id="Group-10">
            <g
              id="icon/Plus-icon-blue"
              transform="translate(0.000000, 1.000000)"
            >
              <g id="Plus-icon">
                <path
                  fill={sColor}
                  fillRule="evenodd"
                  d="M6.6437,26.6407 C4.3107,25.2417 -4.0893,14.0407 4.7767,5.1737 C13.6437,-3.6933 25.7777,2.8407 28.1107,7.9737 C30.4447,13.1077 28.5777,21.5077 21.5777,25.7077 C14.5777,29.9087 8.9767,28.0407 6.6437,26.6407 Z"
                  id="Stroke-1"
                  strokeWidth="1.6"
                />
                <polyline
                  id="Stroke-3"
                  strokeWidth="1.603"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="15.4835 7 15.4835 11.6523667 15.4835 18.3731756 15.4835 20.9571"
                ></polyline>
                <path
                  d="M8,13.1798 L14.2905263,13.1798 C14.2905263,13.1798 20.5810526,12.6238 23,13.1798"
                  id="Stroke-5"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
