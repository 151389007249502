import React from "react";

export default function Info(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 15}
      height={height ? height : width ? width : 29}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 29"
    >
      <g
        id="Wireframes"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Ejendomsvisning---1b-Copy-4"
          transform="translate(-1181.000000, -109.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.6"
        >
          <g id="Group-3" transform="translate(1182.500000, 110.666893)">
            <path
              d="M6.39314915,-3.92574862e-13 C5.51119069,0.372459018 4.47716397,0.49783146 3.74727378,1.11737705 C3.09196572,1.67361582 2.74209145,2.53771764 2.45074892,3.34638924 C0.589298536,8.51316677 16.0953574,3.10688778 6.36558795,0.956603376"
              id="Path-6-Copy"
            ></path>
            <path
              d="M3.05533376e-13,12.0306626 C1.12395329,11.0202364 8.90153273,5.78904294 9.04224113,10.1464151 C9.21031885,15.3513437 2.92750419,23.327472 10.2608538,22.7394111 C10.5111366,22.719341 11.9744912,22.2121683 11.8897421,22.4486928 C10.3058893,26.8690339 4.0946866,27.2558689 1.7655177,23.0852719 C-0.370410194,19.2606912 5.94664028,11.1342927 0.472235739,12.6430933 L3.05533376e-13,12.0306626 Z"
              id="Path-41-Copy"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
