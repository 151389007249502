export enum Interaktionstype {
  vis = "vis",
  vælg = "vælg",
  opret = "opret",
  flyt = "flyt",
}
export enum Strukturtilstande {
  StamData = "StamData",
  StamData_Bekraeftet = "StamData_Bekraeftet",
  Oprettet = "Oprettet",
  NyOprettet = "NyOprettet",
  Flyttet = "Flyttet",
}
export enum StrukturType {
  Bygning = "Bygning",
  TekniskAnlæg = "TekniskAnlæg",
}
