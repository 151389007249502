import React from "react";
import { motion } from "framer-motion";
import styles from "./Ejer.module.scss";
import { getESRList } from "../../../../util";
import { EjendomState } from "routes/Ejendom/Ejendom";

export default function Vurderingsejendom(props: IVurderingsejendom) {
  const { ejendom } = props;
  return (
    <div className={styles.vurderingsejendom}>
      <h5>Vurderingsejendom</h5>
      {ejendom.state === "IDLE" && ejendom.data && (
        <p>
          Ejendommen består af følgende ESR numre:
          <br />
          <ESRListe ejendom={ejendom.data} />
        </p>
      )}
      {ejendom.state === "LOADING" &&
        Array.from(new Array(2)).map((o, i) => {
          return (
            <motion.li key={i} className={`${styles.ejer} ${styles.loading}`}>
              <div className={styles.text}>
                <div
                  style={{ width: 30 + Math.random() * 70 + "%" }}
                  className={styles.line}
                ></div>
              </div>
            </motion.li>
          );
        })}
      {ejendom.state === "ERROR" && <p>Kunne ikke hente vurderingsejendom.</p>}
    </div>
  );
}

const ESRListe = (props: { ejendom: Ejendom }) => {
  const { ejendom } = props;
  let count_esr = getESRList(ejendom, true).reduce((esr, num) => {
    esr[num] = esr[num] === undefined ? 1 : (esr[num] += 1);
    return esr;
  }, {});

  return (
    <>
      {Object.keys(count_esr)
        .map(function (key, idx) {
          return `${
            count_esr[key]
          } grund${parseInt(count_esr[key]) > 1 ? "e" : ""} med ESR: ${key} \n`;
        })
        .join(", ")}
    </>
  );
};

// Interface
interface IVurderingsejendom {
  ejendom: EjendomState;
}
