import { useLocation } from "react-router-dom";

/**
 * A custom hook that builds on react-router's useLocation
 * to parse the query string for you.
 *
 * source: https://reacttraining.com/react-router/web/example/query-parameters
 */
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
