import React from "react";
import { useHistory } from "react-router";
import { motion } from "framer-motion";
import styles from "./Sidebar.module.scss";
import { Spacing } from "components/common/layout/Spacing";
import { mergeClassNames } from "util/index";

export default function Sidebar(props: ISidebarProps) {
  const { temaer, state, currentTemaId, totaltAntalArtikler } = props;
  let history = useHistory();

  return (
    <div>
      <ul role="tablist">
        {state === "LOADING" &&
          Array.from(new Array(6)).map((o, i) => {
            return (
              <motion.li
                key={i}
                className={`${styles.opgave} ${styles.loading}`}
              >
                <div className={styles.text}>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                </div>
              </motion.li>
            );
          })}
        {state === "IDLE" && (
          <>
            <li>
              <motion.button
                role="tab"
                aria-controls="artikler-content"
                aria-selected={currentTemaId === "-1"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={mergeClassNames(
                  styles.opgave,
                  currentTemaId === "-1" ? styles.selected : ""
                )}
                title="Alle opgavebeskrivelser"
                onClick={() => {
                  history.push("?temaid=-1");
                }}
              >
                <Spacing right={1} />
                <div className={styles.titleContainer}>
                  <h2 className={styles.titel}>Alle</h2>
                </div>
                <div className={styles.antal}>{totaltAntalArtikler}</div>
              </motion.button>
            </li>
            {temaer.map((t) => {
              const { tema, antal } = t;
              return (
                <li key={tema.id}>
                  <motion.button
                    role="tab"
                    aria-controls="artikler-content"
                    aria-selected={currentTemaId === tema.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={mergeClassNames(
                      styles.opgave,
                      currentTemaId === tema.id ? styles.selected : ""
                    )}
                    title={tema.navn}
                    onClick={() => {
                      history.push("?temaid=" + tema.id);
                    }}
                  >
                    <Spacing right={1} />
                    <div className={styles.titleContainer}>
                      <h2 className={styles.titel}>{tema.navn}</h2>
                    </div>
                    <div className={styles.antal}>{antal}</div>
                  </motion.button>
                </li>
              );
            })}
          </>
        )}
      </ul>
    </div>
  );
}

interface ISidebarProps {
  state: "IDLE" | "LOADING" | "ERROR";
  temaer: { antal: number; tema: ArtikelTema }[];
  currentTemaId: any;
  totaltAntalArtikler: number;
}
