import React from "react";

export default function Info(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
    >
      <g
        id="Wireframes"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Liste---redesign---1b"
          transform="translate(-888.000000, -180.000000)"
          fill={primaryColor ? primaryColor : "currentColor"}
        >
          <path
            d="M895,194 C891.134007,194 888,190.865993 888,187 C888,183.134007 891.134007,180 895,180 C898.865993,180 902,183.134007 902,187 C902,190.865993 898.865993,194 895,194 Z M895.267877,181.783096 C894.909095,181.934612 894.488451,181.985614 894.191531,182.237646 C893.924951,182.463925 893.782621,182.815443 893.664103,183.144411 C892.906862,185.246264 899.214749,183.046982 895.256665,182.172243 L895.267877,181.783096 Z M892.667135,186.250896 L892.859241,186.500033 C895.086236,185.886251 892.516451,189.192081 893.38535,190.747926 C894.332859,192.444531 896.859585,192.287166 897.503899,190.488965 C897.538375,190.392747 896.94308,190.599065 896.841265,190.60723 C893.858047,190.846454 896.413905,187.601753 896.345531,185.484381 C896.288291,183.711796 893.12436,185.839853 892.667135,186.250896 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
