import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import styles from "./Home.module.scss";
import Arrow from "components/common/icons/Arrow";
import Bbr from "components/common/icons/Bbr";
import Vurdst from "components/common/icons/Vurdst";
import ExternalArrow from "components/common/icons/ExternalArrow";
import Button from "components/common/Buttons/Button";
import Search from "components/Search/Search";
import artikelApi from "api/artikler";
import { motion } from "framer-motion";
import { ArtikelPageOptions } from "interfaces/paging";
import { mergeClassNames } from "util/index";
import { sanitize } from "dompurify";
import { format } from "date-fns";
import { da } from "date-fns/esm/locale";
import { AuthState } from "context/AuthProvider";
import { size } from "styles/theme";

interface HomeProps {
  authState: AuthState;
}

export default function Home(props: HomeProps) {
  const { authState } = props;
  const roller = authState.bruger ? authState.bruger.roller : [];
  let history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [result, setResult] = useState(undefined);
  const [redirect, setRedirect] = useState<{
    state: boolean;
    path: string;
  }>({ state: false, path: "/" });

  const [forsideArtikler, setForsideArtikler] = useState<{
    state: "LOADING" | "IDLE" | "OK" | "ERROR";
    data?: Artikel[];
  }>({ state: "LOADING" });

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      try {
        const pageoptions: ArtikelPageOptions = {
          page: 1,
          pageSize: 99,
          trimindhold: true, // Forkort indholdet.
          forside: true,
          offentlig: true,
        };
        const ol = await artikelApi.getAll(pageoptions);
        if (stillMounted) {
          setForsideArtikler({ state: "OK", data: ol });
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setForsideArtikler((s) => {
              return { ...s, state: "ERROR" };
            });
          }
        }
      }
    };
    fetchData();
    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
  }, []);
  // const renderWelcome = () => {
  //   return (
  //     <>
  //       <h1>Velkommen til BBR Explore</h1>
  //       <p>
  //         BBR Explore er udviklet af Registerforvaltningen (BBR og OIS). Her
  //         finder du oplysninger om ejendomme, lister med fejl i bygningsdata,
  //         samt vejledninger.{" "}
  //         {!authState.bruger && "BBR Explore kan kun tilgås med login."}
  //       </p>
  //     </>
  //   );
  // };

  const renderAuthenticatedPage = () => {
    return (
      <div className={styles.container}>
        {/* <div className={styles.intro}>
          <div className={styles.grid}>
            <div className={styles.col1}>
              {renderWelcome()}
              <Link
                className="internal"
                to="/hjaelp-til-fejllister?artikelid=97cc621c-9967-45e9-b06b-8e0b4072b203"
              >
                Læs mere om BBR Explore <Arrow />
              </Link>
            </div>
            <div className={styles.col2}>
              <img
                src="https://bbr.dk/file/654761/test2.jpeg"
                alt="Billede af hus"
              />
            </div>
          </div>
        </div> */}
        <div className={styles.searchbox}>
          <div className={styles.grid}>
            <h2>Find ejendom</h2>
            <div>
              <Search
                onResult={(item) => {
                  setResult(item);
                  try {
                    let bfe =
                      item["data"][0] !== undefined
                        ? item["data"][0]["data"]["bfeNummer"]
                        : item["data"]["bfeNummer"];
                    if (bfe !== undefined) {
                      setRedirect({ state: true, path: "/ejendom?bfe=" + bfe });
                    } else {
                      redirect.state = false;
                    }
                  } catch (err) {
                    setRedirect({ state: false, path: "" });
                  }
                }}
              />
              {redirect.state && <Redirect push to={redirect.path} />}
              {result && (
                <>
                  <p>Intet BFE-nummer fundet ved søgning.</p>
                </>
              )}
            </div>
          </div>
        </div>
        {roller.findIndex((role) => role.navn === "fejllister") > -1 && (
          <div className={styles.ribbon}>
            <div className={styles.grid}>
              <div style={{ flex: 1, marginRight: 4 * size.base }}>
                <h2>
                  <span>Fejllister</span>
                </h2>
                <p>
                  Fejllister er en fortegnelse over grunde, bygninger, enheder
                  eller tekniske anlæg hvor der potentielt kan være fejl.
                </p>
              </div>
              <Button
                type="primary-lg"
                onClick={() => {
                  history.push("/fejllister");
                }}
              >
                Se fejllister
              </Button>
            </div>
          </div>
        )}
        {roller.findIndex((role) => role.navn === "fejllister") > -1 && (
          <div className={styles.artikler}>
            <div className={styles.grid}>
              <div className={styles.header}>
                <h2>Hjælp til fejllister</h2>
                <Button
                  type="primary-lg"
                  onClick={() => {
                    history.push("/hjaelp-til-fejllister");
                  }}
                >
                  Få hjælp til fejllister
                </Button>
              </div>
              {forsideArtikler.state === "OK" && renderArtikler()}
              {forsideArtikler.state === "LOADING" && renderLoadingArtikler()}
              {forsideArtikler.state === "ERROR" && renderErrorArtikler()}
              {/* <div className={styles.all}>
                <Button
                  type="primary-lg"
                  onClick={() => {
                    history.push("/hjaelp-til-fejllister");
                  }}
                >
                  Få hjælp til fejllister
                </Button>
              </div> */}
            </div>
          </div>
        )}
        <footer className={styles.footer}>
          <div className={styles.grid}>
            <div>
              <div className={styles.logos}>
                <Bbr width={84} primaryColor="#2f2f2f" />
                <span className={styles.ufst}>
                  <Vurdst width={135} />
                </span>
              </div>
              <div className={styles.links}>
                <ul className={styles.linksnoarrow}>
                  <li>
                    <a
                      className="external"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://bbr.dk/persondata"
                    >
                      <span>Persondata</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="external"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://bbr.dk/cookies"
                    >
                      <span>Cookies</span>
                    </a>
                  </li>
                </ul>
                <ul className={styles.linksarrow}>
                  <li>
                    <a
                      className="external"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://ki.bbr.dk"
                    >
                      BBR Kommuneinfo <ExternalArrow />
                    </a>
                  </li>
                  <li>
                    <a
                      className="external"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://teknik.bbr.dk"
                    >
                      BBR Teknik <ExternalArrow />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  };
  const renderLoadingArtikler = () => {
    return Array.from(new Array(3)).map((o, i) => {
      return (
        <motion.li
          key={i}
          className={mergeClassNames(styles.listitem, styles.loading)}
        >
          <div className={styles.text}>
            <div
              style={{
                width: 20 + Math.random() * 30 + "%",
                height: 30,
                margin: "5px",
              }}
              className={styles.line}
            ></div>
            <div
              style={{ width: 100 + "%", height: 50, margin: "5px" }}
              className={styles.line}
            ></div>
            <div
              style={{
                width: 50 + +(Math.random() * 30) + "%",
                height: 10,
                margin: "10px",
              }}
              className={styles.line}
            ></div>
          </div>
        </motion.li>
      );
    });
  };
  const renderErrorArtikler = () => {
    return (
      <motion.div layout transition={{ damping: 300 }}>
        <h3>En uventet fejl opstod</h3>
      </motion.div>
    );
  };

  const renderArtikler = () => {
    let jsx_elements: JSX.Element[] = [];

    if (forsideArtikler.data && forsideArtikler.data.length > 0) {
      forsideArtikler.data.forEach((artikel, idx) => {
        let parsedDate = format(new Date(artikel.dato), "PPP", {
          locale: da,
        });
        jsx_elements.push(
          <li key={artikel.id} className={styles.listitem}>
            <Link
              className={styles.artikellistitem}
              to={`/hjaelp-til-fejllister?artikelid=${artikel.id}`}
            >
              <div className={styles.main}>
                <h2>{artikel.titel}</h2>
                <p
                  className={styles.ingress}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(artikel.indhold.split(/\r?\n/)[0]),
                  }}
                ></p>

                <p>
                  <small>Dato: {parsedDate}</small>{" "}
                  <small>Afsender: {artikel.forfatter}</small>
                </p>
              </div>
              <span className={styles.arrow}>
                <Arrow />
              </span>
            </Link>
          </li>
        );
      });
    }
    return <ul>{jsx_elements}</ul>;
  };

  if (authState.state === "LOADING") {
    return <div></div>;
  }
  return <>{renderAuthenticatedPage()}</>;
}
